import { SxProps, Theme } from '@mui/material'

export const rootSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minHeight: '100vh',
}

export const wrapperSx: SxProps<Theme> = {
  flex: 1,
  display: 'flex',
  minHeight: '100%',
}

export const contentWrapperSx = ({
  maxContentHeight,
}: {
  maxContentHeight: string
}): SxProps<Theme> => {
  return {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: maxContentHeight,
  }
}
