import { useTranslation } from 'react-i18next'

import { Box, Button, Paper, Typography } from '@mui/material'

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined'

import authHook from '@hooks/useAuth'
import responsiveHook from '@hooks/useResponsive'

import type { IImpersonatedUser } from '@utils/types'

export const IMPERSONATION_BAR_HEIGHT = '8rem'

interface IImpersonationBarProps {
  impersonation: IImpersonatedUser
}

const ImpersonationBar = ({ impersonation }: IImpersonationBarProps) => {
  const { t } = useTranslation()
  const { impersonate } = authHook.useAuth()

  const { isMobile } = responsiveHook.useResponsive()

  const handleOnClose = async () => {
    await impersonate()
    window.close()
  }

  return (
    <Paper
      sx={{
        height: IMPERSONATION_BAR_HEIGHT,
        width: '100%',
        marginBottom: '0.2rem',
        borderRadius: 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 2.4rem',
        position: 'sticky',
        margin: 0,
        top: 0,
        zIndex: 1250,
      }}
    >
      <Typography
        sx={{
          display: { xs: 'block', md: 'flex' },
          fontSize: { xs: '1.6rem', lg: '2.4rem' },
          lineHeight: { xs: '2.4rem', lg: '3.2rem' },
          alignItems: 'center',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {!isMobile && (
          <KeyOutlinedIcon
            sx={{
              width: '3.2rem',
              height: '3.2rem',
              color: 'text.secondary',
              mr: '2.4rem',
            }}
          />
        )}
        {`${t('Admin Account')} - ${impersonation.username}`}
      </Typography>
      <Box>
        <Button
          aria-label={t('Back to admin panel')}
          variant="contained"
          size="medium"
          startIcon={<CheckOutlinedIcon />}
          sx={{ marginLeft: '2.4rem', display: 'flex' }}
          onClick={handleOnClose}
        >
          {!isMobile ? t('Back to Admin Panel') : t('Back')}
        </Button>
      </Box>
    </Paper>
  )
}

export { ImpersonationBar }
