import { useTranslation } from 'react-i18next'

import { Box, Link, Typography } from '@mui/material'

import { COSMO_AVATAR_DISABLED } from '@utils/images'

import {
  chatbotDrawerContainerSx,
  chatbotDrawerImageSx,
  chatbotDrawerSubTitleSx,
  chatbotDrawerTitleSx,
  linkSx,
} from './styles'

const ChatbotDrawerDisabled = () => {
  const { t } = useTranslation()

  return (
    <Box sx={chatbotDrawerContainerSx} data-testid="chatbot-drawer-disabled">
      <Box
        alt={t('Avatar Cosmo Chatbot')}
        loading="lazy"
        sx={chatbotDrawerImageSx}
        component="img"
        src={COSMO_AVATAR_DISABLED}
      />
      <Typography variant="h5" sx={chatbotDrawerTitleSx}>
        {t("Sorry, but Cosmo's taking a break right now")}
      </Typography>
      <Typography variant="body1" sx={chatbotDrawerSubTitleSx}>
        <span>
          {t(
            'Please check back later! If you have any questions or need assistance, feel free to'
          )}
        </span>{' '}
        <Link
          href="/contact-us"
          target="_blank"
          sx={linkSx}
          data-testid="contact-us-link"
        >
          {t('Contact Us')}
        </Link>
        <span>.</span>
      </Typography>
    </Box>
  )
}

export { ChatbotDrawerDisabled }
