import { SxProps, Theme } from '@mui/material'

export const itemContainerSx: SxProps<Theme> = {
  padding: '0.8rem 1.2rem',
  transition: 'none',
  height: '4.8rem',
  '&:hover': {
    backgroundColor: '#f5f6f8',
    '.overlay': {
      background:
        'linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #f5f6f8 100%)}!important',
    },
  },
  '&:hover > .moreButton, &.Mui-focusVisible > .moreButton': {
    opacity: 1,
  },
  '.conversationItem.Mui-focusVisible': {
    backgroundColor: '#E8EDF5!important',
    '.overlay': {
      background:
        'linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #E8EDF5 100%) !important',
    },
  },
  '.conversationItem.Mui-selected': {
    backgroundColor: '#f5f6f8!important',
    '.overlay': {
      background:
        'linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #f5f6f8 100%)}!important',
    },
  },
}

export const overlaySx: SxProps<Theme> = {
  position: 'absolute',
  top: 0,
  right: 0,
  width: '2.4rem',
  height: '4rem',
  background: 'linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)',
}

export const itemDotsIconSx = ({
  isMenuOpen,
}: {
  isMenuOpen: boolean
}): SxProps<Theme> => {
  return {
    minWidth: 'auto',
    marginLeft: '1rem',
    display: 'flex',
    opacity: isMenuOpen ? 1 : 0,
  }
}

export const menuItemSx: SxProps<Theme> = {
  color: (theme) => theme.palette.error.main,
  gap: '1rem',
  height: 'auto',
}

export const itemChatIconSx: SxProps<Theme> = {
  minWidth: 'auto',
  marginRight: '1.6rem',
}

export const itemTextSx: SxProps<Theme> = {
  whiteSpace: 'nowrap',
  position: 'relative',
  overflow: 'hidden',
}
