import { SxProps, Theme, alpha } from '@mui/material'

const transparency: SxProps<Theme> = {
  '::before': {
    content: "''",
    position: 'absolute',
    inset: 0,
    opacity: 0.4,
    pointerEvents: 'none',
    background: (theme) => theme.palette.background.paper,
  },
}

export const ownMessageWrapperSx = ({
  channelDisabled,
}: {
  channelDisabled: boolean
}): SxProps<Theme> => {
  return {
    position: 'relative',
    padding: '2rem',
    width: {
      xs: '90%',
      md: '60%',
    },
    maxWidth: '90%',
    marginTop: '2rem',
    borderRadius: '1.4rem',
    borderTopRightRadius: 0,
    marginLeft: 'auto',
    gap: '1.3rem',
    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
    ...(channelDisabled && transparency),
  }
}

export const vendorMessageWrapperSx = ({
  channelDisabled,
}: {
  channelDisabled: boolean
}): SxProps<Theme> => {
  return {
    position: 'relative',
    padding: '2rem',
    marginTop: '2rem',
    width: {
      xs: '90%',
      md: '60%',
    },
    maxWidth: '90%',
    borderRadius: '1.4rem',
    borderTopLeftRadius: 0,
    gap: '1.3rem',
    border: (theme) => `0.1rem solid ${theme.palette.divider}`,
    ...(channelDisabled && transparency),
  }
}

export const messageSx = ({
  isAutomaticOfflineResponse,
}: {
  isAutomaticOfflineResponse: boolean
}): SxProps<Theme> => {
  return {
    flex: 1,
    overflowWrap: 'anywhere',
    fontStyle: isAutomaticOfflineResponse ? 'italic' : 'initial',
    whiteSpace: 'break-spaces',
    color: (theme) =>
      isAutomaticOfflineResponse
        ? theme.palette.text.secondary
        : theme.palette.text.primary,
  }
}

export const messageSenderSx: SxProps<Theme> = {
  whiteSpace: 'nowrap',
}

export const messageTlgSenderSx: SxProps<Theme> = {
  color: (theme) => theme.palette.text.secondary,
}

export const vendorCompanyNameSx: SxProps<Theme> = {
  color: (theme) => theme.palette.text.secondary,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

export const messageTimeSx: SxProps<Theme> = {
  marginLeft: 'auto',
  color: (theme) => alpha(theme.palette.text.primary, 0.5),
}

export const userNameWrapperSx: SxProps<Theme> = {
  gap: '0.8rem',
  alignItems: 'center',
  flexWrap: 'wrap',
}

export const attachmentWrapperSx: SxProps<Theme> = { marginRight: '2rem' }
