import { Dispatch, FC, SetStateAction, createContext, useState } from 'react'

import { SxProps, Theme } from '@mui/material'

export type TemplateContextState = {
  footerCopyrightSx?: SxProps<Theme>
  setFooterCopyrightSx: Dispatch<SetStateAction<SxProps<Theme> | undefined>>
}

export const TemplateContext = createContext<TemplateContextState>(
  {} as TemplateContextState
)

const TemplateProvider: FC = ({ children }) => {
  const [footerCopyrightSx, setFooterCopyrightSx] = useState<SxProps<Theme>>()

  return (
    <TemplateContext.Provider
      value={{ footerCopyrightSx, setFooterCopyrightSx }}
    >
      {children}
    </TemplateContext.Provider>
  )
}

export { TemplateProvider }
