import { SxProps, Theme } from '@mui/material'

export const COPYRIGHT_FOOTER_HEIGHT = '4.4rem'

export const wrapperSx: SxProps<Theme> = {
  position: 'relative',
  bottom: 0,
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  padding: '1.2rem',
  backgroundColor: '#E8EDF5',
  marginTop: 'auto',
}
