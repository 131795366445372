import { FC, Suspense } from 'react'

const withSuspense = <P,>(WrappedComponent: React.ComponentType<P>): FC<P> =>
  function (props) {
    return (
      <Suspense fallback={null}>
        <WrappedComponent {...(props as any)} />
      </Suspense>
    )
  }

export { withSuspense }
