import { SxProps, Theme } from '@mui/material'

export const buttonSx: SxProps<Theme> = {
  width: { xs: '100%', md: 'fit-content' },
  maxHeight: '5.6rem',
  height: '5.6rem',
  minHeight: '5.6rem',
  padding: '1.4rem 1.6rem',
  textTransform: 'none',
  zIndex: 2,
  background: (theme) => theme.palette.secondary.main + '!important',
  '& .MuiLoadingButton-loadingIndicator': {
    color: (theme) => theme.palette.primary.contrastText,
  },
}
