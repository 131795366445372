import { SxProps, Theme, keyframes } from '@mui/material'

const fadeInFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const containerSx: SxProps<Theme> = {
  flex: 1,
}

export const imageSx: SxProps<Theme> = {
  marginLeft: '-1rem',
  width: {
    xs: '6.4rem',
    md: '8.8rem',
  },
  marginTop: {
    xs: '2.1rem',
    md: '4rem',
  },
  display: {
    xs: 'none',
    sm: 'block',
  },
  animation: `${fadeInFromBottom} 0.5s ease-in-out`,
}

export const subtitleSx: SxProps<Theme> = {
  letterSpacing: '0.025rem',
  color: (theme) => theme.palette.text.secondary,
  fontWeight: '400',
  gap: '0.65rem',
  display: 'flex',
  alignItems: 'center',
  marginTop: {
    xs: '0rem',
    sm: '1.2rem',
    md: '1.6rem',
  },
  animation: `${fadeInFromBottom} 0.5s ease-in-out`,
}

export const titleSx: SxProps<Theme> = {
  fontWeight: '700',
  animation: `${fadeInFromBottom} 0.5s ease-in-out`,
}

export const listSx: SxProps<Theme> = {
  borderRadius: '0.8rem',
  boxShadow:
    '0rem 0.1rem 0.5rem 0rem rgba(0, 0, 0, 0.12), 0rem 0.2rem 0.2rem 0rem rgba(0, 0, 0, 0.14), 0rem 0.3rem 0.1rem -0.2rem rgba(0, 0, 0, 0.20)',
  // TODO LG-2573 - we'll refactor this to use values from the palette in this ticket
  border: (theme) => `0.1rem solid ${theme.palette.divider}`,
  marginTop: {
    xs: '1.2rem',
    sm: '2.4rem',
    md: '3.2rem',
  },
  padding: 0,
  overflow: 'hidden',
  animation: `${fadeInFromBottom} 0.5s ease-in-out`,
}

export const listItemSx: SxProps<Theme> = {
  width: '100%',
  maxWidth: '100%',
  padding: {
    xs: '1rem 0.8rem 1rem 1.6rem',
    sm: '1.2rem 0.8rem 1.2rem 1.6rem',
    md: '1.2rem 1.6rem',
  },
  letterSpacing: {
    xs: '0rem',
  },
  display: 'flex',
  alignItems: 'center',
  borderRadius: 0,
  '&:not(:last-child)': {
    borderBottom: '0.1rem solid #ddd',
    // TODO LG-2573 - we'll refactor this to use values from the palette in this ticket
  },
  gap: {
    xs: '0rem',
    sm: '1rem',
    md: '1.6rem',
  },
  cursor: 'pointer',
  '&:hover': {
    background: '#F8F8F8',
    // TODO LG-2573 - we'll refactor this to use values from the palette in this ticket
  },
}

export const listItemIconSx: SxProps<Theme> = {
  minWidth: '4.8rem',
  height: '4.8rem',
  background: '#E8EDF5',
  // TODO LG-2573 - we'll refactor this to use values from the palette in this ticket
  borderRadius: '50%',
  display: {
    xs: 'none',
    md: 'flex',
  },
  alignItems: 'center',
  justifyContent: 'center',
}

export const listItemTextSx: SxProps<Theme> = {
  lineHeight: '2.4rem',
  letterSpacing: '0.044rem',
  color: (theme) => theme.palette.text.primary,
  overflowWrap: 'anywhere',
  width: '100%',
  fontSize: '1.6rem',
}

export const emptyStateWrapperSx: SxProps<Theme> = {
  padding: {
    xs: '0 1.6rem',
    md: '0 3.2rem',
  },
}

export const listItemArrowIconSx: SxProps<Theme> = {
  display: {
    xs: 'none',
    sm: 'flex',
    md: 'none',
  },

  minWidth: 'auto',
}
