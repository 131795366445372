import api from '@libs/api'

import ApiUtils from '@utils/api'
import { handleServiceError } from '@utils/errors'
import StreamUtils, { DataHandler } from '@utils/streams'

type OnData = (data: {
  reply: string
  conversationId: string
  conversationName: string
  messageId?: string
}) => void

type OnComplete = () => void

const getConversationName = async ({ prompt }: { prompt: string }) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
    }

    return await api.post({
      useBrowserFetch: true,
      path: `${ApiUtils.API_URL}/chatbot/conversations/generate-nam`,
      data: {
        message: prompt,
      },
      headers,
    })
  } catch (err) {
    throw handleServiceError(err)
  }
}

const sendPrompt = async ({
  prompt,
  conversationId,
  signal,
  onData,
  onComplete,
}: {
  prompt: string
  conversationId?: string
  signal: AbortSignal
  onData: OnData
  onComplete?: OnComplete
}) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'text/event-stream',
    }

    const response = await api.post({
      useBrowserFetch: true,
      path: `${ApiUtils.API_URL}/chatbot/buyers`,
      data: {
        ...(conversationId && { conversationId }),
        prompt,
      },
      headers,
      config: { signal },
    })

    if (!response.ok) {
      const errorText = await response.text()
      throw {
        response: {
          data: JSON.parse(errorText),
        },
      }
    }

    if (!response.body) {
      throw new Error('ReadableStream not supported')
    }

    const reader = response.body.getReader()
    const decoder = new TextDecoder('utf-8')
    let finalResponse = ''

    const handleData: DataHandler = (parsedData) => {
      if (parsedData.reply || parsedData?.messageId) {
        if (parsedData.reply) {
          finalResponse += parsedData.reply
        }
        onData({
          conversationId: parsedData.conversationId,
          messageId: parsedData?.messageId,
          conversationName: parsedData.conversationName,
          reply: finalResponse,
        })
      }
    }

    await StreamUtils.readStream({
      reader,
      decoder,
      handleData,
      onComplete: () => onComplete?.(),
    })

    return finalResponse
  } catch (err) {
    throw handleServiceError(err)
  }
}

const getConversations = async () => {
  try {
    const response = await api.get({
      path: '/chatbot/conversations',
    })

    return response
  } catch (err) {
    throw handleServiceError(err)
  }
}

const getConversation = async ({
  conversationId,
}: {
  conversationId: string
}) => {
  try {
    const response = await api.get({
      path: `/chatbot/conversations/${conversationId}`,
    })

    return response
  } catch (err) {
    throw handleServiceError(err)
  }
}

const deleteConversation = async ({
  conversationId,
}: {
  conversationId: string
}) => {
  try {
    return await api.remove({
      path: `/chatbot/conversations/${conversationId}`,
    })
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const createBuyerChatbotInteraction = async () => {
  try {
    const response = await api.post({
      path: `/chatbot/buyers/interaction`,
    })

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const sendFeedback = async ({
  conversationId,
  feedbacks,
  messageId,
  message,
  customFeedback,
}: {
  conversationId: string
  messageId: string
  message: string
  feedbacks: string[]
  customFeedback?: string
}) => {
  try {
    const response = await api.post({
      path: `/chatbot/conversations/${conversationId}/feedbacks`,
      data: {
        feedbacks,
        messageId,
        message,
        customFeedback,
      },
    })

    return response
  } catch (err) {
    throw handleServiceError(err)
  }
}

export default {
  getConversationName,
  sendPrompt,
  getConversations,
  sendFeedback,
  getConversation,
  createBuyerChatbotInteraction,
  deleteConversation,
}
