enum Environments {
  PRODUCTION = 'production',
}

const isProduction = () =>
  process.env.REACT_APP_SENTRY_ENVIRONMENT === Environments.PRODUCTION

export default {
  isProduction,
}
