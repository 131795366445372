import { SxProps, Theme } from '@mui/material'

export const userMessageSx: SxProps<Theme> = {
  background: '#E8EDF5',
  // TODO LG-2573 - we'll refactor this to use values from the palette in this ticket
  borderRadius: '1.2rem',
  padding: '1.2rem 1.6rem',
  overflowWrap: 'anywhere',
  whiteSpace: 'pre-wrap',
  width: 'fit-content',
  marginLeft: 'auto',
}
