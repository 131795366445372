import { createContext, useCallback, useEffect, useMemo, useRef } from 'react'

import { Channel, DefaultGenerics, EventTypes } from 'stream-chat'

import { streamChatClient } from '@libs/stream-chat'

import { AssistanceEvent } from '@utils/chats'
import {
  AdvisorChannel,
  AssistanceChannelData,
  IAdvisorAssignedToChatCustomUserEvent,
  StreamChatChannelType,
} from '@utils/types'

export enum STREAM_CHAT_EVENTS {
  NEW_ADVISOR_CHAT = 'new_advisor_chat',
  NEW_ARCHIVED_CHAT = 'new_archived_chat',
  ADVISOR_ASSIGNED_TO_CHAT = 'advisor_assigned_to_chat',
  NEW_ADMIN_ASSIGNMENT = 'new_admin_assignment',
  NOTIFICATION_ADDED_TO_CHANNEL = 'notification.added_to_channel',
  NOTIFICATION_REMOVED_FROM_CHANNEL = 'notification.removed_from_channel',
  CHANNEL_UPDATED = 'channel.updated',
  NEW_MESSAGE = 'message.new',
  READ_MESSAGE = 'message.read',
}

type AssistanceGeneralEventDefinition = {
  [_ in AssistanceEvent]?: {
    type: EventTypes | string
    event: (
      { event }: Pick<IEventSubCallbackParams, 'event'>,
      callback: EventSubCallbackParam<any>
    ) => void
  }
}

type AssistanceChannelEventDefinition = {
  [_ in AssistanceEvent]?: {
    type: EventTypes | string
    event: (
      { channel, event }: IEventSubCallbackParams,
      callback: EventSubCallbackParam<any>
    ) => void
  }
}

interface IPropertyDef<T> {
  property: keyof T
  newValue: T[keyof T]
}

interface IChannelWatchStateProperties {
  isAdminAssigned?: boolean
  assignedAdminUserId?: string
  unreadConversationCount?: number
}

interface IChannelWatch {
  channel: Channel<DefaultGenerics>
  stateProperties: IChannelWatchStateProperties
}

type EventSubCallbackParam<T> = (arg: T) => void

type EventSubCallback<T> = (arg: T) => void

interface IEventSubCallbackParams {
  event?: any
  channel: Channel<DefaultGenerics>
}

export interface IAssignedAdminStatusUpdate {
  markMessagesAsRead: boolean
  wasChatUnread: boolean
  oldAdminAssignment?: string
  newAdminAssignment: string
}

interface IEventSubscriptions {
  componentId: string
  generalSubscriptionsToEnable?: {
    [AssistanceEvent.NEW_ADVISOR_CHAT]?: EventSubCallback<
      Required<Pick<IEventSubCallbackParams, 'event'>>
    >
    [AssistanceEvent.NEW_ARCHIVED_CHAT]?: EventSubCallback<
      Required<Pick<IEventSubCallbackParams, 'event'>>
    >
    [AssistanceEvent.NEW_ADMIN_ASSIGNMENT]?: EventSubCallback<
      Required<Pick<IEventSubCallbackParams, 'event'>>
    >
    [AssistanceEvent.NOTIFICATION_ADMIN_ASSIGNED_TO_CHAT]?: EventSubCallback<any>
    [AssistanceEvent.CUSTOM_ADMIN_ASSIGNED_TO_CHAT]?: EventSubCallback<
      Required<IEventSubCallbackParams>
    >
  }
  channelSubscriptionsToEnable?: {
    [AssistanceEvent.INITIAL_ADMIN_ASSIGNMENT]?: EventSubCallback<
      Required<IEventSubCallbackParams>
    >
    [AssistanceEvent.NEW_MESSAGE]?: EventSubCallback<IEventSubCallbackParams>
    [AssistanceEvent.READ_MESSAGE]?: EventSubCallback<any>
  }
}

interface ISubscriptionLookup {
  name: AssistanceEvent
  type: string
  callback: EventSubCallbackParam<any>
}

interface IComponentState {
  [componentId: string]: {
    watchedChannels: {
      [channelId: string]: IChannelWatch
    }
    notificationSubscriptionLookup: ISubscriptionLookup[]
    channelSubscriptionLookup: ISubscriptionLookup[]
  }
}

interface IMarkChatAsUnread {
  componentId: string
  channel: Channel<DefaultGenerics>
  onUpdate?: ({
    hasNewUnreadMessageUpdate,
  }: {
    hasNewUnreadMessageUpdate: boolean
  }) => void
}

export type AdvisorRealtimeEventsContextState = {
  isComponentStateInitialized: (data: { componentId: string }) => boolean
  getUnreadConversationCount: () => number
  registerNewWatchedChannelsComponentState: (data: {
    componentId: string
    channels: Channel<DefaultGenerics>[]
  }) => IChannelWatch[]
  registerNewWatchedChannelsComponentStateFromIds: (data: {
    componentId: string
    channelIds: string[]
  }) => Promise<void>
  getStreamChatChannel: (data: {
    channelId: string
  }) => Promise<Channel<DefaultGenerics>>
  getWatchedChannel: (data: {
    componentId: string
    channelId: string
  }) => IChannelWatch | undefined
  setWatchedChannelProperties: <
    T extends IChannelWatch,
    K extends IChannelWatchStateProperties
  >(data: {
    componentId: string
    channelId: string
    set:
      | { base: IPropertyDef<T>[]; state?: IPropertyDef<K>[] }
      | { state: IPropertyDef<K>[]; base?: IPropertyDef<T>[] }
  }) => void
  createWatchChannel: (
    data: {
      componentId: string
      channelId: string
      newChannelWatch: Pick<IChannelWatch, 'channel' | 'stateProperties'>
    },
    event?: any
  ) => void
  markChatAsUnread: (data: IMarkChatAsUnread) => void
  markChatAsRead: (data: {
    componentId: string
    channel: Channel<DefaultGenerics>
    onUpdate?: () => void
  }) => void
  markAdminAsAssigned: (data: {
    componentId: string
    channel: Channel<DefaultGenerics>
    event: any
    onUpdate?: () => void
  }) => void
  setAdminAssignment: (data: {
    componentId: string
    event: any
    onUpdate?: ({
      oldAdminAssignment,
      newAdminAssignment,
    }: IAssignedAdminStatusUpdate) => void
  }) => Promise<void>
  addNewAdvisorChatToWatchedChannels: (data: {
    componentId: string
    event: any
    onCreatedChannel: ({
      channel,
    }: {
      channel: Channel<DefaultGenerics>
    }) => void
  }) => Promise<void>
  subscribeToEvents: (data: IEventSubscriptions) => void
  unsubscribeFromWatchedChannels: (data: {
    componentId: string
  }) => Promise<void>
  fetchChannelsWithUnreadConversations: (data: {
    assignedAdminUserId: string
    archivedOnly?: boolean
  }) => Promise<AdvisorChannel[]>
  fetchChannelsWithUnassignedConversations: (data?: {
    archivedOnly?: boolean
  }) => Promise<AdvisorChannel[]>
}

export const AdvisorRealtimeEventsContext =
  createContext<AdvisorRealtimeEventsContextState>(
    {} as AdvisorRealtimeEventsContextState
  )

const AdvisorRealtimeEventProvider: React.FC = ({ children }) => {
  const componentState = useRef<IComponentState>({})
  const queryHistory = useRef<{
    [channelId: string]: Channel<DefaultGenerics>
  }>({})

  const activeNotificationEvents = useRef<{
    [componentId: string]: { unsubscribe: () => void }[]
  }>({})
  const activeChannelEvents = useRef<{
    [channelId: string]: { unsubscribe: () => void }[]
  }>({})

  const activeWatchChannels = useRef<{
    [channelId: string]: Channel<DefaultGenerics>
  }>({})

  const suppressMessagesWithAutomaticResponsesValidation = (
    { channel, event }: IEventSubCallbackParams,
    callback: EventSubCallbackParam<IEventSubCallbackParams>
  ) => {
    const latestMessage = channel?.lastMessage()
    if (!latestMessage || (latestMessage?.extra as any)?.automaticResponse) {
      return
    }

    callback({ channel, event })
  }

  const belongsToAdminValidation = (
    { channel, event }: IEventSubCallbackParams,
    callback: EventSubCallbackParam<IEventSubCallbackParams>
  ) => {
    if (event?.user?.role !== 'admin') {
      return
    }

    callback({ channel, event })
  }

  const getSessionAdminUserId = () => streamChatClient.userID

  const ADVISOR_GENERAL_EVENT: AssistanceGeneralEventDefinition =
    useMemo(() => {
      return {
        [AssistanceEvent.NEW_ADVISOR_CHAT]: {
          type: 'new_advisor_chat',
          event: (arg, callback) => callback(arg),
        },
        [AssistanceEvent.NEW_ARCHIVED_CHAT]: {
          type: 'new_archived_chat',
          event: (arg, callback) => callback(arg),
        },
        [AssistanceEvent.NEW_ADMIN_ASSIGNMENT]: {
          type: 'new_admin_assignment',
          event: (arg, callback) => callback(arg),
        },
        [AssistanceEvent.CUSTOM_ADMIN_ASSIGNED_TO_CHAT]: {
          type: 'advisor_assigned_to_chat',
          event: (arg, callback) => callback(arg),
        },
      }
    }, [])

  const ADVISOR_CHANNEL_EVENT: AssistanceChannelEventDefinition =
    useMemo(() => {
      return {
        [AssistanceEvent.INITIAL_ADMIN_ASSIGNMENT]: {
          type: 'channel.updated',
          event: (arg, callback) => callback(arg),
        },
        [AssistanceEvent.NEW_MESSAGE]: {
          type: 'message.new',
          event: (arg, callback) =>
            suppressMessagesWithAutomaticResponsesValidation(arg, callback),
        },
        [AssistanceEvent.READ_MESSAGE]: {
          type: 'message.read',
          event: (arg, callback) => belongsToAdminValidation(arg, callback),
        },
      }
    }, [])

  const isComponentStateInitialized = ({
    componentId,
  }: {
    componentId: string
  }) => !!getComponentState({ componentId })

  const getAllStatePropertyValues = useCallback(
    ({
      channel,
      properties,
    }: {
      channel: Channel<DefaultGenerics>
      properties: (keyof IChannelWatchStateProperties)[]
    }) =>
      Object.fromEntries(
        properties.map((property) => [
          property,
          getStatePropertyValue({ channel, property }),
        ])
      ) as IChannelWatchStateProperties,
    []
  )

  const getStatePropertyValue = ({
    channel,
    property,
  }: {
    channel: Channel<DefaultGenerics>
    property: keyof IChannelWatchStateProperties
  }) => {
    switch (property) {
      case 'isAdminAssigned':
        return channel.data?.isAdminAssigned
      case 'unreadConversationCount':
        return channel.countUnread()
      default:
        return channel.data?.[property]
    }
  }

  const getUnreadConversationCount = useCallback(
    () =>
      Object.values(activeWatchChannels.current).filter(
        (channel) =>
          channel.countUnread() > 0 &&
          channel.state.members[getSessionAdminUserId()!]
      ).length,
    []
  )

  const registerNewWatchedChannels = useCallback(
    ({
      componentId,
      channels,
    }: {
      componentId: string
      channels: Channel<DefaultGenerics>[]
    }) => {
      const initializeActiveChannelEvents = (channelId: string) => {
        if (!activeChannelEvents.current[channelId]) {
          activeChannelEvents.current[channelId] = []
        }
      }

      const newChannels: { [_: string]: Channel<DefaultGenerics> } =
        Object.fromEntries(channels.map((channel) => [channel.id, channel]))

      const mergedChannels = {
        ...activeWatchChannels.current,
        ...newChannels,
      }

      activeWatchChannels.current = mergedChannels

      const currentComponent = getComponentState({ componentId })

      const componentsToUpdate = [
        ...Object.keys(componentState.current),
        ...(!componentState.current?.[componentId] ? [componentId] : []),
      ]
      componentsToUpdate.forEach((componentKey) => {
        // This loop is done here to ensure each state is unique (deep clone)
        const newChannelWatches = Object.fromEntries(
          Object.values(mergedChannels).map((channel) => {
            const channelId = channel.id!
            initializeActiveChannelEvents(channelId)

            return [
              channelId,
              {
                channel: currentComponent?.watchedChannels?.[channelId]
                  ? getWatchedChannel({ componentId, channelId })?.channel
                  : channel,
                stateProperties: getAllStatePropertyValues({
                  channel,
                  properties: [
                    'assignedAdminUserId',
                    'isAdminAssigned',
                    'unreadConversationCount',
                  ],
                }),
              },
            ]
          })
        ) as { [_: string]: IChannelWatch }

        componentState.current[componentKey] = {
          ...(componentState.current?.[componentKey] ?? {
            notificationSubscriptionLookup: [],
            channelSubscriptionLookup: [],
          }),
          watchedChannels: newChannelWatches,
        }
      })

      return getComponentState({ componentId }).watchedChannels
    },
    [getAllStatePropertyValues]
  )

  const registerNewWatchedChannelsComponentState = useCallback(
    ({
      componentId,
      channels,
    }: {
      componentId: string
      channels: Channel<DefaultGenerics>[]
    }) => {
      const watchedChannels = registerNewWatchedChannels({
        componentId,
        channels,
      })

      return Object.values(watchedChannels)
    },
    [registerNewWatchedChannels]
  )

  const registerNewWatchedChannelsComponentStateFromIds = useCallback(
    async ({
      componentId,
      channelIds,
    }: {
      componentId: string
      channelIds: string[]
    }) => {
      const currentComponentState = getComponentState({ componentId })
      const newChannelCidsToLoad: string[] = []
      channelIds.forEach((id) => {
        const channelIsNotLoaded = !(
          id in (currentComponentState?.watchedChannels ?? {})
        )

        if (channelIsNotLoaded) {
          newChannelCidsToLoad.push(`${StreamChatChannelType.ASSISTANCE}:${id}`)
        }
      })

      if (newChannelCidsToLoad.length > 0) {
        const newChannels = await streamChatClient.queryChannels(
          {
            type: StreamChatChannelType.ASSISTANCE,
            cid: {
              $in: newChannelCidsToLoad,
            },
          },
          {},
          { watch: true, presence: true }
        )

        registerNewWatchedChannels({
          componentId,
          channels: newChannels,
        })
      }
    },
    [registerNewWatchedChannels]
  )

  const getWatchedChannel = ({
    componentId,
    channelId,
  }: {
    componentId: string
    channelId: string | undefined
  }) =>
    channelId
      ? componentState.current?.[componentId].watchedChannels?.[channelId]
      : undefined

  const getStreamChatChannel = async ({ channelId }: { channelId: string }) =>
    activeWatchChannels.current?.[channelId] ??
    (await loadChannelById({ channelId }))

  const getActiveChannelSubscriptions = ({
    channelId,
  }: {
    channelId: string
  }) => activeChannelEvents.current[channelId]

  const getComponentState = ({ componentId }: { componentId: string }) =>
    componentState.current?.[componentId]

  const setWatchedChannelProperties = useCallback(
    <T extends IChannelWatch, K extends IChannelWatchStateProperties>({
      componentId,
      channelId,
      set,
    }: {
      componentId: string
      channelId: string | undefined
      set:
        | { base: IPropertyDef<T>[]; state?: IPropertyDef<K>[] }
        | { state: IPropertyDef<K>[]; base?: IPropertyDef<T>[] }
    }) => {
      const watchedChannel = getWatchedChannel({ componentId, channelId })
      if (!channelId || !watchedChannel) {
        return
      }

      set.base?.forEach((propertyDef) => {
        const { property, newValue } = propertyDef
        ;(watchedChannel as T)[property] = newValue
      })

      set.state?.forEach((propertyDef) => {
        const { property, newValue } = propertyDef
        ;(watchedChannel.stateProperties as K)[property] = newValue
      })
    },
    []
  )

  const createWatchChannel = useCallback(
    ({
      componentId,
      channelId,
      newChannelWatch,
    }: {
      componentId?: string
      channelId: string
      newChannelWatch: Pick<IChannelWatch, 'channel' | 'stateProperties'>
    }) => {
      if (componentId) {
        const watchChannel = getWatchedChannel({ componentId, channelId })
        if (watchChannel) {
          return watchChannel
        }
      } else {
        const loadedChannel = activeWatchChannels.current?.[channelId]
        if (loadedChannel) {
          return
        }
      }

      const newSubscriptions = Object.values(componentState.current)
        .flatMap((component) => component.channelSubscriptionLookup)
        .map((subscription) =>
          newChannelWatch.channel.on(
            subscription.type as EventTypes,
            subscription.callback
          )
        )

      Object.values(componentState.current).forEach((component) => {
        component.watchedChannels[channelId] = {
          ...newChannelWatch,
          stateProperties: getAllStatePropertyValues({
            channel: newChannelWatch.channel,
            properties: [
              'assignedAdminUserId',
              'isAdminAssigned',
              'unreadConversationCount',
            ],
          }),
        }
      })

      activeWatchChannels.current[channelId] = newChannelWatch.channel
      activeChannelEvents.current[channelId] = newSubscriptions

      return newChannelWatch as IChannelWatch
    },
    [getAllStatePropertyValues]
  )

  const markChatAsUnread = ({
    componentId,
    channel,
    onUpdate,
  }: IMarkChatAsUnread) => {
    const channelId = channel?.id
    const watchedChannel = getWatchedChannel({
      componentId,
      channelId,
    })

    if (!watchedChannel) {
      return
    }

    const currentUnreadInState =
      watchedChannel.stateProperties.unreadConversationCount!
    const newUnread = channel.countUnread()
    const hasNewUnreadMessageUpdate = newUnread - currentUnreadInState > 0

    if (hasNewUnreadMessageUpdate) {
      setWatchedChannelProperties({
        componentId,
        channelId,
        set: {
          state: [
            {
              property: 'unreadConversationCount',
              newValue: newUnread,
            },
          ],
        },
      })
    }

    onUpdate?.({ hasNewUnreadMessageUpdate })
  }

  const markChatAsRead = useCallback(
    ({
      componentId,
      channel,
      onUpdate,
    }: {
      componentId: string
      channel: Channel<DefaultGenerics>
      onUpdate?: () => void
    }) => {
      const channelId = channel.id!
      const watchedChannel = getWatchedChannel({
        componentId,
        channelId,
      })

      if (!watchedChannel) {
        return
      }

      const currentUnread =
        watchedChannel.stateProperties.unreadConversationCount!
      const newUnread = channel.countUnread()

      if (currentUnread > 0 && newUnread <= 0) {
        setWatchedChannelProperties({
          componentId,
          channelId,
          set: {
            state: [
              {
                property: 'unreadConversationCount',
                newValue: newUnread,
              },
            ],
          },
        })

        onUpdate?.()
      }
    },
    [setWatchedChannelProperties]
  )

  const markAdminAsAssigned = ({
    componentId,
    channel,
    event,
    onUpdate,
  }: {
    componentId: string
    channel: Channel<DefaultGenerics>
    event: any
    onUpdate?: () => void
  }) => {
    const channelId = channel.id!
    const currentIsAdminAssigned = getWatchedChannel({ componentId, channelId })
      ?.stateProperties.isAdminAssigned

    // An assigned admin always stays assigned (For now)
    if (currentIsAdminAssigned || !event) {
      return
    }

    const data = event.channel as AssistanceChannelData
    if (data?.isAdminAssigned) {
      setWatchedChannelProperties({
        componentId,
        channelId,
        set: {
          state: [
            {
              property: 'isAdminAssigned',
              newValue: true,
            },
          ],
        },
      })

      onUpdate?.()
    }
  }

  const setAdminAssignment = async ({
    componentId,
    event,
    onUpdate,
  }: {
    componentId: string
    event: IAdvisorAssignedToChatCustomUserEvent
    onUpdate?: ({
      oldAdminAssignment,
      newAdminAssignment,
      wasChatUnread,
      markMessagesAsRead,
    }: IAssignedAdminStatusUpdate) => void
  }) => {
    const channelId = event?.channelId

    let watch = getWatchedChannel({ componentId, channelId })
    if (!watch) {
      watch = await loadChannelWatchById({
        channelId,
        componentId,
      })
    } else {
      await getUpdatedChannels({ channelIds: [channelId] })
    }

    const { previous: oldAdminAssignment, current: newAdminAssignment } =
      event?.assignedAdmin

    const markMessagesAsRead: boolean = event?.markMessagesAsRead
      ? JSON.parse(event?.markMessagesAsRead)
      : false

    const wasChatUnread: boolean = event?.wasChatUnread
      ? JSON.parse(event?.wasChatUnread)
      : false

    if (newAdminAssignment && oldAdminAssignment !== newAdminAssignment) {
      setWatchedChannelProperties({
        componentId,
        channelId,
        set: {
          state: [
            {
              property: 'assignedAdminUserId',
              newValue: newAdminAssignment,
            },
          ],
        },
      })
      onUpdate?.({
        newAdminAssignment,
        oldAdminAssignment,
        markMessagesAsRead,
        wasChatUnread,
      })
    }
  }

  const addNewAdvisorChatToWatchedChannels = async ({
    componentId,
    event,
    onCreatedChannel,
  }: {
    componentId: string
    event: any
    onCreatedChannel: ({
      channel,
    }: {
      channel: Channel<DefaultGenerics>
    }) => void
  }) => {
    const channelId = event.text as string

    const newChannel = await getChannelFromQueryHistory({ channelId })
    const currentChannel = getWatchedChannel({ componentId, channelId })

    if (newChannel?.id && !currentChannel) {
      createWatchChannel({
        componentId,
        channelId,
        newChannelWatch: {
          channel: newChannel,
          stateProperties: {
            isAdminAssigned: newChannel.data?.isAdminAssigned ?? false,
            assignedAdminUserId: newChannel.data?.assignedAdminUserId,
          },
        },
      })

      onCreatedChannel?.({ channel: newChannel })
    }
  }

  const activateSubscriptions = useCallback(() => {
    cleanUpNotificationSubscriptions()
    cleanUpChannels({ endWatch: false })

    Object.keys(componentState.current).forEach((componentId) => {
      activeNotificationEvents.current[componentId] = getComponentState({
        componentId,
      }).notificationSubscriptionLookup.map((eventValue) => {
        const eventDefinition =
          ADVISOR_GENERAL_EVENT[eventValue.name as AssistanceEvent]!
        return streamChatClient.on(eventValue.type, (event) =>
          eventDefinition.event({ event }, eventValue.callback)
        )
      })
    })

    const activeChannelList = Object.values(activeWatchChannels.current)
    const refreshChannelBucket: string[] = []

    activeChannelList.forEach((channel) => {
      const eventsOnChannel = getActiveChannelSubscriptions({
        channelId: channel.id!,
      })

      while (eventsOnChannel.length > 0) {
        eventsOnChannel.pop()?.unsubscribe()
      }

      activeChannelEvents.current[channel.id!] = Object.values(
        componentState.current
      )
        .flatMap((component) => component.channelSubscriptionLookup)
        .map((eventValue) => {
          const eventDefinition =
            ADVISOR_CHANNEL_EVENT[eventValue.name as AssistanceEvent]!

          return channel.on(eventValue.type as EventTypes, (event) =>
            eventDefinition.event({ event, channel }, eventValue.callback)
          )
        })

      const channelIsNotWatched =
        !channel.state.watchers[streamChatClient.userID!]

      if (channelIsNotWatched) {
        refreshChannelBucket.push(channel.id!)
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const subscribeToEvents = useCallback(
    ({
      componentId,
      generalSubscriptionsToEnable,
      channelSubscriptionsToEnable,
    }: IEventSubscriptions) => {
      const componentToSubscribe = getComponentState({ componentId })

      if (generalSubscriptionsToEnable) {
        componentToSubscribe.notificationSubscriptionLookup = Object.entries(
          generalSubscriptionsToEnable
        ).map(([event, channelSub]) => {
          const eventDefinition =
            ADVISOR_GENERAL_EVENT[event as AssistanceEvent]!

          return {
            name: event as AssistanceEvent,
            type: eventDefinition.type,
            callback: channelSub,
          }
        })
      }

      if (channelSubscriptionsToEnable) {
        componentToSubscribe.channelSubscriptionLookup = Object.entries(
          channelSubscriptionsToEnable
        ).map(([event, channelSub]) => {
          const eventDefinition =
            ADVISOR_CHANNEL_EVENT[event as AssistanceEvent]!

          return {
            name: event as AssistanceEvent,
            type: eventDefinition.type,
            callback: channelSub,
          }
        })
      }

      activateSubscriptions()
    },
    [ADVISOR_CHANNEL_EVENT, ADVISOR_GENERAL_EVENT, activateSubscriptions]
  )

  const getChannelFromQueryHistory = async ({
    channelId,
  }: {
    channelId: string
  }) => {
    let retrievedChannel = queryHistory.current[channelId]
    if (!retrievedChannel) {
      queryHistory.current[channelId] = (
        await streamChatClient.queryChannels(
          {
            cid: `${StreamChatChannelType.ASSISTANCE}:${channelId}`,
          },
          {},
          { watch: true, presence: true, limit: 1 }
        )
      )?.[0]

      retrievedChannel = queryHistory.current[channelId]
    }

    return retrievedChannel as Channel<DefaultGenerics> & {
      data: AssistanceChannelData
    }
  }

  // Note: Need to query channels to ensure the data is up to date, not sure why
  const getUpdatedChannels = async ({
    channelIds,
  }: {
    channelIds: string[]
  }) => {
    if (channelIds.length <= 0) {
      return
    }

    const CHANNEL_MAX = 30
    const channelGroup = []

    let page = 0
    do {
      channelGroup.push(
        await streamChatClient.queryChannels(
          {
            type: StreamChatChannelType.ASSISTANCE,
            cid: {
              $in: channelIds.map(
                (id) => `${StreamChatChannelType.ASSISTANCE}:${id}`
              ),
            },
          },
          {},
          {
            limit: CHANNEL_MAX,
            offset: page++ * CHANNEL_MAX,
            watch: true,
          }
        )
      )
    } while (channelGroup[channelGroup.length - 1].length === CHANNEL_MAX)
    const updatedChannels = channelGroup.flatMap((x) => x)

    updatedChannels.forEach((channel) => {
      activeWatchChannels.current[channel.id!] = channel

      // Note: State properties are meant to be unique per component, so don't try to optimize by creating it in the channel instead.
      Object.values(componentState.current).forEach((component) => {
        component.watchedChannels[channel.id!] = {
          channel,
          stateProperties: getAllStatePropertyValues({
            channel,
            properties: [
              'assignedAdminUserId',
              'isAdminAssigned',
              'unreadConversationCount',
            ],
          }),
        }
      })
    })

    return updatedChannels
  }

  const loadChannelById = async ({
    channelId,
    componentId,
    loadProperties = [
      'assignedAdminUserId',
      'isAdminAssigned',
      'unreadConversationCount',
    ],
  }: {
    channelId: string
    componentId?: string
    loadProperties?: (keyof IChannelWatchStateProperties)[]
  }) => {
    const loadedChannel = activeWatchChannels.current?.[channelId]
    if (loadedChannel) {
      return loadedChannel
    }

    const queriedChannel = (
      await streamChatClient.queryChannels(
        {
          cid: `${StreamChatChannelType.ASSISTANCE}:${channelId}`,
        },
        {},
        { watch: true, presence: true, limit: 1 }
      )
    )?.[0]

    if (!queriedChannel) {
      return
    }

    createWatchChannel({
      componentId,
      channelId,
      newChannelWatch: {
        channel: queriedChannel,
        stateProperties: getAllStatePropertyValues({
          channel: queriedChannel,
          properties: loadProperties,
        }),
      },
    })

    return queriedChannel
  }

  const loadChannelWatchById = async ({
    channelId,
    componentId,
    loadProperties = [
      'assignedAdminUserId',
      'isAdminAssigned',
      'unreadConversationCount',
    ],
  }: {
    channelId: string
    componentId: string
    loadProperties?: (keyof IChannelWatchStateProperties)[]
  }) => {
    const watchChannel = getWatchedChannel({ componentId, channelId })
    if (watchChannel) {
      return watchChannel
    }

    const queriedChannel = await loadChannelById({
      componentId,
      channelId,
      loadProperties,
    })
    if (!queriedChannel) {
      return
    }

    return getWatchedChannel({ componentId, channelId })
  }

  const cleanUpNotificationSubscriptions = useCallback(
    ({ componentId }: { componentId?: string } = {}) => {
      if (Object.keys(activeNotificationEvents.current).length <= 0) {
        return
      }

      const subscriptions = componentId
        ? activeNotificationEvents.current[componentId]
        : Object.values(activeNotificationEvents.current).flat()

      while (subscriptions && subscriptions.length > 0) {
        subscriptions.pop()?.unsubscribe()
      }
    },
    []
  )

  const cleanUpChannels = useCallback(
    async (
      {
        componentId,
        endWatch,
      }: { componentId?: string; endWatch?: boolean } = { endWatch: true }
    ) => {
      const componentsToClean = componentId
        ? [getComponentState({ componentId })]
        : Object.values(componentState.current) ?? []

      if (!Array.isArray(componentsToClean)) {
        return
      }

      const watchList =
        componentsToClean
          ?.flatMap((component) => Object.values(component.watchedChannels))
          .flat() ?? []

      for (const watch of watchList) {
        const { channel } = watch
        const channelSubscriptions = getActiveChannelSubscriptions({
          channelId: channel.id!,
        })

        while (channelSubscriptions?.length > 0) {
          channelSubscriptions.pop()?.unsubscribe()
        }

        if (endWatch) {
          await channel.stopWatching()
        }
      }
    },
    []
  )

  const unsubscribeFromWatchedChannels = async ({
    componentId,
  }: {
    componentId: string
  }) => {
    await cleanUpChannels({ componentId })
  }

  useEffect(() => {
    const onCleanup = async () => {
      await cleanUpChannels()
      cleanUpNotificationSubscriptions()
    }

    return () => {
      onCleanup()
    }
  }, [cleanUpNotificationSubscriptions, cleanUpChannels])

  const fetchChannelsWithUnreadConversations = useCallback(
    async ({
      assignedAdminUserId,
      archivedOnly,
    }: {
      assignedAdminUserId: string
      archivedOnly?: boolean
    }) => {
      const MAX_CHANNEL_QUERY = 30

      const channelGroup: AdvisorChannel[] = []
      let channels: AdvisorChannel[] = []
      let page = 0
      do {
        channels = (await streamChatClient.queryChannels(
          {
            type: StreamChatChannelType.ASSISTANCE,
            isReadyForSupport: true,
            members: { $in: [assignedAdminUserId] },
            ...(archivedOnly && { isArchived: true }),
          },
          { has_unread: -1 },
          {
            limit: MAX_CHANNEL_QUERY,
            offset: MAX_CHANNEL_QUERY * page++,
            watch: true,
          }
        )) as AdvisorChannel[]

        channelGroup.push(...channels)
      } while (channels.length >= MAX_CHANNEL_QUERY)

      return channelGroup.filter((v) => v.state.unreadCount > 0)
    },
    []
  )

  const fetchChannelsWithUnassignedConversations = useCallback(
    async ({ archivedOnly }: { archivedOnly?: boolean } = {}) => {
      const MAX_CHANNEL_QUERY = 30

      const channelGroup: AdvisorChannel[] = []
      let channels: AdvisorChannel[] = []
      let page = 0
      do {
        channels = (await streamChatClient.queryChannels(
          {
            type: StreamChatChannelType.ASSISTANCE,
            isAdminAssigned: false,
            isReadyForSupport: true,
            ...(archivedOnly && { isArchived: true }),
          },
          {},
          {
            limit: MAX_CHANNEL_QUERY,
            offset: MAX_CHANNEL_QUERY * page++,
            watch: true,
          }
        )) as AdvisorChannel[]

        channelGroup.push(...channels)
      } while (channels.length >= MAX_CHANNEL_QUERY)

      return channelGroup
    },
    []
  )

  return (
    <AdvisorRealtimeEventsContext.Provider
      value={{
        isComponentStateInitialized,
        getUnreadConversationCount,
        getStreamChatChannel,
        getWatchedChannel,
        registerNewWatchedChannelsComponentStateFromIds,
        registerNewWatchedChannelsComponentState,
        setWatchedChannelProperties,
        createWatchChannel,
        markChatAsUnread,
        markChatAsRead,
        markAdminAsAssigned,
        setAdminAssignment,
        addNewAdvisorChatToWatchedChannels,
        subscribeToEvents,
        unsubscribeFromWatchedChannels,
        fetchChannelsWithUnreadConversations,
        fetchChannelsWithUnassignedConversations,
      }}
    >
      {children}
    </AdvisorRealtimeEventsContext.Provider>
  )
}

export { AdvisorRealtimeEventProvider }
