import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { FormikProps } from 'formik'

import authHook from '@hooks/useAuth'
import navigationHook from '@hooks/useNavigation'
import toastHook from '@hooks/useToast'

import { errorMessages, errorTypes } from '@utils/errors'

interface IHandleServiceErrorOptions {
  skipRedirect?: boolean
  signOut?: () => Promise<void>
}

export interface IErrorCallbackType {
  err: string
  message: string
  values?: any
}

export interface IErrorCallback {
  type: string
  callback?(data: IErrorCallbackType): void
}

const useErrorHandler = <T>(formik?: FormikProps<T>) => {
  const { show: showToast } = toastHook.useToast()
  const { t } = useTranslation()
  const { navigateTo } = navigationHook.useNavigation()

  const { impersonatedBy, signOut, impersonate } = authHook.useAuth()

  const handleError = useCallback(
    ({
      defaultErrorMessage,
      exception,
      errorCallbacks,
      options,
      defaultErrorCallback,
    }: {
      defaultErrorMessage?: string
      exception: any
      errorCallbacks?: IErrorCallback[]
      options?: IHandleServiceErrorOptions
      defaultErrorCallback?: (data: IErrorCallbackType) => void
    }) => {
      const generalErrors: string[] = []

      if (Array.isArray(exception)) {
        const uniqueError = exception[0][3]
        const disabledUser = uniqueError === errorTypes.USER_DISABLED

        if (disabledUser) {
          showToast({
            type: 'error',
            message: exception[0][0],
          })

          if (impersonatedBy) {
            impersonate(undefined)
          } else {
            const signOutFn = options?.signOut ?? signOut
            signOutFn?.()
          }

          return
        }
      }

      if (!options?.skipRedirect) {
        switch (exception.statusCode) {
          case 403:
            navigateTo({ path: '/403' })
            break
          case 404:
            navigateTo({ path: '/404' })
            break
          case 500:
            navigateTo({ path: '/500' })
            break
        }
      }

      if (Array.isArray(exception)) {
        exception.forEach((v) => {
          const [errorMessage, field, value, uniqueError, values] = v
          const translatedError = t(errorMessage)

          const errorCallback = errorCallbacks?.find(
            (v) => v.type === uniqueError
          )

          if (errorCallback) {
            errorCallback.callback?.({
              err: v,
              message: translatedError,
              values,
            })
            return
          }

          if (!!defaultErrorCallback) {
            defaultErrorCallback({ err: v, message: translatedError, values })
            return
          }

          let errorMessageToShow: string | undefined

          if (!translatedError) {
            errorMessageToShow = defaultErrorMessage
          } else {
            errorMessageToShow = value
              ? `${translatedError} ${value}`
              : translatedError
          }

          if (!formik || !field) {
            generalErrors.push(errorMessageToShow ?? '')
            return
          }

          formik.setFieldError(field, errorMessageToShow)
          return
        })

        if (generalErrors.length > 0) {
          const errorMessages = generalErrors.join('\n')
          showToast({
            type: 'error',
            message: errorMessages,
          })
        }
        return
      }

      const errorMessage: string =
        exception.message || (defaultErrorMessage ?? errorMessages.DEFAULT)

      showToast({
        type: 'error',
        message: t(errorMessage),
      })
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, showToast]
  )

  return {
    handleError,
  }
}

export default {
  useErrorHandler,
}
