import { SxProps, Theme } from '@mui/material'

export const textFieldWithLeftIconSx: SxProps<Theme> = {
  '.MuiFilledInput-input': {
    paddingLeft: '1rem',
  },

  '.MuiInputLabel-formControl': {
    left: '3.4rem',
  },

  '.MuiFilledInput-root': {
    paddingLeft: '4.2rem',
  },

  '.MuiInputAdornment-positionStart': {
    position: 'absolute',
    left: '1.7rem',
    top: '0.8rem',
  },
}

export const inputLabelPropsShrinkSx = ({
  variant,
  hasValue,
}: {
  variant: 'filled' | 'standard' | 'outlined' | undefined
  hasValue: boolean
}): SxProps<Theme> => {
  return {
    ...(!hasValue &&
      variant === 'filled' && {
        transform: 'translate(1.7rem, 1.2rem) scale(1)',
        letterSpacing: '0.015rem',
      }),

    ...(hasValue &&
      variant === 'filled' && {
        transform: 'translate(1.7rem, 0.4rem) scale(0.75) !important',
        letterSpacing: '0.015rem !important',
      }),

    ...(!hasValue &&
      variant === 'outlined' && {
        transform: 'translate(1.7rem, 1.2rem) scale(1)',
        letterSpacing: '0.015rem',
      }),

    ...(hasValue &&
      variant === 'outlined' && {
        transform: 'translate(1.7rem, -0.8rem) scale(0.75) !important',
        letterSpacing: '0.015rem !important',
      }),
  }
}
