import { FC, createContext, useCallback, useState } from 'react'

import type { IAlert } from '@utils/types'

export type ToastContextState = {
  show(data: IAlert): void
  remove(): void
  message?: IAlert
  isVisible: boolean
}

export const ToastContext = createContext<ToastContextState>(
  {} as ToastContextState
)

const ToastProvider: FC = ({ children }) => {
  const [toastMessage, setToastMessage] = useState<IAlert>()
  const [isToastVisible, setIsToastVisible] = useState(false)

  const show = useCallback(({ type, message, action }: IAlert) => {
    setToastMessage({ type, message, action })
    setIsToastVisible(true)
  }, [])

  const remove = () => {
    setIsToastVisible(false)
  }

  return (
    <ToastContext.Provider
      value={{
        show,
        remove,
        message: toastMessage,
        isVisible: isToastVisible,
      }}
    >
      {children}
    </ToastContext.Provider>
  )
}

export { ToastProvider }
