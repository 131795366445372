import { SxProps, Theme } from '@mui/material'

const scaleUpTransition: SxProps<Theme> = {
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.04)',
  },
}

export const lineClamp = ({ lines }: { lines: number }): SxProps<Theme> => {
  return {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: lines,
    WebkitBoxOrient: 'vertical',
  }
}

export default {
  scaleUpTransition,
}
