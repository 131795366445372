import { useCallback, useState } from 'react'

import WindowEventUtils from '@utils/window-events'

export const useLocalStorage = <T = any>(key: string, initialValue: T) => {
  const getValue = useCallback((): T => {
    if (typeof window?.localStorage === 'undefined') {
      return initialValue
    }

    const item = window.localStorage.getItem(key)
    return (item ? JSON.parse(item) : initialValue) as T
  }, [initialValue, key])

  const [storedValue, setStoredValue] = useState<T>(getValue)

  const setValue = useCallback(
    (
      value: T,
      {
        customWindowEvent,
      }: {
        customWindowEvent?: keyof WindowEventMap
      } = {}
    ) => {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value

      setStoredValue(valueToStore)

      window.localStorage.setItem(key, JSON.stringify(valueToStore))

      if (customWindowEvent) {
        WindowEventUtils.dispatch({
          event: customWindowEvent,
          value: valueToStore,
        })
      }
    },
    [key, storedValue]
  )

  return {
    storedValue,
    getValue,
    setValue,
  }
}

export default {
  useLocalStorage,
}
