import { SxProps, Theme } from '@mui/material'

import { getChatStatusTextColor } from '@utils/chats'
import type { ChatStatus } from '@utils/types'

export const chatChannelItemSx = ({
  userNotAvailable,
}: {
  userNotAvailable: boolean
}): SxProps<Theme> => {
  return {
    padding: {
      xs: userNotAvailable ? '1.2rem 1.6rem 0.8rem' : '1.2rem 1.6rem',
      md: userNotAvailable ? '1.2rem 2.4rem 0.8rem' : '1.2rem 2.4rem',
    },
  }
}

export const userInfoWithAvatarWrapperSx = ({
  userNotAvailable,
}: {
  userNotAvailable: boolean
}): SxProps<Theme> => {
  return {
    display: 'flex',
    alignItems: 'center',
    gap: '1.6rem',
    backgroundColor: 'transparent',
    opacity: userNotAvailable ? 0.4 : 1,
  }
}

export const companyChatChannelHeaderSx: SxProps<Theme> = {
  padding: {
    xs: '1.4rem 1.6rem 0.4rem',
    md: '2.2rem 2.4rem 0.4rem',
  },
}

export const companyChatChannelHeaderTitleSx: SxProps<Theme> = {
  lineHeight: '2.4rem',
  color: (theme) => theme.palette.text.secondary,
}

export const userInfoWithoutAvatarWrapperSx: SxProps<Theme> = {
  flex: 1,
}

export const lastOnlineDateSx: SxProps<Theme> = {
  marginLeft: 'auto',
}

export const chatStatusTextSx = ({
  chatStatus,
}: {
  chatStatus: ChatStatus
}): SxProps<Theme> => {
  return {
    alignSelf: 'end',
    color: getChatStatusTextColor({ chatStatus }),
  }
}

export const userPositionTextSx: SxProps<Theme> = {
  color: (theme) => theme.palette.text.secondary,
}

export const userAvatarSx: SxProps<Theme> = {
  width: '4.4rem',
  height: '4.4rem',
}

export const userNameAndStatusWrapperSx: SxProps<Theme> = {
  flex: 1,
  flexWrap: 'wrap',
  gap: '0.8rem',
  alignItems: 'center',
}

export const lastUserPositionAndLastOnlineWrapperSx: SxProps<Theme> = {
  gap: '0.8rem',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: (theme) => theme.palette.text.secondary,
}

export const userNotAvailableWarningLabelSx: SxProps<Theme> = {
  marginTop: '1.6rem',
  alignSelf: 'start',
  display: 'inline-flex',
  backgroundColor: (theme) => theme.palette.background.paper,
}
