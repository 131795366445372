import { useEffect, useRef, useState } from 'react'

import {
  Breakpoint,
  SxProps,
  Theme,
  Tooltip,
  TooltipProps,
  Typography,
  TypographyProps,
} from '@mui/material'

import responsiveHook from '@hooks/useResponsive'

import { lineClamp } from '@utils/styles'

import { textSx, textWithTooltipSx, tooltipSx } from './styles'

export interface ITLGTypographyProps extends TypographyProps {
  maxLines?: number
  sx?: SxProps<Theme>
  tooltipSx?: SxProps<Theme>
  tooltipPosition?: TooltipProps['placement']
  component?: React.ElementType
  children: React.ReactNode
  responsiveVariant?: Partial<Record<Breakpoint, TypographyProps['variant']>>
  showTooltip?: boolean
  tooltipDelay?: number
}

const TLGTypography = ({
  maxLines = 3,
  tooltipPosition = 'bottom',
  showTooltip = false,
  tooltipSx: tooltipSxOverride,
  tooltipDelay,
  responsiveVariant,
  children,
  ...rest
}: ITLGTypographyProps) => {
  const textRef = useRef<any>(null)
  const [showTooltipInternal, setShowTooltipInternal] = useState(false)

  useEffect(() => {
    if (showTooltip) {
      const isTextOverflowing =
        textRef.current.scrollHeight > textRef.current.offsetHeight + 1

      if (!showTooltipInternal && isTextOverflowing) {
        setShowTooltipInternal(true)
      }
    }
  }, [showTooltip, showTooltipInternal, textRef])

  const { getCurrentBreakpoint, findResponsiveVariant } =
    responsiveHook.useResponsive()
  const currentBreakpoint = getCurrentBreakpoint()

  let variant: TypographyProps['variant'] = undefined

  if (responsiveVariant) {
    variant = findResponsiveVariant({
      responsiveVariant,
      currentBreakpoint,
    })
  }

  if (showTooltip) {
    return (
      <Tooltip
        componentsProps={{
          tooltip: { sx: { ...tooltipSx, ...tooltipSxOverride } as SxProps },
        }}
        title={children}
        disableHoverListener={!showTooltipInternal}
        placement={tooltipPosition}
        enterDelay={tooltipDelay}
        enterNextDelay={tooltipDelay}
      >
        <Typography
          ref={textRef}
          variant={variant}
          {...rest}
          sx={
            {
              ...textWithTooltipSx,
              ...lineClamp({ lines: maxLines }),
              ...rest.sx,
            } as SxProps<Theme>
          }
        >
          {children}
        </Typography>
      </Tooltip>
    )
  }

  return (
    <Typography
      variant={variant}
      {...rest}
      sx={
        {
          ...textSx,
          ...rest.sx,
        } as SxProps<Theme>
      }
    >
      {children}
    </Typography>
  )
}

export { TLGTypography }
