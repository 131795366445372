import React, { FC, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { streamChatClient } from '@libs/stream-chat'

import authHook from '@hooks/useAuth'
import chatHook from '@hooks/useChat'
import errorHandlerHook from '@hooks/useErrorHandler'
import localStorageHook from '@hooks/useLocalStorage'
import pageVisibilityHook from '@hooks/usePageVisibility'
import toastHook from '@hooks/useToast'
import updateEffectHook from '@hooks/useUpdateEffect'

import DeviceUtils from '@utils/devices'
import { LOCAL_STORAGE_TAB_FOCUSED } from '@utils/local-storage'
import {
  ChatStatus,
  IAdmin,
  IBuyerVendorChatUserData,
  IVendor,
  IVisitorChatInfo,
  IVisitorChatUserData,
} from '@utils/types'

const TIMEOUT_TO_CONNECT_CHAT = 2_000
const TIMEOUT_TO_DISCONNECT_CHAT = 3_000
const TIMEOUT_TO_SEND_ONLINE_CHAT_STATUS_EVENTS = 5_000
const TIMEOUT_TO_SEND_IDLE_CHAT_STATUS_EVENTS = 1_000
const TIMEOUT_TO_SET_LOCAL_STORAGE_TLG_TAB_FOCUSED = 500

export interface IStreamChatManagedConnectorHandle {
  test: () => void
}

export interface IStreamChatManagedConnectorProps {
  userData?: IBuyerVendorChatUserData | IVisitorChatUserData | undefined
  authenticated: boolean
}

interface IConnectionComponent {
  onUserDataUpdate?: ({
    userDataToUpdate,
  }: {
    userDataToUpdate:
      | IBuyerVendorChatUserData
      | IVisitorChatUserData
      | undefined
  }) => void
}

const withStreamChatManagedConnector = <P,>(
  WrappedComponent: React.ComponentType<P>,
  ConnectionComponent: React.ComponentType<IConnectionComponent>,
  options: IStreamChatManagedConnectorProps
): FC<P> =>
  function ({ ...props }) {
    const { buyer, vendor, admin, isBuyer, isVendor, isAdmin } =
      authHook.useAuth()
    const { handleError } = errorHandlerHook.useErrorHandler()

    const {
      isConnectionOpen,
      connectToChat,
      disconnectFromChat,
      changeChatStatus,
    } = chatHook.useChat()
    const { show: showToast } = toastHook.useToast()
    const { t } = useTranslation()

    const userData = useRef<
      IBuyerVendorChatUserData | IVisitorChatUserData | undefined
    >(options?.userData)
    const authenticated = options.authenticated

    const isChatConnectionOpenRef = useRef(false)
    isChatConnectionOpenRef.current = isConnectionOpen

    const { getValue: getHasTabFocused, setValue: setHasTlgTabFocused } =
      localStorageHook.useLocalStorage(LOCAL_STORAGE_TAB_FOCUSED, false)

    const { visible } = pageVisibilityHook.usePageVisibility()
    const connectTimeoutRef = useRef<NodeJS.Timeout>()
    const disconnectTimeoutRef = useRef<NodeJS.Timeout>()

    const sendOnlineEventsTimeoutRef = useRef<NodeJS.Timeout>()
    const sendIdleEventsTimeoutRef = useRef<NodeJS.Timeout>()

    const visibleRef = useRef<boolean>()

    visibleRef.current = visible

    const getCurrentUser = useCallback(() => {
      if (authenticated) {
        if (isBuyer()) {
          return buyer
        } else if (isVendor()) {
          return vendor
        } else if (isAdmin()) {
          return admin
        }
      }

      return null
    }, [authenticated, isBuyer, isVendor, isAdmin, buyer, vendor, admin])

    const handleOnUpdateUserData = ({
      userDataToUpdate,
    }: {
      userDataToUpdate:
        | IBuyerVendorChatUserData
        | IVisitorChatUserData
        | undefined
    }) => {
      userData.current = userDataToUpdate
      connectUserToChat({ useTimeout: true })
    }

    const isAdminOrVendorAndIsOffline = useCallback(
      (user: IVendor | IAdmin | IVisitorChatInfo | undefined | null) => {
        if (isAdmin() || isVendor()) {
          return !(user as IAdmin | IVendor).chatStatus
        }

        return false
      },
      [isAdmin, isVendor]
    )

    const internalChangeChatStatus = async ({
      status,
    }: {
      status: ChatStatus
    }) => {
      try {
        await changeChatStatus({
          status,
        })
      } catch (err) {
        handleError({ exception: err })
      }
    }

    const connectUserToChat = useCallback(
      ({ useTimeout }: { useTimeout?: boolean } = {}) => {
        if (!userData.current || isConnectionOpen) {
          return
        }

        connectTimeoutRef.current = setTimeout(
          async () => {
            try {
              if (!visibleRef.current) {
                return
              }

              await connectToChat(userData.current!, {
                watchChannels: authenticated,
              })
            } catch (err: any) {
              showToast({
                type: 'error',
                message: t(err.message),
              })
            }
          },
          useTimeout ? TIMEOUT_TO_CONNECT_CHAT : 0
        )
      },
      [isConnectionOpen, authenticated, connectToChat, showToast, t]
    )

    const handleOnPageVisibilityChange = useCallback(() => {
      if (authenticated && !admin && !vendor && !buyer) {
        return
      }

      if (!visible) {
        if (connectTimeoutRef.current) {
          clearTimeout(connectTimeoutRef.current!)
          connectTimeoutRef.current = undefined
        }

        disconnectTimeoutRef.current = setTimeout(() => {
          if (!getHasTabFocused() && !DeviceUtils.isMobile()) {
            return
          }

          disconnectFromChat({
            hardDisconnect: false,
          })
        }, TIMEOUT_TO_DISCONNECT_CHAT)
      } else {
        if (disconnectTimeoutRef.current) {
          clearTimeout(disconnectTimeoutRef.current!)
          disconnectTimeoutRef.current = undefined
        }

        connectUserToChat({
          useTimeout: true,
        })
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      authenticated,
      visible,
      buyer,
      vendor,
      isAdmin,
      connectUserToChat,
      disconnectFromChat,
    ])

    const handleOnChatStatusPageFocus = () => {
      if (authenticated) {
        const user = getCurrentUser()
        if (
          isAdminOrVendorAndIsOffline(
            user as IVendor | IAdmin | IVisitorChatInfo | null | undefined
          ) ||
          !isChatConnectionOpenRef.current
        ) {
          return
        }
      }

      const isIdle = streamChatClient.user?.idle

      if (isIdle) {
        internalChangeChatStatus({
          status: ChatStatus.ONLINE,
        })
      }
    }

    const handleOnChatStatusPageLeave = () => {
      if (authenticated) {
        const user = getCurrentUser()
        if (
          isAdminOrVendorAndIsOffline(
            user as IVendor | IAdmin | undefined | null
          ) ||
          !isChatConnectionOpenRef.current
        ) {
          return
        }
      }

      if (!getHasTabFocused() && !DeviceUtils.isMobile()) {
        internalChangeChatStatus({
          status: ChatStatus.IDLE,
        })
      }
    }

    const handleOnChangeChatStatusBasedOnPageVisibility = () => {
      if (!visible) {
        setHasTlgTabFocused(false)

        if (authenticated) {
          clearPreviousOnlineOrIdleTimeouts()

          sendIdleEventsTimeoutRef.current = setTimeout(() => {
            handleOnChatStatusPageLeave()
          }, TIMEOUT_TO_SEND_IDLE_CHAT_STATUS_EVENTS)
        }
      } else {
        clearPreviousOnlineOrIdleTimeouts()

        setTimeout(() => {
          setHasTlgTabFocused(true)
        }, TIMEOUT_TO_SET_LOCAL_STORAGE_TLG_TAB_FOCUSED)

        sendOnlineEventsTimeoutRef.current = setTimeout(
          handleOnChatStatusPageFocus,
          TIMEOUT_TO_SEND_ONLINE_CHAT_STATUS_EVENTS
        )
      }
    }

    const clearPreviousOnlineOrIdleTimeouts = () => {
      if (sendOnlineEventsTimeoutRef.current) {
        clearTimeout(sendOnlineEventsTimeoutRef.current!)
        sendOnlineEventsTimeoutRef.current = undefined
      }

      if (sendIdleEventsTimeoutRef.current) {
        clearTimeout(sendIdleEventsTimeoutRef.current!)
        sendIdleEventsTimeoutRef.current = undefined
      }
    }

    useEffect(() => {
      const handleBeforeUnload = async () => {
        if (authenticated) {
          await internalChangeChatStatus({
            status: ChatStatus.OFFLINE,
          })
        }
      }

      window.addEventListener('pagehide', handleBeforeUnload)
      window.addEventListener('beforeunload', handleBeforeUnload)

      return () => {
        window.removeEventListener('pagehide', handleBeforeUnload)
        window.removeEventListener('beforeunload', handleBeforeUnload)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    updateEffectHook.useUpdateEffect(() => {
      handleOnPageVisibilityChange()
    }, [visible])

    useEffect(() => {
      handleOnChangeChatStatusBasedOnPageVisibility()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, vendor, admin])

    return (
      <>
        <ConnectionComponent onUserDataUpdate={handleOnUpdateUserData} />
        <WrappedComponent {...props} />
      </>
    )
  }

export { withStreamChatManagedConnector, type IConnectionComponent }
