import type { SetOptional } from 'type-fest'

import { IQueryFilters } from './types'

const API_URL = `${process.env.REACT_APP_API}/api`

const removeSortModel = ({ query }: { query: { [key: string]: string } }) => {
  const { order, sort } = query
  if (order) {
    delete query.order
  }
  if (sort) {
    delete query.sort
  }

  return query
}

export const buildQueryString = (
  queryFilters?: SetOptional<IQueryFilters, 'page' | 'limit'>
) => {
  if (!queryFilters) {
    return undefined
  }

  let query: { [key: string]: string } = {}

  const { filters, page, limit, negativeOffset, sortModel } = queryFilters

  if (!!filters) {
    const filtersQuery = filters?.reduce((acc, filter) => {
      const field = filter.field
      let value = filter.value

      if (value instanceof Date) {
        value = value.toISOString()
      }

      return {
        ...acc,
        [field]: value,
      }
    }, {})

    query = { ...query, ...filtersQuery }
  }

  if (!!page) {
    query = { ...query, page: String(page) }
  }

  if (!!limit) {
    query = { ...query, limit: String(limit) }
  }

  if (!!negativeOffset) {
    query = { ...query, negativeOffset: String(negativeOffset) }
  }

  if (!!sortModel) {
    if (sortModel.sort) {
      query = { ...query, order: sortModel.field, sort: sortModel.sort }
    } else {
      query = removeSortModel({ query })
    }
  }

  return query
}

export default {
  API_URL,
  buildQueryString,
}
