import { SxProps, Theme } from '@mui/material'

export const containerSx: SxProps<Theme> = {
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',

  alignItems: { xs: 'end', lg: 'start' },
  '@media (min-width: 700px) and (max-width: 1439px)': {
    backgroundImage: 'url(/images/http-error/404/tablet.webp)',
  },
  backgroundImage: {
    xs: 'url(/images/http-error/404/mobile.webp)',
    md: 'url(/images/http-error/404/tablet.webp)',
    lg: 'url(/images/http-error/404/big-screen.webp) !important',
  },
  backgroundPosition: {
    xs: 'top 20% center',
    lg: 'bottom 25% center',
    xxl: 'bottom 35% center',
  },
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  width: '100%',
  paddingTop: {
    xs: 0,
    lg: '7.4rem',
  },
  minHeight: 'calc(var(--vh, 1vh) * 100)',
}

export const innerContainerSx: SxProps<Theme> = {
  position: {
    xs: 'absolute',
    lg: 'relative',
  },
  gap: { xs: '1.6rem', md: '2.4rem' },
  alignItems: 'center',
  textAlign: 'center',
  padding: {
    xs: '2.4rem',
    md: '3.2rem',
  },
  width: {
    xs: 'calc(100% - 3.2rem)',
    md: '58.6rem',
    lg: '86rem',
    xl: '90rem',
  },
  height: {
    xs: 'auto',
    lg: '100%',
  },
  borderRadius: '0.8rem',
  background: 'rgba(22, 18, 63, 0.90)',
  left: {
    xs: '1.6rem',
    md: 'auto',
  },
  right: {
    xs: '1.6rem',
    md: 'auto',
  },
  bottom: {
    xs: '2rem',
    md: '8rem',
    lg: 'auto',
  },
}

export const textSx: SxProps<Theme> = {
  color: (theme) => theme.palette.primary.contrastText,
}
