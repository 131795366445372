import { SxProps, Theme } from '@mui/material'

export const containerSx: SxProps<Theme> = {
  alignItems: 'center',
  padding: '1.6rem 0.8rem',
  borderTop: (theme) => `0.1rem solid ${theme.palette.divider}`,
  gap: '1.6rem',
}

export const addAttachmentButtonSx: SxProps<Theme> = {
  padding: 0,
  width: '2.4rem',
  height: '2.4rem',
}

export const chatSendMessageButtonSx: SxProps<Theme> = {
  padding: 0,
  width: '4.4rem',
  height: '4.4rem',
}

export const chatSendMessageButtonIconSx: SxProps<Theme> = {
  minWidth: '4.4rem',
  minHeight: '4.4rem',
}

export const chatMessageTextFieldWrapperSx: SxProps<Theme> = {
  flex: 1,
}

export const chatMessageTextFieldSx: SxProps<Theme> = {
  borderRadius: '2rem',
  height: '4.4rem',
}

export const attachFileIconSx: SxProps<Theme> = {
  color: '#7A7A7A',
  transform: 'rotate(20deg)',
}

export const numberOfAttachmentToBeSentTextSx: SxProps<Theme> = {
  width: '1.2rem',
  height: '1.2rem',
  fontWeight: '700',
  fontSize: '1rem',
  borderRadius: '50%',
  color: '#FFF',
  backgroundColor: 'red',
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 999,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}
