import { SxProps, Theme } from '@mui/material'

export const chatbotDrawerContainerSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  gap: '1.2rem',
  paddingBottom: '7.4rem',
  maxWidth: {
    xs: '32.6rem',
    md: '34.6rem',
  },
}

export const chatbotDrawerImageSx: SxProps<Theme> = {
  width: '13.6rem',
  height: '13.6rem',
}

export const chatbotDrawerTitleSx: SxProps<Theme> = {
  marginTop: '0.4rem',
}

export const linkSx: SxProps<Theme> = {
  textDecoration: 'none',
}

export const chatbotDrawerSubTitleSx: SxProps<Theme> = {
  color: (theme) => theme.palette.text.secondary,
}
