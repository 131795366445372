const isIOS = () => {
  const platform = navigator?.userAgent || navigator?.platform || 'unknown'
  return /iPhone|iPod|iPad/.test(platform)
}

const isTouchDevice = () =>
  'ontouchstart' in window || navigator.maxTouchPoints > 0

const isMobile = () =>
  localStorage.mobile || window.navigator.maxTouchPoints > 1

const exports = {
  isMobile,
  isTouchDevice,
  isIOS,
}

export default exports
