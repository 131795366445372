import { Suspense, lazy, useCallback } from 'react'

import authHook from '@hooks/useAuth'

import { withStreamChatManagedConnector } from '@HOCs/withStreamChatManagedConnector'

import { StreamChatAnonymousConnection } from '@components/StreamChatAnonymousConnection'
import { StreamChatPrivateConnection } from '@components/StreamChatPrivateConnection'

const BuyerOrVisitorSolutionView = lazy(() =>
  import('@pages/SolutionView').then((module) => {
    return {
      default: module.SolutionView,
    }
  })
)

const BuyerSolutionViewWithChatConnection = withStreamChatManagedConnector(
  BuyerOrVisitorSolutionView,
  StreamChatPrivateConnection,
  { authenticated: true }
)

const VisitorSolutionViewWithChatConnection = withStreamChatManagedConnector(
  BuyerOrVisitorSolutionView,
  StreamChatAnonymousConnection,
  { authenticated: false }
)

const SolutionViewMiddleware = () => {
  const { isBuyer, isFetchingUserData } = authHook.useAuth()

  const ComponentToRender = useCallback(() => {
    if (isBuyer()) {
      return <BuyerSolutionViewWithChatConnection />
    }

    return <VisitorSolutionViewWithChatConnection />

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingUserData])

  if (isFetchingUserData) {
    return <></>
  }

  return <Suspense fallback={<></>}>{ComponentToRender()}</Suspense>
}

export { SolutionViewMiddleware }
