import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Avatar,
  Badge,
  BadgeProps,
  Box,
  Card,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'

import CancelIcon from '@mui/icons-material/Cancel'

import responsiveHook from '@hooks/useResponsive'
import windowSizeHook from '@hooks/useWindowSize'

import {
  avatarSx,
  badgeSx,
  badgeValueSx,
  bubbleSx,
  bubbleWrapperSx,
  cancelBackgroundSx,
  closeButtonSx,
  containerSx,
} from './styles'

const defaultBadgeProps: BadgeProps = {
  overlap: 'circular',
  anchorOrigin: { vertical: 'top', horizontal: 'right' },
  color: 'error',
}

enum BubbleDisplayState {
  HIDDEN,
  VISIBLE,
  CLOSED,
}

export interface IAssistanceChatBubble {
  text: string
  waitTime?: number
  hideBadgeOnZeroValue?: boolean
  badgeValue?: number
  badgeProps?: BadgeProps
  bubbleSxProps?: SxProps<Theme>
  onInteract?: () => void
}

const AssistanceChatBubble = ({
  text,
  waitTime = 3,
  hideBadgeOnZeroValue = true,
  badgeValue = 1,
  badgeProps = { ...defaultBadgeProps },
  bubbleSxProps,
  onInteract,
}: IAssistanceChatBubble) => {
  const { width } = windowSizeHook.useWindowSize({ startDelay: 10 })
  const { isMobile } = responsiveHook.useResponsive()

  const containerRef = useRef<HTMLDivElement>(null)

  const [chatBubbleDisplayState, setChatBubbleDisplayState] = useState(
    BubbleDisplayState.HIDDEN
  )
  const { t } = useTranslation()

  const handleOnClose = () => {
    setChatBubbleDisplayState(BubbleDisplayState.CLOSED)
  }

  const openAtWaitTime = useCallback(() => {
    setTimeout(() => {
      setChatBubbleDisplayState(BubbleDisplayState.VISIBLE)
    }, waitTime * 1000)
  }, [waitTime])

  useEffect(() => {
    openAtWaitTime()
  }, [openAtWaitTime])

  const hasVScroll = useMemo(() => width! > document.body.clientWidth, [width])
  const leftPosition = useMemo(
    () =>
      width && containerRef.current?.clientWidth
        ? width - containerRef.current?.clientWidth - (hasVScroll ? 15 : 0)
        : undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [width, chatBubbleDisplayState]
  )

  return (
    <Stack
      ref={containerRef}
      sx={containerSx({
        isVisible: chatBubbleDisplayState !== BubbleDisplayState.HIDDEN,
        leftPosition,
      })}
    >
      <Stack
        sx={bubbleWrapperSx({
          isVisible: chatBubbleDisplayState !== BubbleDisplayState.CLOSED,
        })}
      >
        <IconButton sx={closeButtonSx} onClick={handleOnClose}>
          <CancelIcon />
          <Box sx={cancelBackgroundSx} />
        </IconButton>
        <Card
          data-testid="help-card-button"
          role="button"
          sx={{ ...bubbleSx, ...bubbleSxProps } as SxProps<Theme>}
          elevation={6}
          onClick={onInteract}
        >
          <Typography variant={isMobile ? 'body2' : 'body1'}>{text}</Typography>
        </Card>
      </Stack>
      <Badge
        role="button"
        data-testid="chat-bubble-avatar"
        sx={badgeSx({
          isVisible: !hideBadgeOnZeroValue || badgeValue > 0,
        })}
        badgeContent={
          <Typography
            data-testid="chat-badge-value"
            variant="badge"
            sx={badgeValueSx({
              isVisible: !hideBadgeOnZeroValue || badgeValue > 0,
            })}
          >
            {badgeValue}
          </Typography>
        }
        onClick={onInteract}
        {...badgeProps}
      >
        <Avatar
          alt={t('Assistance Chat Button')}
          sx={avatarSx}
          src="/images/chat-face.webp"
        />
      </Badge>
    </Stack>
  )
}

export { AssistanceChatBubble }
