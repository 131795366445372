import { useTranslation } from 'react-i18next'

import { Box } from '@mui/material'

import { betaTagSx } from './styles'

const BetaTag = () => {
  const { t } = useTranslation()

  return (
    <Box component="span" sx={betaTagSx}>
      {t('Beta')}
    </Box>
  )
}

export { BetaTag }
