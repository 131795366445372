import { keyframes } from '@mui/system'

export const bounce = keyframes`
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-6px);
  }
  40% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0);
  }
`

export const typingDotsSx = {
  display: 'flex',
  alignItems: 'center',
  overflow: 'visible',
}

export const dotSx = (delay: string) => {
  return {
    fill: 'rgba(0, 0, 0, 0.20)',
    animation: `${bounce} 1s infinite both ease-in-out`,
    animationDelay: delay,
  }
}
