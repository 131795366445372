import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'

import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

import { ChatbotConversation } from '@utils/types'

import {
  itemChatIconSx,
  itemContainerSx,
  itemDotsIconSx,
  itemTextSx,
  menuItemSx,
  overlaySx,
} from './styles'

interface IConversationItem {
  conversation: ChatbotConversation
  onSelectConversation: (data: { conversationId: string }) => void
  onSelectDeleteOption: ({
    conversation,
  }: {
    conversation: ChatbotConversation
  }) => void
}

const ConversationItem = ({
  conversation,
  onSelectConversation,
  onSelectDeleteOption,
}: IConversationItem) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const { t } = useTranslation()

  const handleOnClickMore = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
    setIsMenuOpen(true)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
    setIsMenuOpen(false)
  }

  const handleOnSelectedDeleteOption = () => {
    onSelectDeleteOption({ conversation })
    handleCloseMenu()
  }

  return (
    <>
      <ListItemButton
        data-testid="conversation-item"
        sx={itemContainerSx}
        className="conversationItem"
        selected={isMenuOpen}
        disableRipple
        onClick={() =>
          onSelectConversation({ conversationId: conversation.id })
        }
      >
        <ListItemIcon sx={itemChatIconSx}>
          <ChatBubbleOutlineIcon />
        </ListItemIcon>
        <ListItemText
          sx={itemTextSx}
          primary={
            <>
              <Typography data-testid="conversation-title" variant="subtitle1">
                {conversation.name}
              </Typography>
              <Box className="overlay" sx={overlaySx} />
            </>
          }
          disableTypography
        />

        <ListItemIcon
          data-testid="more-button"
          onClick={handleOnClickMore}
          sx={itemDotsIconSx({ isMenuOpen })}
          className="moreButton"
          tabIndex={1}
        >
          <IconButton>
            <MoreHorizIcon />
          </IconButton>
        </ListItemIcon>
      </ListItemButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          data-testid="delete-option"
          onClick={handleOnSelectedDeleteOption}
          disableRipple
          sx={menuItemSx}
        >
          <DeleteIcon />
          <Typography variant="subtitle1">{t('Delete chat')}</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}

export { ConversationItem }
