import { DependencyList, EffectCallback, useEffect } from 'react'

import isFirstRenderHook from '@hooks/useIsFirstRender'

function useUpdateEffect(effect: EffectCallback, deps?: DependencyList) {
  const isFirstRender = isFirstRenderHook.useIsFirstRender()

  useEffect(() => {
    if (!isFirstRender) {
      return effect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

export default {
  useUpdateEffect,
}
