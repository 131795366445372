export const SESSION_STORAGE_USER_TYPE_KEY = '@tlg:user_type_key'
export const SESSION_STORAGE_IMPERSONATION_KEY = '@tlg:impersonation_key'
export const LEGACY_BUYER_COMPLETION_PROFILE_CARD_REMOVED =
  '@tlg:legacy_buyer_completion_profile_card_removed'
export const LEGACY_BUYER_MATCHES_COUNT_CARD_REMOVED =
  '@tlg:legacy_buyer_matches_count_card_removed'
export const LEGACY_VENDOR_ARCHIVED_SOLUTIONS_COUNT_CARD_REMOVED =
  '@tlg:legacy_vendor_archived_solutions_count_card_removed'
export const LEGACY_VENDOR_INCOMPLETE_SOLUTIONS_COUNT_CARD_REMOVED =
  '@tlg:legacy_vendor_incomplete_solutions_count_card_removed'
export const SESSION_STORAGE_IMPERSONATED_BY_KEY =
  '@tlg:session_storage.impersonated_by_key'
