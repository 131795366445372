import { useTranslation } from 'react-i18next'

import { LoadingButton } from '@mui/lab'
import {
  Box,
  Card,
  CardContent,
  Chip,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'

import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import errorHandlerHook from '@hooks/useErrorHandler'

import ChatbotService from '@services/chatbot'

import {
  cardSx,
  charactersCountSx,
  feedbackFormCardContentSx,
  feedbackFormChipSx,
  feedbackFormChipsContainerSx,
  feedbackFormHeader,
  feedbackFormHeaderContainer,
  feedbackFormHeaderIconContainerSx,
  feedbackFormHeaderIconSx,
  feedbackFormSubmittedIconContainerSx,
  feedbackFormSubmittedIconSx,
  feedbackFormSubmittedSx,
  feedbackFormSubmittedTextSx,
  feedbackFormTextFieldSx,
  textFieldBottomSx,
  textFieldWrapperSx,
} from './styles'

const SOMETHING_ELSE_FEEDBACK_OPTION = 'Something else...' as const

const feedbackOptions: readonly string[] = [
  'Inaccurate response',
  'Lazy answer',
  'Out of date',
  "Didn't fully follow instructions",
  SOMETHING_ELSE_FEEDBACK_OPTION,
] as const

type FormData = {
  feedbacks: string[]
  customFeedback?: string
}

interface IFeedbackFormProps {
  messageId: string
  conversationId: string
  message: string
  onSuccess: () => void
  onClose: () => void
  onScroll: () => void
}

const FeedbackForm = ({
  messageId,
  conversationId,
  onSuccess,
  message,
  onClose,
  onScroll,
}: IFeedbackFormProps) => {
  const { t } = useTranslation()
  const { handleError } = errorHandlerHook.useErrorHandler()

  const SCHEMA = Yup.object().shape({
    feedbacks: Yup.array().of(Yup.string()).min(1, t('Required')),
    customFeedback: Yup.string(),
  })

  const formik = useFormik<FormData>({
    enableReinitialize: true,
    validationSchema: SCHEMA,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    initialValues: {
      feedbacks: [],
      customFeedback: '',
    },
    onSubmit: (values, { setStatus }) => handleOnSubmit({ values, setStatus }),
  })

  const handleOnSubmit = async ({
    values,
    setStatus,
  }: {
    values: any
    setStatus: any
  }) => {
    try {
      const filteredFeedbacks = values.customFeedback
        ? values.feedbacks.filter(
            (v: string) => v !== SOMETHING_ELSE_FEEDBACK_OPTION
          )
        : values.feedbacks

      await ChatbotService.sendFeedback({
        feedbacks: filteredFeedbacks,
        customFeedback: values.customFeedback,
        messageId,
        message,
        conversationId,
      })
      setStatus({ submitted: true })
      onSuccess()
    } catch (error) {
      handleError({ exception: error })
    }
  }

  const handleToggle = (value: string) => {
    const currentIndex = formik.values.feedbacks.indexOf(value)
    const newSelectedOptions = [...formik.values.feedbacks]

    if (currentIndex === -1) {
      newSelectedOptions.push(value)
    } else {
      newSelectedOptions.splice(currentIndex, 1)
    }

    formik.setFieldValue('feedbacks', newSelectedOptions)

    if (value === SOMETHING_ELSE_FEEDBACK_OPTION) {
      setTimeout(() => {
        onScroll()
      }, 50)
    }
  }

  if (formik.status?.submitted) {
    return (
      <Box sx={feedbackFormSubmittedSx} data-testid="feedback-form-submitted">
        <Box sx={feedbackFormSubmittedIconContainerSx}>
          <CheckIcon
            fontSize="large"
            color="success"
            sx={feedbackFormSubmittedIconSx}
          />
        </Box>
        <Box sx={feedbackFormSubmittedTextSx}>
          <Typography variant="subtitle1">
            {t('Thanks for your feedback!')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {t('It will be used to improve your experience.')}
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      data-testid="feedback-form"
    >
      <Card sx={cardSx}>
        <CardContent sx={feedbackFormCardContentSx}>
          <Box sx={feedbackFormHeader}>
            <Box sx={feedbackFormHeaderContainer}>
              <Box sx={feedbackFormHeaderIconContainerSx}>
                <ThumbDownOffAltOutlinedIcon sx={feedbackFormHeaderIconSx} />
              </Box>
              <Typography variant="subtitle1">Help Us Improve</Typography>
            </Box>
            <IconButton
              color="default"
              size="medium"
              aria-label={t('Close feedback form')}
              onClick={onClose}
              data-testid="feedback-form-close-button"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
            <Typography variant="subtitle2">
              {t('Please select all that apply:')}
            </Typography>
            <Box sx={feedbackFormChipsContainerSx}>
              {feedbackOptions.map((option) => (
                <Chip
                  key={option}
                  label={option}
                  size="medium"
                  variant="outlined"
                  data-testid="feedback-option"
                  color={
                    formik.values.feedbacks.includes(option)
                      ? 'error'
                      : 'default'
                  }
                  onClick={() => handleToggle(option)}
                  sx={feedbackFormChipSx}
                />
              ))}
            </Box>
          </Box>

          {formik.values.feedbacks.includes(SOMETHING_ELSE_FEEDBACK_OPTION) && (
            <Box data-testid="feedback-form-additional-comments">
              <Typography variant="subtitle2">
                {t('Specify any details')}{' '}
                <Typography
                  variant="body2"
                  color="text.secondary"
                  component="span"
                >
                  {t('(Optional)')}
                </Typography>
              </Typography>
              <Box sx={textFieldWrapperSx}>
                <TextField
                  multiline
                  placeholder={t('Enter your feedback')}
                  rows={4}
                  variant="outlined"
                  fullWidth
                  value={formik.values.customFeedback}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="customFeedback"
                  inputProps={{
                    maxLength: 240,
                    'data-testid': 'additional-comments-input',
                  }}
                  sx={feedbackFormTextFieldSx}
                />
                <Box sx={textFieldBottomSx}>
                  <Typography
                    variant="caption"
                    component="span"
                    sx={charactersCountSx}
                  >
                    {formik.values.customFeedback?.length} / 240
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          <LoadingButton
            variant="outlined"
            size="medium"
            fullWidth
            type="submit"
            loading={formik.isSubmitting || formik.isValidating}
            disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
            color="primary"
            data-testid="send-feedback-button"
          >
            {t('SEND FEEDBACK')}
          </LoadingButton>
        </CardContent>
      </Card>
    </Box>
  )
}

export { FeedbackForm }
