import { useCallback, useEffect, useState } from 'react'

import { useLocalStorage } from '@hooks/useLocalStorage'

import {
  LOCAL_STORAGE_CHAT_VISITOR_INFO_KEY,
  LOCAL_STORAGE_EVENT_LISTENER_KEY,
} from '@utils/local-storage'
import {
  ChatUserType,
  IVisitorChatInfo,
  IVisitorChatUserData,
} from '@utils/types'
import { NEW_VISITOR_CHAT_DATA_WINDOW_EVENT } from '@utils/window-events'

interface IStreamChatAnonymousConnectionProps {
  onUserDataUpdate?: ({
    userDataToUpdate,
  }: {
    userDataToUpdate: IVisitorChatUserData | undefined
  }) => void
}

const StreamChatAnonymousConnection = ({
  onUserDataUpdate,
}: IStreamChatAnonymousConnectionProps) => {
  const [userData, setUserData] = useState<IVisitorChatUserData>()
  const { getValue: getLocalStorageChatVisitorInfo } = useLocalStorage<
    IVisitorChatInfo | undefined
  >(LOCAL_STORAGE_CHAT_VISITOR_INFO_KEY, undefined)

  const getValidVisitorInfo = useCallback(() => {
    const visitorInfo = getLocalStorageChatVisitorInfo()

    if (!visitorInfo?.token || !visitorInfo?.visitorName) {
      return undefined
    }

    return visitorInfo
  }, [getLocalStorageChatVisitorInfo])

  const handleOnUserDataUpdate = useCallback(() => {
    const visitorInfo = getValidVisitorInfo()

    if (!visitorInfo || userData) {
      return
    }

    setUserData({
      id: visitorInfo.id!,
      name: visitorInfo.visitorName,
      companyName: visitorInfo.companyName,
      anon: true,
      userType: ChatUserType.VISITOR,
      idle: false,
      token: visitorInfo.token!,
    })
  }, [userData, getValidVisitorInfo])

  const handleOnVisitorStorageEvent = useCallback((visitorInfo: any) => {
    if (visitorInfo) {
      setUserData({
        id: visitorInfo.id!,
        name: visitorInfo.visitorName,
        companyName: visitorInfo.companyName,
        anon: true,
        userType: ChatUserType.VISITOR,
        idle: false,
        token: visitorInfo.token!,
      })
    }
  }, [])

  useEffect(() => {
    handleOnUserDataUpdate()

    const handleOnNewVisitorChatDatWindowEvent = (event: CustomEvent) => {
      const { value: visitorInfo } = event.detail

      handleOnVisitorStorageEvent(visitorInfo)
    }

    const handleOnNewLocalStorageChatVisitorInfoEvent = (
      event: StorageEvent
    ) => {
      if (event.key === LOCAL_STORAGE_CHAT_VISITOR_INFO_KEY) {
        handleOnUserDataUpdate()
      }
    }

    window.addEventListener(
      NEW_VISITOR_CHAT_DATA_WINDOW_EVENT,
      handleOnNewVisitorChatDatWindowEvent
    )

    window.addEventListener(
      LOCAL_STORAGE_EVENT_LISTENER_KEY,
      handleOnNewLocalStorageChatVisitorInfoEvent
    )

    return () => {
      window.removeEventListener(
        NEW_VISITOR_CHAT_DATA_WINDOW_EVENT,
        handleOnNewVisitorChatDatWindowEvent
      )
      window.removeEventListener(
        LOCAL_STORAGE_EVENT_LISTENER_KEY,
        handleOnNewLocalStorageChatVisitorInfoEvent
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (userData) {
      onUserDataUpdate?.({ userDataToUpdate: userData })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  return null
}

export { StreamChatAnonymousConnection }
