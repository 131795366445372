import * as EF from '@everflow/everflow-sdk'

EF.configure({
  tracking_domain: process.env.REACT_APP_EVERFLOW_TRACKING_DOMAIN!,
})

interface IConversion {
  offer_id: number
  amount: number
  transaction_id?: string
  event_id?: number
  coupon_code?: string
  adv_event_id?: number
  order_id?: string
  user_id?: string
  verification_token?: string
  email?: string
  parameters?: Record<string, string>
  adv1?: string
  adv2?: string
  adv3?: string
  adv4?: string
  adv5?: string
}

interface IClick {
  offer_id: number
  affiliate_id: number
  transaction_id?: string
  uid?: number
  creative_id?: number
  sub1?: string
  sub2?: string
  sub3?: string
  sub4?: string
  sub5?: string
  source_id?: string
  coupon_code?: string
  fbclid?: string
  gclid?: string
  ttclid?: string
  parameters?: Record<string, string>
}

const conversion = (data: IConversion) => EF.conversion(data)

const click = (data: IClick) => EF.click(data)

const urlParameter = (key: string) => EF.urlParameter(key)

export default { conversion, click, urlParameter }
