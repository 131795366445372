import i18n from '@libs/i18n'

import {
  AdminRole,
  UserBatchAction,
  UserStatus,
  UserStatusOption,
} from './types'

const t = i18n.t.bind(i18n)

const userStatusResult: any = {
  [UserStatus.DELETED]: {
    [UserStatusOption.NONE]: 'Deleted',
    [UserStatusOption.DELETED_BY_ADMIN]: 'Deleted by Admin',
    [UserStatusOption.DELETED_BY_USER]: 'Deleted by User',
  },
} as const

export const USER_BATCH_ACTIONS_MAPPING = {
  [UserStatus.ACTIVE]: [UserBatchAction.DEACTIVATE, UserBatchAction.DELETE],
  [UserStatus.INACTIVE]: [UserBatchAction.ACTIVATE, UserBatchAction.DELETE],
  [UserStatus.DELETED]: [UserBatchAction.ACTIVATE, UserBatchAction.RECOVER],
  [UserStatus.INVITED]: [UserBatchAction.DELETE],
}

export const isInactiveLegacyUser = ({
  user,
}: {
  user: { status: UserStatus; legacy?: boolean }
}) => user.status === UserStatus.INVITED && user.legacy

export const getUserStatus = (status?: string, statusOption?: string) => {
  if (status === UserStatus.ACTIVE) {
    return t('Active')
  }
  if (status === UserStatus.INACTIVE) {
    return t('Deactivated')
  }
  if (status === UserStatus.INVITED) {
    return t('Invited')
  }
  if (status === UserStatus.PENDING) {
    return t('Pending')
  }
  if (status === UserStatus.SUSPENDED) {
    return t('Suspended')
  }
  if (status === UserStatus.DELETED) {
    return t(
      (statusOption && userStatusResult[UserStatus.DELETED][statusOption]) ||
        userStatusResult[UserStatus.DELETED][UserStatusOption.NONE]
    )
  }

  return ''
}

export const getUserRole = (role?: string) => {
  if (role === AdminRole.SUPER_ADMIN) {
    return t('Super Admin')
  }
  if (role === AdminRole.ADMIN) {
    return t('Admin')
  }
  if (role === AdminRole.SUPPORT) {
    return t('Support')
  }
  if (role === AdminRole.FINANCE) {
    return t('Finance')
  }

  return ''
}

export const getUserPosition = ({
  officialTitle,
  companyName,
}: {
  officialTitle?: string
  companyName?: string
}) => {
  if (officialTitle && companyName) {
    return `${officialTitle} • ${companyName}`
  } else if (officialTitle) {
    return officialTitle
  } else if (companyName) {
    return companyName
  }

  return undefined
}

export const getUserFullName = ({
  firstName,
  lastName,
  email,
}: {
  firstName?: string
  lastName?: string
  email?: string
}) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`
  } else if (firstName) {
    return firstName
  } else if (lastName) {
    return lastName
  } else if (email) {
    return email
  }

  return ''
}

export const getUserCompanyName = ({
  companyName,
  firstName,
  lastName,
  email,
}: {
  companyName?: string
  firstName?: string
  lastName?: string
  email?: string
}) => {
  if (companyName) {
    return companyName
  }

  return getUserFullName({ firstName, lastName, email })
}
