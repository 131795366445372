import { useCallback, useEffect, useState } from 'react'

import { streamChatClient } from '@libs/stream-chat'

import authHook from '@hooks/useAuth'
import chatHook from '@hooks/useChat'

import {
  ChatUserType,
  IAdmin,
  IBuyer,
  IBuyerVendorChatUserData,
  IVendor,
} from '@utils/types'
import { getUserFullName } from '@utils/users'

interface IStreamChatPrivateConnectionProps {
  onUserDataUpdate?: ({
    userDataToUpdate,
  }: {
    userDataToUpdate: IBuyerVendorChatUserData | undefined
  }) => void
}

const StreamChatPrivateConnection = ({
  onUserDataUpdate,
}: IStreamChatPrivateConnectionProps) => {
  const [userData, setUserData] = useState<IBuyerVendorChatUserData>()
  const {
    activeUserType,
    getCurrentUser,
    isVendor,
    isAdmin,
    isLoadingImpersonation,
  } = authHook.useAuth()
  const { isConnectionOpen } = chatHook.useChat()

  const isAdminOrVendorAndIsOffline = useCallback(
    (user: IVendor | IAdmin | undefined | null) =>
      (isAdmin() || isVendor()) && !user?.chatStatus,
    [isAdmin, isVendor]
  )

  useEffect(() => {
    const prepareConnectionData = () => {
      const user = getCurrentUser()
      const isUserLoadedInStreamChat =
        streamChatClient?.user && !streamChatClient?.user?.anon

      if (!user || (isConnectionOpen && isUserLoadedInStreamChat)) {
        return
      }

      const invisibleChatStatus = isAdminOrVendorAndIsOffline(
        user as IVendor | IAdmin | undefined | null
      )

      const companyData = isAdmin()
        ? undefined
        : { companyName: (user as IBuyer | IVendor).company?.name }

      setUserData({
        id: user.userId,
        name: getUserFullName({
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
        })!,
        type: activeUserType! as unknown as ChatUserType,
        email: user.email,
        officialTitle: user.officialTitle,
        invisible: invisibleChatStatus,
        token: user.chatToken!,
        ...companyData,
      })
    }

    prepareConnectionData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUserType, getCurrentUser, isAdmin, isAdminOrVendorAndIsOffline])

  useEffect(() => {
    if (userData && !isLoadingImpersonation) {
      onUserDataUpdate?.({ userDataToUpdate: userData })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  return null
}

export { StreamChatPrivateConnection }
