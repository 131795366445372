import React from 'react'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

const stripePromise = (async () => {
  try {
    return await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!)
  } catch (err) {
    window.location.reload()
    return null
  }
})()

const options = {
  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&display=swap',
    },
  ],
}

const StripeElements: React.FC = ({ children }) => (
  <Elements stripe={stripePromise} options={options}>
    {children}
  </Elements>
)

export { StripeElements }
