import { useEffect } from 'react'
import { NavigateOptions, To } from 'react-router-dom'

import navigationHook from '@hooks/useNavigation'

interface INavigateTo {
  path: To
  replace?: NavigateOptions['replace']
  state?: NavigateOptions['state']
}

const NavigateTo = ({ path, replace, state }: INavigateTo) => {
  const { navigateTo } = navigationHook.useNavigation()

  useEffect(() => {
    navigateTo({ path, replace, state })
  })

  return null
}

export { NavigateTo }
