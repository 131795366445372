import { Outlet } from 'react-router-dom'

import { FooterCopyright } from '@components/FooterCopyright'

const WithFooterRoute = () => (
  <>
    <Outlet />
    <FooterCopyright />
  </>
)

export { WithFooterRoute }
