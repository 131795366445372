import api from '@libs/api'

import { handleServiceError } from '@utils/errors'
import FileUtils from '@utils/files'
import { getTimeZone } from '@utils/timezones'
import type { AdminRole, IAdmin, IApiData, IQueryFilters } from '@utils/types'
import { buildQueryString } from '@utils/url'

interface IGetByUserIdResponse
  extends Omit<IAdmin, 'createdDate' | 'updatedDate'> {
  createdDate: string
  updatedDate: string
}

export interface ICreateOrUpdateProfile {
  firstName: string
  lastName: string
  officialTitle?: string
  avatar?: File | null
}

const mapFindResponse = (data: IGetByUserIdResponse): IAdmin => {
  return {
    ...data,
    createdDate: new Date(data.createdDate),
    updatedDate: new Date(data.updatedDate),
  }
}

const findAll = async (
  queryFilters: Partial<IQueryFilters>
): Promise<IApiData<IAdmin[]>> => {
  const queryString = buildQueryString(queryFilters)

  try {
    const response = (await api.get({
      path: '/admins',
      queryString,
    })) as IApiData<IAdmin[]>

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const fetchCsvData = async (queryFilters: IQueryFilters) => {
  const queryString = {
    ...buildQueryString(queryFilters),
    timeZone: getTimeZone(),
  }

  try {
    const response = await api.csv({
      path: '/admins/csv',
      queryString,
    })

    FileUtils.download({
      content: response,
      fileName: 'admins.csv',
      contentType: 'text/csv',
    })
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const getByUserId = async (): Promise<IAdmin> => {
  try {
    const response = await api.get({
      path: '/admins/profile',
    })

    return mapFindResponse(response)
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const getById = async (id: string): Promise<IAdmin> => {
  try {
    const response = await api.get({
      path: `/admins/${id}`,
    })

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const createProfile = async (data?: ICreateOrUpdateProfile) => {
  try {
    const formData = data ? api.buildFormData(data) : new FormData()

    return await api.post({
      headers: { 'Content-Type': 'multipart/form-data' },
      path: '/admins/profile',
      data: formData,
    })
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const updateProfile = async (data: ICreateOrUpdateProfile) => {
  try {
    const formData = api.buildFormData(data)

    return await api.put({
      headers: { 'Content-Type': 'multipart/form-data' },
      path: '/admins/profile',
      data: formData,
    })
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const update = async (data: {
  id: string
  firstName?: string
  lastName?: string
  email?: string
  avatar?: string
  officialTitle?: string
  role?: string
  status?: string
}) => {
  try {
    return await api.put({
      path: `/admins/${data.id}`,
      data,
    })
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const create = async (
  firstName: string,
  lastName: string,
  email: string,
  role: AdminRole
) => {
  try {
    return await api.post({
      path: '/admins',
      data: {
        firstName,
        email,
        lastName,
        role,
      },
    })
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const remove = async (id: string) => {
  try {
    return await api.remove({
      path: `/admins/${id}`,
    })
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const batchUpdate = async (data: { adminIds: string[]; status: string }) => {
  try {
    return await api.put({
      path: '/admins/batch',
      data,
    })
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const batchDelete = async ({ adminIds }: { adminIds: string[] }) => {
  try {
    return await api.remove({
      path: '/admins/batch',
      queryString: {
        adminIds,
      },
    })
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

export const changeChatStatus = async ({
  chatStatus,
  chatStatusOfflineAutomaticResponse,
}: Pick<IAdmin, 'chatStatus' | 'chatStatusOfflineAutomaticResponse'>) => {
  try {
    const processedChatStatusOfflineAutomaticResponse =
      chatStatusOfflineAutomaticResponse || null

    await api.put({
      path: '/admins/chat-status',
      data: {
        chatStatus,
        chatStatusOfflineAutomaticResponse:
          processedChatStatusOfflineAutomaticResponse,
      },
    })
  } catch (err) {
    throw handleServiceError(err)
  }
}

export default {
  findAll,
  getByUserId,
  getById,
  createProfile,
  updateProfile,
  batchUpdate,
  batchDelete,
  update,
  create,
  remove,
  changeChatStatus,
  fetchCsvData,
}
