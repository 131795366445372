import { StreamChat } from 'stream-chat'

const streamChatClient = StreamChat.getInstance(
  process.env.REACT_APP_STREAM_CHAT_KEY!,
  {
    timeout: 10_000,
  }
)

export { streamChatClient }
