import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Drawer,
  IconButton,
  Link,
  TextField,
  Typography,
} from '@mui/material'

import CancelIcon from '@mui/icons-material/Cancel'
import CloseIcon from '@mui/icons-material/Close'
import HistoryIcon from '@mui/icons-material/History'
import SendIcon from '@mui/icons-material/Send'

import authHook from '@hooks/useAuth'
import errorHandlerHook, { IErrorCallback } from '@hooks/useErrorHandler'
import featuresHook from '@hooks/useFeatures'
import responsiveHook from '@hooks/useResponsive'

import ChatbotService from '@services/chatbot'

import { errorTypes } from '@utils/errors'
import { ChatbotConversation, ChatbotMessage, ChatbotUser } from '@utils/types'

import {
  backButtonSx,
  betaTagSx,
  buttonWrapperSx,
  cancelResponseButtonSx,
  charactersCountSx,
  chatHistoryTitleSx,
  drawerCloseButtonSx,
  drawerHeaderSx,
  footerTextSx,
  footerWrapperSx,
  middleContentWrapperSx,
  quickAccessDrawerPaperSx,
  quickAccessDrawerSx,
  textFieldBottomSx,
  textFieldSx,
  textFieldWrapperSx,
} from './styles'

import { ConversationList } from './ConversationList'
import { ConversationView } from './ConversationView'
import { ChatbotDrawerDisabled } from './Disabled'
import { EmptyHistory } from './EmptyHistory'
import { ChatbotWelcome } from './Welcome'

enum ViewState {
  EMPTY = 'empty',
  CONVERSATIONS = 'conversations',
  CHAT = 'chat',
  DISABLED = 'disabled',
  WELCOME = 'welcome',
}

interface IChatbotDrawerProps {
  isOpen: boolean
  onClose: () => void
  isFirstInteraction: boolean
  onCloseFirstInteraction: () => void
}

const PROMPT_MAX_LENGTH = 2_000

interface PromptInputProps {
  onSend: ({ prompt }: { prompt: string }) => void
  isLoading: boolean
  onAbort: () => void
  shouldFocus: boolean
}

const PromptInput = memo(
  ({ onSend, isLoading, onAbort, shouldFocus }: PromptInputProps) => {
    const [prompt, setPrompt] = useState<string>('')
    const { isDesktop } = responsiveHook.useResponsive()
    const textFieldRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
      if (isDesktop || shouldFocus) {
        if (textFieldRef.current) {
          textFieldRef.current.focus()
        }
      }
    }, [isDesktop, shouldFocus])

    const handleSendButtonClick = useCallback(() => {
      if (prompt && !isLoading) {
        onSend({ prompt })
        setPrompt('')
      }
    }, [onSend, prompt, isLoading])

    const handleKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (isLoading) {
          return
        }
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault()
          handleSendButtonClick()
        }
      },
      [handleSendButtonClick, isLoading]
    )

    return (
      <Box sx={footerWrapperSx}>
        {isLoading && (
          <Button
            variant="outlined"
            color="error"
            size="medium"
            sx={cancelResponseButtonSx}
            onClick={onAbort}
            data-testid="cancel-response-button"
          >
            Cancel Response
            <CancelIcon />
          </Button>
        )}
        <Box sx={textFieldWrapperSx}>
          <TextField
            placeholder="Ask me anything..."
            onKeyDown={handleKeyDown}
            multiline
            maxRows={4}
            value={prompt}
            onChange={(event) => setPrompt(event.target.value)}
            sx={textFieldSx}
            inputProps={{
              maxLength: PROMPT_MAX_LENGTH,
              'data-testid': 'prompt-input',
            }}
            inputRef={textFieldRef}
          />
          <Box sx={textFieldBottomSx}>
            <Typography
              variant="caption"
              component="span"
              sx={charactersCountSx}
            >
              {prompt.length} / {PROMPT_MAX_LENGTH}
            </Typography>
            <IconButton
              aria-label="Submit"
              onClick={handleSendButtonClick}
              data-testid="send-button"
              disabled={!prompt || isLoading}
            >
              <SendIcon color={!prompt || isLoading ? 'disabled' : 'primary'} />
            </IconButton>
          </Box>
        </Box>
        <Typography variant="caption" sx={footerTextSx}>
          <Box>
            <Link
              href="/privacy-policy"
              color="inherit"
              underline="none"
              target="_blank"
            >
              Privacy Policy
            </Link>
            {' ・ '}
            <Link
              href="/terms-and-conditions"
              color="inherit"
              underline="none"
              target="_blank"
            >
              Terms & Conditions
            </Link>
          </Box>
        </Typography>
      </Box>
    )
  }
)

const ChatbotDrawer = ({
  isOpen,
  onClose,
  isFirstInteraction,
  onCloseFirstInteraction,
}: IChatbotDrawerProps) => {
  const { t } = useTranslation()
  const { impersonatedUser } = authHook.useAuth()
  const { isDesktop } = responsiveHook.useResponsive()
  const { handleError } = errorHandlerHook.useErrorHandler()
  const { availableFeatures, isLoadingAvailableFeatures } =
    featuresHook.useFeatures()

  const [isLoading, setIsLoading] = useState(false)
  const [history, setHistory] = useState<ChatbotMessage[]>([])
  const [abortController, setAbortController] =
    useState<AbortController | null>(null)
  const [conversations, setConversations] = useState<ChatbotConversation[]>([])
  const [selectedConversationId, setSelectedConversationId] = useState<string>()
  const [viewState, setViewState] = useState<ViewState>(ViewState.EMPTY)
  const [isErrorTriggered, setIsErrorTriggered] = useState(false)

  const preventFetchingConversationFlag = useRef(false)
  const conversationBoxRef = useRef<HTMLDivElement>(null)

  const isImpersonatingUser = !!impersonatedUser
  const isBuyerChatbotFeatureEnabled = availableFeatures?.buyerChatbot ?? false

  useEffect(() => {
    if (!isFirstInteraction) {
      return
    }

    setViewState(ViewState.WELCOME)
  }, [isFirstInteraction])

  const fetchConversations = useCallback(async () => {
    try {
      const conversationList = await ChatbotService.getConversations()
      setConversations(conversationList)
    } catch (error) {
      handleError({ exception: error })
    }
  }, [handleError])

  useEffect(() => {
    if (isLoadingAvailableFeatures) {
      return
    }

    if (!isBuyerChatbotFeatureEnabled) {
      setViewState(ViewState.DISABLED)
    } else if (isOpen && conversations.length === 0) {
      fetchConversations()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchConversations, isOpen, isLoadingAvailableFeatures])

  const fetchConversation = useCallback(async () => {
    if (!selectedConversationId || preventFetchingConversationFlag.current) {
      preventFetchingConversationFlag.current = false
      return
    }

    setHistory([])

    try {
      const response = await ChatbotService.getConversation({
        conversationId: selectedConversationId,
      })
      setHistory(response.messages)
      setViewState(ViewState.CHAT)
    } catch (error) {
      handleError({ exception: error })
    }
  }, [selectedConversationId, handleError])

  useEffect(() => {
    fetchConversation()
  }, [selectedConversationId, handleError, fetchConversation])

  const handleScroll = () => {
    if (conversationBoxRef.current) {
      conversationBoxRef.current.scrollTop =
        conversationBoxRef.current.scrollHeight
    }
  }
  const handleScrollSmooth = () => {
    if (conversationBoxRef?.current && conversationBoxRef?.current?.scrollTo) {
      conversationBoxRef.current.scrollTo({
        top: conversationBoxRef.current.scrollHeight,
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    handleScroll()
  }, [history])

  const updateChatbotMessageHistory = useCallback(
    ({
      message,
      history,
      messageId,
    }: {
      message: string
      history: ChatbotMessage[]
      messageId?: string
    }): ChatbotMessage[] => {
      const newHistory = [...history]
      const lastMessage = newHistory[newHistory.length - 1]

      if (lastMessage?.role === ChatbotUser.ASSISTANT) {
        newHistory[newHistory.length - 1] = {
          ...lastMessage,
          message,
          id: messageId,
        }
      } else {
        newHistory.push({
          role: ChatbotUser.ASSISTANT,
          message,
          id: messageId,
        })
      }

      return newHistory
    },
    []
  )

  const updateHistory = useCallback(
    ({
      message,
      conversationId,
      messageId,
      conversationName,
    }: {
      message: string
      conversationId: string
      conversationName: string
      messageId?: string
    }) => {
      preventFetchingConversationFlag.current = true
      setSelectedConversationId(conversationId)

      setConversations((previousConversations) => {
        const newConversations = [...previousConversations]

        const conversationIndex = newConversations.findIndex(
          (conversation) => conversation.id === conversationId
        )

        if (conversationIndex === -1) {
          newConversations.unshift({
            id: conversationId,
            name: conversationName,
            createdDate: new Date(),
          })
        }

        return newConversations
      })

      setHistory((previousHistory) =>
        updateChatbotMessageHistory({
          message,
          history: previousHistory,
          messageId,
        })
      )
    },
    [updateChatbotMessageHistory]
  )

  const sendPrompt = useCallback(
    async ({ prompt }: { prompt: string }) => {
      try {
        const controller = new AbortController()
        setAbortController(controller)
        setIsLoading(true)
        setHistory((v) => [
          ...v,
          { role: ChatbotUser.USER, message: prompt },
          { role: ChatbotUser.ASSISTANT, message: '...' },
        ])

        await ChatbotService.sendPrompt({
          prompt,
          conversationId: selectedConversationId,
          onData: ({ reply, conversationId, conversationName, messageId }) =>
            updateHistory({
              message: reply,
              conversationId,
              conversationName,
              messageId,
            }),
          signal: controller.signal,
        })
      } catch (err: any) {
        const buyerChatbotNotAvailable: IErrorCallback = {
          type: errorTypes.FEATURE_NOT_AVAILABLE,
          callback: () => {
            setViewState(ViewState.DISABLED)
          },
        }

        const defaultErrorCallback = () => {
          setIsErrorTriggered(true)

          setHistory((v) => {
            if (v[v.length - 1].role === ChatbotUser.ASSISTANT) {
              v.pop()
            }

            return [
              ...v,
              { role: ChatbotUser.ASSISTANT, message: '', error: true },
            ]
          })
        }

        if (!err?.message?.includes('AbortError')) {
          handleError({
            exception: err,
            defaultErrorCallback,
            errorCallbacks: [buyerChatbotNotAvailable],
          })
        }
      } finally {
        setIsLoading(false)
        setAbortController(null)
      }
    },
    [selectedConversationId, updateHistory, handleError]
  )

  const resendPrompt = useCallback(async () => {
    const prompt = history[history.length - 2].message
    try {
      const controller = new AbortController()
      setAbortController(controller)
      setIsLoading(true)
      setHistory((v) => {
        if (v[v.length - 1].role === ChatbotUser.ASSISTANT) {
          v.pop()
        }

        return [...v, { role: ChatbotUser.ASSISTANT, message: '...' }]
      })

      if (isErrorTriggered) {
        setIsErrorTriggered(false)
      }

      await ChatbotService.sendPrompt({
        prompt,
        conversationId: selectedConversationId,
        onData: ({ reply, conversationId, conversationName, messageId }) =>
          updateHistory({
            message: reply,
            conversationId,
            conversationName,
            messageId,
          }),
        signal: controller.signal,
      })
    } catch (err: any) {
      const buyerChatbotNotAvailable: IErrorCallback = {
        type: errorTypes.FEATURE_NOT_AVAILABLE,
        callback: () => {
          setViewState(ViewState.DISABLED)
        },
      }

      const defaultErrorCallback = () => {
        setIsErrorTriggered(true)

        setHistory((v) => {
          if (v[v.length - 1].role === ChatbotUser.ASSISTANT) {
            v.pop()
          }

          return [
            ...v,
            { role: ChatbotUser.ASSISTANT, message: '', error: true },
          ]
        })
      }

      if (!err?.message?.includes('AbortError')) {
        handleError({
          exception: err,
          defaultErrorCallback,
          errorCallbacks: [buyerChatbotNotAvailable],
        })
      }
    } finally {
      setIsLoading(false)
      setAbortController(null)
    }
  }, [
    history,
    isErrorTriggered,
    selectedConversationId,
    updateHistory,
    handleError,
  ])

  const abortRequest = () => {
    if (abortController) {
      abortController.abort()
      setAbortController(null)
      setHistory((prevHistory) => {
        const newHistory = [...prevHistory]
        if (
          newHistory.length > 0 &&
          newHistory[newHistory.length - 1].role === ChatbotUser.ASSISTANT
        ) {
          newHistory.pop()
        }
        return newHistory
      })
    }
  }

  const handleAbortButtonClick = () => {
    abortRequest()
  }

  const handleOnNewConversation = () => {
    abortRequest()
    setSelectedConversationId(undefined)
    setHistory([])
    setViewState(ViewState.EMPTY)
  }

  const handleConversationSelect = ({
    conversationId,
  }: {
    conversationId: string
  }) => {
    setSelectedConversationId(conversationId)
    setViewState(ViewState.CHAT)
  }

  const handleOnConversationDeleted = (conversationId: string) => {
    setHistory([])
    setSelectedConversationId(undefined)
    setConversations((previousConversations) => {
      const newConversations = previousConversations.filter(
        (conversation) => conversation.id !== conversationId
      )

      if (newConversations.length === 0) {
        setViewState(ViewState.EMPTY)
      }

      return newConversations
    })
  }

  const handleBackButtonClick = () => {
    abortRequest()
    setIsErrorTriggered(false)
    setSelectedConversationId('')
    setViewState(ViewState.CONVERSATIONS)
  }

  const handleSendPrompt = ({ prompt }: { prompt: string }) => {
    setViewState(ViewState.CHAT)
    sendPrompt({ prompt })
  }

  const handleOnClose = () => {
    if (isFirstInteraction) {
      onCloseFirstInteraction()
    }

    onClose()
  }

  const handleOnClickTryNowButton = () => {
    setViewState(ViewState.EMPTY)
  }

  return (
    <Drawer
      sx={quickAccessDrawerSx({ isImpersonatingUser })}
      PaperProps={{ sx: quickAccessDrawerPaperSx({ isImpersonatingUser }) }}
      hideBackdrop={isDesktop}
      variant="temporary"
      open={isOpen}
      anchor="right"
      ModalProps={{
        disableEnforceFocus: isDesktop,
      }}
      data-testid="chatbot-drawer"
    >
      {isOpen && (
        <>
          <Box sx={buttonWrapperSx}>
            {viewState === ViewState.CONVERSATIONS && (
              <Typography variant="h6" sx={chatHistoryTitleSx}>
                {t('Chat History')}
              </Typography>
            )}

            {viewState === ViewState.EMPTY && (
              <>
                {conversations.length > 0 && (
                  <IconButton
                    color="default"
                    size="medium"
                    sx={backButtonSx}
                    onClick={handleBackButtonClick}
                    aria-label={t('Back to chat history')}
                    data-testid="back-to-chat-history-button"
                  >
                    <HistoryIcon />
                  </IconButton>
                )}
              </>
            )}

            {viewState === ViewState.CHAT && (
              <>
                <IconButton
                  color="default"
                  size="medium"
                  sx={backButtonSx}
                  onClick={handleBackButtonClick}
                  aria-label={t('Back to chat history')}
                  data-testid="back-to-chat-history-button"
                >
                  <HistoryIcon />
                </IconButton>

                <Box sx={drawerHeaderSx}>
                  <Typography variant="h6">Cosmo</Typography>
                  <Box component="span" sx={betaTagSx}>
                    Beta
                  </Box>
                </Box>
              </>
            )}

            <IconButton
              color="default"
              size="medium"
              sx={drawerCloseButtonSx}
              aria-label={t('Close chatbot')}
              onClick={handleOnClose}
              data-testid="chatbot-close-button"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            ref={conversationBoxRef}
            sx={middleContentWrapperSx({
              isScrollable: viewState === ViewState.CHAT,
              isCentered:
                viewState === ViewState.DISABLED ||
                viewState === ViewState.WELCOME,
            })}
          >
            {viewState === ViewState.WELCOME && (
              <ChatbotWelcome onClick={handleOnClickTryNowButton} />
            )}

            {viewState === ViewState.DISABLED && <ChatbotDrawerDisabled />}

            {viewState === ViewState.EMPTY && (
              <EmptyHistory onSendPrompt={handleSendPrompt} />
            )}

            {viewState === ViewState.CONVERSATIONS && (
              <ConversationList
                conversations={conversations}
                onNewConversation={handleOnNewConversation}
                onConversationDeleted={handleOnConversationDeleted}
                onSelectConversation={handleConversationSelect}
              />
            )}

            {viewState === ViewState.CHAT && (
              <ConversationView
                history={history}
                conversationId={selectedConversationId}
                isStreaming={isLoading}
                resendPrompt={resendPrompt}
                onScroll={handleScrollSmooth}
                onLinkClick={onClose}
              />
            )}
          </Box>
          {(viewState === ViewState.CHAT || viewState === ViewState.EMPTY) &&
            !isErrorTriggered && (
              <PromptInput
                onSend={handleSendPrompt}
                isLoading={isLoading}
                onAbort={handleAbortButtonClick}
                shouldFocus={viewState === ViewState.EMPTY}
              />
            )}
        </>
      )}
    </Drawer>
  )
}

export { ChatbotDrawer }
