import { ChangeEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import i18n from '@libs/i18n'

import { useLocalStorage } from '@hooks/useLocalStorage'

import { LOCAL_STORAGE_CHAT_VISITOR_INFO_KEY } from '@utils/local-storage'
import type { IVisitorChatInfo } from '@utils/types'

const t = i18n.t.bind(i18n)

const INITIAL_VALUES = {
  visitorName: '',
  companyName: '',
}

type FormData = typeof INITIAL_VALUES

const YUP_SCHEMA = Yup.object().shape({
  visitorName: Yup.string()
    .required(t('Required'))
    .min(2, t('Min. 2 characters'))
    .max(20, t('Max. 20 characters')),
  companyName: Yup.string()
    .min(2, t('Min. 2 characters'))
    .max(20, t('Max. 20 characters')),
})

interface IVisitorInfoDialogProps {
  open: boolean
  onCancel: () => void
  onContinue: () => void
}

const VisitorInfoDialog = ({
  open,
  onCancel,
  onContinue,
}: IVisitorInfoDialogProps) => {
  const { t } = useTranslation()

  const { setValue: setLocalStorageChatVisitorData } = useLocalStorage<
    IVisitorChatInfo | undefined
  >(LOCAL_STORAGE_CHAT_VISITOR_INFO_KEY, undefined)

  const handleOnSubmit = useCallback(
    (values: FormData) => {
      const { visitorName, companyName } = values

      setLocalStorageChatVisitorData({
        visitorName,
        companyName: companyName || undefined,
      })

      onContinue()
    },
    [setLocalStorageChatVisitorData, onContinue]
  )

  const formik = useFormik<FormData>({
    validationSchema: YUP_SCHEMA,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    initialValues: INITIAL_VALUES,
    onSubmit: handleOnSubmit,
  })

  const handleOnFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target

    formik.handleChange(event)
    formik.setFieldError(name, undefined)
  }

  const isAllRequiredFieldsFilled = useCallback(
    (): boolean => !!formik.values.visitorName,
    [formik.values]
  )

  return (
    <>
      <Dialog
        data-testid="request-visitor-info-dialog"
        disableScrollLock
        open={open}
        onClose={onCancel}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '44.6rem',
          },
        }}
      >
        <Box component="form" onSubmit={formik.handleSubmit}>
          <DialogTitle
            sx={{
              height: '12.5rem',
              display: 'flex',
              paddingBottom: 0,
            }}
          >
            <Box
              sx={{
                alignSelf: 'center',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box sx={{ ml: '0.5rem' }}>
                <Typography variant="h5">{t('Customer Support')}</Typography>
                <Typography
                  sx={{
                    letterSpacing: '0.015rem',
                    marginTop: '0.8rem',
                  }}
                >
                  {t(
                    'Before we start, please share with us the following information:'
                  )}
                </Typography>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent
            sx={{
              padding: '2.4rem !important',
              display: 'flex',
              flexDirection: 'column',
              gap: '1.6rem',
            }}
          >
            <TextField
              aria-label={t('Admin first name')}
              label={t('Your Full Name')}
              size="small"
              name="visitorName"
              variant="filled"
              value={formik.values.visitorName}
              error={!!formik.errors.visitorName}
              helperText={formik.errors.visitorName}
              inputProps={{
                maxLength: 20,
              }}
              onChange={handleOnFieldChange}
            />
            <TextField
              aria-label={t('Your Company Name')}
              label={t('Your Company Name')}
              size="small"
              name="companyName"
              variant="filled"
              value={formik.values.companyName}
              error={!!formik.errors.companyName}
              helperText={formik.errors.companyName}
              inputProps={{
                maxLength: 20,
              }}
              onChange={handleOnFieldChange}
            />
          </DialogContent>
          <DialogActions sx={{ paddingBottom: '2.4rem' }}>
            <Button
              aria-label={t('Cancel')}
              size="medium"
              variant="outlined"
              onClick={onCancel}
            >
              {t('Cancel')}
            </Button>
            <LoadingButton
              aria-label={
                isAllRequiredFieldsFilled() ? t('Continue') : t('Fill the form')
              }
              variant="contained"
              size="medium"
              disabled={!isAllRequiredFieldsFilled()}
              type="submit"
              loading={formik.isValidating || formik.isSubmitting}
            >
              {isAllRequiredFieldsFilled() ? t('CONTINUE') : t('FILL THE FORM')}
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  )
}

export { VisitorInfoDialog }
