import { Theme } from '@mui/material'

import {
  Channel,
  ChannelMemberResponse,
  Channel as ChannelType,
  DefaultGenerics,
} from 'stream-chat'

import { streamChatClient } from '@libs/stream-chat'

import { getUserFullName } from '@utils/users'

import {
  AssistanceChatSourcePage,
  AssistanceCustomFields,
  ChatStatus,
  ChatUserRole,
  ChatUserType,
  IAdvisorChatData,
  IBuyer,
  IChatMessageExtra,
  IChatUser,
  IVendor,
  StreamChatChannelType,
  UserType,
} from './types'

export const THE_LAUNCHPAD_USER_NAME = 'The Launchpad'
export const DEFAULT_ADMIN_SUPPORT_NAME = 'The Launchpad Support'

export enum AssistanceEvent {
  NEW_ADVISOR_CHAT = 'new_advisor_chat',
  NEW_ARCHIVED_CHAT = 'new_archived_chat',
  INITIAL_ADMIN_ASSIGNMENT = 'initial_admin_assignment',
  NEW_ADMIN_ASSIGNMENT = 'new_admin_assignment',
  CUSTOM_ADMIN_ASSIGNED_TO_CHAT = 'custom_admin_assigned_to_chat',
  NOTIFICATION_ADMIN_ASSIGNED_TO_CHAT = 'notification_admin_assigned_to_chat',
  NOTIFICATION_ADMIN_UNASSIGNED_FROM_CHAT = 'notification_admin_unassigned_from_chat',
  NEW_MESSAGE = 'new_message',
  READ_MESSAGE = 'read_message',
}

const sourcePageMapping = {
  [AssistanceChatSourcePage.PROMOTIONAL_LANDING_PAGE]:
    'Promotional Landing Page',
  [AssistanceChatSourcePage.BUYER_APP]: 'Tech Customer App',
  [AssistanceChatSourcePage.VENDOR_APP]: 'Tech Vendor App',
}

export const SOURCE_PAGE_ITEMS: { value: string; label: string }[] =
  Object.entries(sourcePageMapping).map(([value, label]) => {
    return { value, label }
  })

export const getSourcePageText = ({
  sourcePage,
}: {
  sourcePage: AssistanceChatSourcePage
}) =>
  sourcePageMapping[sourcePage] ?? sourcePageMapping['promotional-landing-page']

export const getAssistedUserTypeMappedText = ({
  assistedChatUserType,
}: {
  assistedChatUserType: ChatUserType
}) => {
  const assistedUserTypeMappedText = {
    [ChatUserType.BUYER]: 'Tech Customer',
    [ChatUserType.VENDOR]: 'Tech Vendor',
    [ChatUserType.VISITOR]: 'Visitor',
    [ChatUserType.ADMIN]: 'Admin',
  }

  return assistedUserTypeMappedText[assistedChatUserType]
}

export const closeImagePreviewModal = () => {
  const closeImagePreviewModalButton = document.querySelector(
    '.str-chat__modal__close-button'
  ) as HTMLElement

  if (!closeImagePreviewModalButton) {
    return false
  }

  closeImagePreviewModalButton.click()
  return true
}

export const queryChannel = async ({
  chatChannelId,
  channelType,
  watch,
}: {
  chatChannelId: string
  channelType?: string
  watch?: false
}) => {
  const chatChannels = await streamChatClient.queryChannels({
    id: chatChannelId,
    type: channelType ?? StreamChatChannelType.MESSAGING,
  })

  const chatChannel = chatChannels[0]

  if (watch) {
    await chatChannel.watch()
  }

  return chatChannel
}

export const getUserChannelMember = ({
  channel,
  filter,
}: {
  channel: Channel<DefaultGenerics>
  filter: (
    value: ChannelMemberResponse<DefaultGenerics>,
    index: number,
    array: ChannelMemberResponse<DefaultGenerics>[]
  ) => unknown
}): IChatUser | undefined => {
  const users = Object.values(channel.state.members).filter(filter)
  if (!users.length) {
    return undefined
  }

  return users[0].user as unknown as IChatUser
}

export const getAssistedUserChannelMember = ({
  channel,
}: {
  channel: Channel<DefaultGenerics>
}) =>
  getUserChannelMember({
    channel,
    filter: (member) =>
      member?.role === ChatUserRole.OWNER &&
      member?.user?.role !== ChatUserRole.ADMIN,
  })

export const getAssignedAdminChannelMember = ({
  channel,
}: {
  channel: Channel<DefaultGenerics>
}) =>
  getUserChannelMember({
    channel,
    filter: (member) =>
      member?.role === ChatUserRole.ADMIN &&
      member?.user?.role === ChatUserRole.ADMIN,
  })

export const storeLatestMessageMetadataForSorting = ({
  chatChannel,
  sentText,
}: {
  chatChannel: ChannelType<DefaultGenerics> | undefined
  sentText: string | undefined
}) => {
  if (!chatChannel) {
    return
  }

  const firstWordRegex = /^([^\w])*([\w-]+)/g
  const firstWordMetadata =
    sentText?.match(firstWordRegex)?.[0].substring(0, 20) ?? ''

  chatChannel?.updatePartial({
    set: {
      latestMessageText: firstWordMetadata,
      latestMessageDate: Date.now(),
    },
  })
}

const getChannelMembersInAdvisorChatDataFormat = ({
  channel,
}: {
  channel: Channel<DefaultGenerics>
}) => {
  const { assistedUserType } = channel.data as unknown as AssistanceCustomFields

  const assistedUserChatChannelMember = getAssistedUserChannelMember({
    channel,
  })

  const assignedAdminChatChannelMember = getAssignedAdminChannelMember({
    channel,
  })

  const assignedAdmin = assignedAdminChatChannelMember
    ? {
        id: assignedAdminChatChannelMember.id,
        name: assignedAdminChatChannelMember.name,
        email: assignedAdminChatChannelMember.email,
        type: assignedAdminChatChannelMember.type,
        officialTitle: assignedAdminChatChannelMember.officialTitle,
      }
    : undefined

  const assistedUser = assistedUserChatChannelMember
    ? {
        id: assistedUserChatChannelMember.id,
        name: assistedUserChatChannelMember.name,
        type: assistedUserType,
        officialTitle: assistedUserChatChannelMember.officialTitle,
        email: assistedUserChatChannelMember.email,
        companyName:
          assistedUserChatChannelMember.companyName ??
          channel.data?.metadataCompanyName,
      }
    : undefined

  return { assignedAdmin, assistedUser }
}

export const getLastMessageFromChannel = ({
  channel,
}: {
  channel: Channel<DefaultGenerics>
}) => {
  const messages = channel.state.messages.filter(
    (message) => !(message.extra as IChatMessageExtra)?.automaticResponse
  )

  return messages.sort(
    (a, b) => b.created_at.getTime() - a.created_at.getTime()
  )?.[0]
}

export const getAdvisorChatDataFromChannel = ({
  channel,
}: {
  channel: Channel<DefaultGenerics>
}) => {
  const { sourcePage } = channel.data as unknown as AssistanceCustomFields
  const { assignedAdmin, assistedUser } =
    getChannelMembersInAdvisorChatDataFormat({ channel })

  const lastMessage = getLastMessageFromChannel({ channel })
  const lastMessageHasText =
    lastMessage?.text?.length && lastMessage?.text?.length > 0
  const unreadMessages = assignedAdmin?.id
    ? channel.state.read?.[assignedAdmin?.id]?.unread_messages ?? 0
    : 0
  return {
    channelId: channel.id,
    sourcePage,
    assignedAdmin,
    assistedUser,
    unreadMessageCount: unreadMessages,
    ...(lastMessage && {
      latestMessage: {
        userId: lastMessage.user?.id,
        text: lastMessageHasText ? lastMessage?.text : undefined,
        createdDate: lastMessage?.created_at,
        attachments: lastMessage?.attachments,
      },
    }),
  } as IAdvisorChatData
}

export const parseStreamChatUserName = (user: {
  firstName: string | undefined
  lastName: string | undefined
}) => {
  const { firstName, lastName } = user

  if (!firstName && !lastName) {
    return ''
  }

  return `${firstName} ${lastName}`
}

export const getBuyerOrVendorAssistedChatUserInfo = (
  user:
    | (IBuyer | IVendor) & {
        activeUserType: UserType
      }
): IChatUser => {
  return {
    id: user!.userId,
    name: getUserFullName({
      firstName: user!.firstName,
      lastName: user!.lastName,
    }),
    avatar: user!.avatar,
    email: user!.email,
    companyName: user!.company?.name,
    token: user!.chatToken!,
    type: user!.activeUserType as unknown as ChatUserType,
  }
}

export const getChatStatusTextColor = ({
  chatStatus,
}: {
  chatStatus: ChatStatus
}) => {
  const color = (theme: Theme) => {
    switch (chatStatus) {
      case ChatStatus.ONLINE:
        return theme.palette.success.main
      case ChatStatus.IDLE:
        return theme.palette.error.light
      case ChatStatus.OFFLINE:
        return theme.palette.primary.light
    }
  }

  return color
}
