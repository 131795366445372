import {
  BaseSyntheticEvent,
  ChangeEvent,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'

import { IconButton, Stack, Typography } from '@mui/material'

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import AttachFileIcon from '@mui/icons-material/AttachFile'

import { Message } from 'stream-chat'
import {
  FileUpload,
  ImageUpload,
  useMessageInputContext,
} from 'stream-chat-react'
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types'

import authHook from '@hooks/useAuth'
import toastHook from '@hooks/useToast'

import { TextField } from '@components/TextField'

import { AllowedStreamChatMimeTypes } from '@utils/files'

import {
  addAttachmentButtonSx,
  attachFileIconSx,
  chatMessageTextFieldSx,
  chatMessageTextFieldWrapperSx,
  chatSendMessageButtonIconSx,
  chatSendMessageButtonSx,
  containerSx,
  numberOfAttachmentToBeSentTextSx,
} from './styles'

interface IChatMessageInputProps {
  disabled?: boolean
  sendAttachmentSupport?: boolean
  ignoreImpersonation?: boolean
  customMessageData?: Partial<Message<DefaultStreamChatGenerics>> | undefined
  onMessageSent?: () => void
}

const ChatMessageInput = forwardRef(
  (
    {
      disabled,
      ignoreImpersonation,
      sendAttachmentSupport = true,

      customMessageData,
      onMessageSent,
    }: IChatMessageInputProps,
    inputRef
  ) => {
    const { t } = useTranslation()
    const { show: showToast } = toastHook.useToast()
    const attachmentInputRef = useRef<HTMLInputElement>(null)
    const messageInputRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(inputRef, () => messageInputRef.current)

    const { impersonatedBy } = authHook.useAuth()

    const {
      text,
      fileUploads,
      imageUploads,
      uploadNewFiles,
      handleChange,
      handleSubmit,
    } = useMessageInputContext()

    const attachmentsToBeSentLength =
      Object.keys(fileUploads).length + Object.keys(imageUploads).length

    const checkIfIsUploadingAttachment = (v: FileUpload | ImageUpload) =>
      v.state === 'uploading'

    const isUploadingAttachments =
      Object.values(fileUploads).some(checkIfIsUploadingAttachment) ||
      Object.values(imageUploads).some(checkIfIsUploadingAttachment)

    const handleOnFileChange = (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault()

      const { target } = event

      uploadNewFiles(target.files ?? [])
      target.value = ''

      messageInputRef.current?.focus()
    }

    const getMessageInputPlaceholder = () => {
      if (isUploadingAttachments) {
        return t('Uploading attachments...')
      }

      return t('Send a message')
    }

    const handleOnSendMessage = (event: BaseSyntheticEvent<any, any, any>) => {
      event.preventDefault()
      event.stopPropagation()

      try {
        if (impersonatedBy && !ignoreImpersonation) {
          const id = impersonatedBy?.userId
          const name = `${impersonatedBy?.firstName} ${impersonatedBy?.lastName}`

          handleSubmit(event, {
            extra: {
              impersonatedBy: {
                id,
                name,
              },
            },
          })
        } else {
          handleSubmit(event, customMessageData)
        }

        onMessageSent?.()

        messageInputRef.current?.focus()
      } catch (err) {
        showToast({
          type: 'error',
          message: 'An error occurred while sending a chat message.',
        })
      }
    }

    return (
      <Stack direction="row" sx={containerSx}>
        {sendAttachmentSupport && (
          <IconButton
            data-testid="add-attachment-button"
            aria-label={t('Add attachment')}
            color="primary"
            disabled={disabled}
            sx={addAttachmentButtonSx}
            onClick={() => attachmentInputRef.current?.click()}
          >
            {attachmentsToBeSentLength > 0 && (
              <Typography sx={numberOfAttachmentToBeSentTextSx}>
                {attachmentsToBeSentLength}
              </Typography>
            )}
            <AttachFileIcon sx={attachFileIconSx} />
            <input
              ref={attachmentInputRef}
              multiple
              hidden
              type="file"
              accept={Object.values(AllowedStreamChatMimeTypes).join(',')}
              onChange={handleOnFileChange}
            />
          </IconButton>
        )}

        <TextField
          ref={messageInputRef}
          data-testid="message-input"
          value={text}
          disabled={disabled}
          onChange={handleChange}
          placeholder={getMessageInputPlaceholder()}
          onKeyDown={(event: React.KeyboardEvent) => {
            if (event.key === 'Enter') {
              handleOnSendMessage(event)
            }
          }}
          sx={chatMessageTextFieldWrapperSx}
          InputProps={{
            sx: chatMessageTextFieldSx,
          }}
          inputProps={{
            style: {
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
        />
        <IconButton
          data-testid="send-message-button"
          aria-label={t('Send message')}
          color="primary"
          sx={chatSendMessageButtonSx}
          disabled={isUploadingAttachments || disabled}
          title={
            isUploadingAttachments
              ? t('Wait for attachments upload...')
              : t('Send message')
          }
          onClick={handleOnSendMessage}
        >
          <ArrowCircleRightIcon sx={chatSendMessageButtonIconSx} />
        </IconButton>
      </Stack>
    )
  }
)

export { ChatMessageInput }
