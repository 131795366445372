import { Box, Typography } from '@mui/material'

import { userMessageSx } from './styles'

const UserMessage = ({ message }: { message: string }) => (
  <Box data-testid="conversation-history-item" sx={userMessageSx}>
    <Typography variant="body1">{message}</Typography>
  </Box>
)

export { UserMessage }
