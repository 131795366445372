export const NEW_VISITOR_CHAT_DATA_WINDOW_EVENT =
  'new_visitor_chat_data_window_event'
export const NEW_VISITOR_CHAT_CHANNEL_CREATED_WINDOW_EVENT =
  'new_visitor_chat_channel_created_window_event'

declare global {
  interface WindowEventMap {
    [NEW_VISITOR_CHAT_DATA_WINDOW_EVENT]: CustomEvent
    [NEW_VISITOR_CHAT_CHANNEL_CREATED_WINDOW_EVENT]: CustomEvent
    local_storage_chat_visitor_info_key: CustomEvent
  }
}

const dispatch = ({
  event,
  value,
}: {
  event: keyof WindowEventMap
  value: any
}) => {
  window.dispatchEvent(
    new CustomEvent(event, {
      detail: {
        value,
      },
    })
  )
}

export default {
  dispatch,
}
