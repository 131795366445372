import { useTranslation } from 'react-i18next'

import { IconButton, Stack, Typography } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'

import responsiveHook from '@hooks/useResponsive'

import {
  channelNameSx,
  chatHeaderSx,
  closeChatButtonSx,
  openSideBarDrawerButtonSx,
  openSideBarDrawerIconButtonSx,
} from './styles'

interface IHeaderProps {
  showDrawerIconButton: boolean
  chatChannelHeaderText: string
  onOpenSideBarDrawer: () => void
  onDialogClose: () => void
}

const Header = ({
  chatChannelHeaderText,
  showDrawerIconButton,
  onOpenSideBarDrawer,
  onDialogClose,
}: IHeaderProps) => {
  const { t } = useTranslation()
  const { isMobileOrTablet } = responsiveHook.useResponsive()

  return (
    <Stack direction="row" sx={chatHeaderSx}>
      {showDrawerIconButton && isMobileOrTablet && (
        <IconButton
          id="menu-button"
          aria-label={t('Open conversation menu')}
          sx={openSideBarDrawerButtonSx}
          onClick={onOpenSideBarDrawer}
        >
          <MenuIcon sx={openSideBarDrawerIconButtonSx} />
        </IconButton>
      )}

      <Typography
        variant="h6"
        sx={channelNameSx({
          addLeftMargin: showDrawerIconButton,
        })}
      >
        {chatChannelHeaderText}
      </Typography>
      <IconButton
        data-testid="close-chat-button"
        sx={closeChatButtonSx}
        onClick={onDialogClose}
      >
        <CloseIcon />
      </IconButton>
    </Stack>
  )
}

export { Header }
