import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

const init = () => {
  const dataDogClientToken = process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN
  const dataDogRumApplicationId =
    process.env.REACT_APP_DATA_DOG_RUM_APPLICATION_ID

  const environment = process.env.REACT_APP_SENTRY_ENVIRONMENT

  const isLocalEnvironment = (environment ?? 'local') === 'local'
  const isTestEnvironment = process.env.NODE_ENV === 'test'

  if (
    !dataDogClientToken ||
    !dataDogRumApplicationId ||
    isLocalEnvironment ||
    isTestEnvironment
  ) {
    return
  }

  datadogLogs.init({
    clientToken: dataDogClientToken,
    service: 'web',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  })

  datadogRum.init({
    applicationId: dataDogRumApplicationId,
    clientToken: dataDogClientToken,
    service: 'web',
    env: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    version: process.env.REACT_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
}

init()
