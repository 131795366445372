import { Outlet } from 'react-router-dom'

import { Box } from '@mui/material'

import authHook from '@hooks/useAuth'

import {
  IMPERSONATION_BAR_HEIGHT,
  ImpersonationBar,
} from '@pages/Template/ImpersonationBar'

import { FooterCopyright } from '@components/FooterCopyright'

import { contentWrapperSx, rootSx, wrapperSx } from './styles'

const TemplateWithoutSideBar = () => {
  const { impersonatedUser } = authHook.useAuth()

  const isImpersonatingUser = !!impersonatedUser
  const maxContentHeight = `calc(100vh - ${
    isImpersonatingUser ? IMPERSONATION_BAR_HEIGHT : '0px'
  })`

  return (
    <Box sx={rootSx} data-testid="template">
      {isImpersonatingUser && (
        <ImpersonationBar impersonation={impersonatedUser} />
      )}

      <Box sx={wrapperSx}>
        <Box sx={contentWrapperSx({ maxContentHeight })}>
          <Outlet />
          <FooterCopyright />
        </Box>

        <Box id="right-bar-section" />
      </Box>
    </Box>
  )
}

export { TemplateWithoutSideBar }
