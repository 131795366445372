import api from '@libs/api'

import { handleServiceError } from '@utils/errors'
import {
  ChangelogAction,
  IApiData,
  IProductServiceChangelog,
  IQueryFilters,
  ISolutionChangelog,
  ITeamMemberChangelog,
  IUserChangelog,
  UserType,
} from '@utils/types'
import { buildQueryString } from '@utils/url'

const findUserChangelog = async ({
  userType,
  userId,
  queryFilters,
}: {
  userType: UserType
  userId: string
  queryFilters: IQueryFilters
}): Promise<IApiData<IUserChangelog[]>> => {
  const queryString = buildQueryString(queryFilters)

  try {
    const response = await api.get({
      path: `/${userType}/${userId}/changelog`,
      queryString,
    })

    const { data, count } = response

    const mappedData = data.map((v: any) => {
      return {
        ...v,
        user: {
          ...v.user,
          createdDate: new Date(v.user.createdDate),
          updatedDate: new Date(v.user.updatedDate),
        },
        createdDate: new Date(v.createdDate),
        updatedDate: new Date(v.updatedDate),
      }
    })

    return {
      data: mappedData,
      count,
    }
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const findTeamMemberChangelog = async ({
  userId,
  queryFilters,
}: {
  userId: string
  queryFilters: IQueryFilters
}): Promise<IApiData<ITeamMemberChangelog[]>> => {
  const queryString = buildQueryString(queryFilters)

  try {
    const response = await api.get({
      path: `/admins/${userId}/changelog`,
      queryString,
    })

    const { data, count } = response

    const mappedData = data.map((v: any) => {
      return {
        ...v,
        user: {
          ...v.user,
          createdDate: new Date(v.user.createdDate),
          updatedDate: new Date(v.user.updatedDate),
        },
        createdDate: new Date(v.createdDate),
        updatedDate: new Date(v.updatedDate),
      }
    })

    return {
      data: mappedData,
      count,
    }
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const saveUserImpersonationChangelog = async ({
  userType,
  userId,
  action,
}: {
  userType: UserType
  userId: string
  action:
    | ChangelogAction.IMPERSONATION_START
    | ChangelogAction.IMPERSONATION_STOP
}): Promise<IApiData<IUserChangelog[]>> => {
  try {
    const response = (await api.post({
      path: `/${userType}/${userId}/changelog/impersonation`,
      data: {
        action,
      },
    })) as IApiData<IUserChangelog[]>

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const findProductServiceChangelog = async ({
  productServiceId,
  queryFilters,
}: {
  productServiceId: string
  queryFilters: IQueryFilters
}): Promise<IApiData<IProductServiceChangelog[]>> => {
  const queryString = buildQueryString(queryFilters)

  try {
    const response = await api.get({
      path: `/products-services/${productServiceId}/changelog`,
      queryString,
    })

    const { data, count } = response

    const mappedData = data.map((v: any) => {
      return {
        ...v,
        createdDate: new Date(v.createdDate),
        updatedDate: new Date(v.updatedDate),
      }
    })

    return {
      data: mappedData,
      count,
    }
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const findSolutionChangelog = async ({
  solutionId,
  queryFilters,
}: {
  solutionId: string
  queryFilters: IQueryFilters
}): Promise<IApiData<ISolutionChangelog[]>> => {
  const queryString = buildQueryString(queryFilters)

  try {
    const response = await api.get({
      path: `/solutions/${solutionId}/changelog`,
      queryString,
    })

    const { data, count } = response

    const mappedData = data.map((v: any) => {
      return {
        ...v,
        createdDate: new Date(v.createdDate),
        updatedDate: new Date(v.updatedDate),
      }
    })

    return {
      data: mappedData,
      count,
    }
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

export default {
  findUserChangelog,
  saveUserImpersonationChangelog,
  findProductServiceChangelog,
  findSolutionChangelog,
  findTeamMemberChangelog,
}
