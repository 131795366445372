import { SxProps, Theme } from '@mui/material'

import { AvatarSize } from '@components/Avatar'

export const avatarStyleSx = ({
  size,
}: {
  size?: AvatarSize
}): SxProps<Theme> => {
  const propsBySize = {
    [AvatarSize.MICRO]: {
      width: '2.4rem',
      height: '2.4rem',
    },
    [AvatarSize.SMALL]: {
      width: '4rem',
      height: '4rem',
    },
    [AvatarSize.MEDIUM]: {
      width: '4.8rem',
      height: '4.8rem',
    },
    [AvatarSize.LARGE]: {
      width: '6.4rem',
      height: '6.4rem',
    },
  }

  return {
    background: (theme) => theme.palette.background.paper,
    ...(size ? propsBySize[size] : { width: '5.4rem', height: '5.4rem' }),
  }
}

export const defaultAvatarStyleSx: SxProps<Theme> = {
  width: '5.4rem',
  height: '5.4rem',
  border: (theme) => `0.2rem solid ${theme.palette.background.paper}`,
  color: (theme) => theme.palette.background.default,
}
