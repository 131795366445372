import { useTranslation } from 'react-i18next'

import { Stack, Typography } from '@mui/material'

import WarningAmberIcon from '@mui/icons-material/WarningAmber'

import { containerSx, warningIconSx } from './styles'

const ImpersonationWarning = () => {
  const { t } = useTranslation()

  return (
    <Stack direction="row" sx={containerSx}>
      <Typography
        variant="caption"
        sx={{
          color: (theme) => theme.palette.warning.dark,
        }}
      >
        {t('Impersonated Message')}
      </Typography>
      <WarningAmberIcon aria-label={t('Warning icon')} sx={warningIconSx} />
    </Stack>
  )
}

export { ImpersonationWarning }
