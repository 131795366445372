import { useTranslation } from 'react-i18next'

import { Box, Stack } from '@mui/material'

import viewportHeightHook from '@hooks/useViewportHeight'

import { TLGTypography } from '@components/TLGTypography'

import { containerSx, innerContainerSx, textSx } from './styles'

import { ReturnButton } from '../components/ReturnButton'

const ErrorPage404 = () => {
  const { t } = useTranslation()

  viewportHeightHook.useViewportHeight()

  return (
    <Box sx={containerSx}>
      <Stack sx={innerContainerSx}>
        <TLGTypography
          responsiveVariant={{
            xs: 'h4',
            lg: 'h2',
          }}
          sx={textSx}
        >
          {t('Whoops! That page is gone')}
        </TLGTypography>

        <TLGTypography
          responsiveVariant={{
            xs: 'body1',
            md: 'h6',
          }}
          sx={textSx}
        >
          {t(
            'We apologize for any inconvenience this may have caused. You can check the URL and try again, or we can take you back to The Launchpad dashboard.'
          )}
        </TLGTypography>
        <ReturnButton />
      </Stack>
    </Box>
  )
}

export { ErrorPage404 }
