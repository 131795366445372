import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Divider, Stack, Typography } from '@mui/material'

import { Channel as ChannelType, DefaultGenerics } from 'stream-chat'

import chatHook from '@hooks/useChat'

import { withMobileDrawer } from '@HOCs/withMobileDrawer'

import { Avatar } from '@components/Avatar'

import StringUtils from '@utils/strings'
import type { IChatUser } from '@utils/types'

import {
  adminOfficialTitleSx,
  buyerAvatarSx,
  buyerInfoWrapperSx,
  buyerNameAndOnlineStatusWrapperSx,
  buyerNameAndStatusWrapperSx,
  buyerWrapperSx,
  channelsListSx,
  chatChannelHeaderTitleSx,
  companyChatChannelHeaderSx,
  containerSx,
  onlineStatusTextSx,
} from './styles'

import { UserChatChannel } from '../../../UserChatChannel'

interface ISideBarProps {
  chatChannel?: ChannelType<DefaultGenerics>
  assistedChatUser: IChatUser
  assignedAdmin?: IChatUser
}

const SideBar = ({
  chatChannel,
  assistedChatUser,
  assignedAdmin,
}: ISideBarProps) => {
  const { t } = useTranslation()
  const { processUserChatStatus } = chatHook.useChat()

  const getUserChatStatus = useCallback(() => {
    const member = chatChannel?.state?.members?.[assistedChatUser.id]

    const chatStatus = processUserChatStatus({
      user: member?.user,
    })

    return chatStatus
  }, [assistedChatUser.id, chatChannel?.state?.members, processUserChatStatus])

  const userChatStatus = getUserChatStatus()

  return (
    <Box component="aside" sx={containerSx}>
      <Stack sx={buyerWrapperSx}>
        <Stack direction="row" sx={buyerInfoWrapperSx}>
          <Avatar
            alt={t('Assisted user avatar')}
            src={assistedChatUser.avatar}
            sx={buyerAvatarSx}
          />
          <Stack sx={buyerNameAndStatusWrapperSx}>
            <Stack direction="row" sx={buyerNameAndOnlineStatusWrapperSx}>
              <Typography data-testid="assisted-user-name" variant="h6">
                {assistedChatUser.name}
              </Typography>
              <Typography
                data-testid="assisted-online-status"
                variant="caption"
                sx={onlineStatusTextSx({ chatStatus: userChatStatus })}
              >
                {t(StringUtils.capitalizeWords(userChatStatus))}
              </Typography>
            </Stack>

            {!!assistedChatUser?.officialTitle && (
              <Typography
                data-testid="admin-official-title"
                variant="body2"
                sx={adminOfficialTitleSx}
              >
                {assistedChatUser?.officialTitle}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>

      <Divider />

      {chatChannel && assignedAdmin && (
        <Box component="ul" sx={channelsListSx}>
          <Box component="li" sx={companyChatChannelHeaderSx}>
            <Typography variant="subtitle2" sx={chatChannelHeaderTitleSx}>
              {t('Assigned Advisor')}
            </Typography>
          </Box>
          <UserChatChannel user={assignedAdmin} chatChannel={chatChannel} />
        </Box>
      )}
    </Box>
  )
}

const SideBarWithHoc = withMobileDrawer(SideBar)
export { SideBarWithHoc as SideBar }
