import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CircularProgress, Dialog, Divider, Stack } from '@mui/material'

import * as Sentry from '@sentry/react'
import { Channel as ChannelType, DefaultGenerics, Event } from 'stream-chat'
import {
  Channel,
  MessageInput,
  MessageList,
  Chat as ReactStreamChat,
  StreamMessage,
} from 'stream-chat-react'
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types'
import { v4 as uuidv4 } from 'uuid'

import { streamChatClient } from '@libs/stream-chat'

import chatHook from '@hooks/useChat'
import errorHandlerHook from '@hooks/useErrorHandler'
import titleHook from '@hooks/useTitle'

import ChatService from '@services/chat'

import { ChatMessage } from '@components/ChatMessage'
import { ChatMessageInput } from '@components/ChatMessageInput'
import { DateSeparator } from '@components/DateSeparator'

import { closeImagePreviewModal, getSourcePageText } from '@utils/chats'
import {
  AssistanceAutomaticMessageType,
  AssistanceChatSourcePage,
  ChatUserRole,
  ChatUserType,
  IAdminAdvisorStreamChatChannelData,
  IChatMessage,
  IChatUser,
  StreamChatChannelType,
} from '@utils/types'
import WindowEventUtils, {
  NEW_VISITOR_CHAT_CHANNEL_CREATED_WINDOW_EVENT,
} from '@utils/window-events'

import {
  chatContentSx,
  chatWrapperSx,
  circularProgressSx,
  contentSx,
  dialogPaperSx,
} from './styles'

import { Header } from './components/Header'
import { SideBar } from './components/SideBar'

interface IAssistedAdvisorChatDialogProps {
  open: boolean
  showSideBar: boolean
  sourcePage: AssistanceChatSourcePage
  assistedChatUser: IChatUser
  onClose: () => void
}

const CONNECTION_CHANGED_EVENT_ID = uuidv4()

export const DIALOG_ID = 'assistance-chat-dialog'

const AssistedAdvisorChatDialog = ({
  open,
  showSideBar,
  sourcePage,
  assistedChatUser,
  onClose,
}: IAssistedAdvisorChatDialogProps) => {
  const { t } = useTranslation()
  const { handleError } = errorHandlerHook.useErrorHandler()

  const newMessageSub = useRef<{ unsubscribe: () => void }>()
  const alreadyRequestedAllNonOfflineAutomaticMessages = useRef(false)
  const assignedAdminRef = useRef<IChatUser>()

  const [isConnecting, setIsConnecting] = useState(false)
  const [chatChannel, setChatChannel] = useState<ChannelType<DefaultGenerics>>()
  const [isSideBarDrawerOpen, setIsSideBarDrawerOpen] = useState(false)
  const {
    isConnectionOpen,
    subscribeToChatEvent,
    unsubscribeFromAllChatEvents,
  } = chatHook.useChat()

  const { restorePreviousPageTitle, changePageTitle } = titleHook.useTitle(
    `${document.title} - ${t('Assistance Chat')}`
  )

  const handleOnDialogClose = () => {
    if (isConnecting) {
      return
    }

    const closedImagePreviewModal = closeImagePreviewModal()

    if (!closedImagePreviewModal) {
      onClose()
    }
  }

  useEffect(() => {
    if (!chatChannel) {
      return
    }

    const connected = !chatChannel?.disconnected

    if (connected) {
      requestAutomaticMessage({
        messageType: AssistanceAutomaticMessageType.WELCOME,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatChannel])

  const requestAutomaticMessage = useCallback(
    async ({
      messageType,
    }: {
      messageType: AssistanceAutomaticMessageType
    }) => {
      try {
        await ChatService.requestAssistanceChatAutomaticResponse({
          chatUserId: assistedChatUser.id,
          channelId: chatChannel!.id!,
          messageType,
        })
      } catch (err) {
        Sentry.captureException(err)
        onClose()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [assistedChatUser.id, chatChannel, onClose]
  )

  const requestWaitForSupportMessageAfterHalfSecond = useCallback(() => {
    setTimeout(async () => {
      await requestAutomaticMessage({
        messageType: AssistanceAutomaticMessageType.WAIT_FOR_SUPPORT,
      })
    }, 500)
  }, [requestAutomaticMessage])

  const requestAutomaticMessages = useCallback(async () => {
    const response =
      assistedChatUser?.type !== ChatUserType.VISITOR
        ? await ChatService.checkForAutomaticResponse({
            channelId: chatChannel!.id!,
          })
        : { automaticOfflineMessageSent: false }

    if (alreadyRequestedAllNonOfflineAutomaticMessages.current) {
      return
    }

    alreadyRequestedAllNonOfflineAutomaticMessages.current = true

    if (!response.automaticOfflineMessageSent) {
      await requestWaitForSupportMessageAfterHalfSecond()
    }
  }, [
    chatChannel,
    assistedChatUser?.type,
    requestWaitForSupportMessageAfterHalfSecond,
  ])

  const getAssignedAdminChatMember = useCallback(
    ({
      chatChannel,
    }: {
      chatChannel: ChannelType<DefaultGenerics>
    }): IChatUser | undefined => {
      const { assignedAdminUserId } =
        chatChannel.data as unknown as IAdminAdvisorStreamChatChannelData

      if (!assignedAdminUserId) {
        return undefined
      }

      const assignedAdminChatMember = Object.values(
        chatChannel.state.members
      ).filter((member) => member.user?.role === ChatUserRole.ADMIN)[0]

      if (!assignedAdminChatMember) {
        return undefined
      }

      const assignedAdminChatUser =
        assignedAdminChatMember.user! as unknown as IChatUser

      const {
        name: assignedAdminName,
        email: assignedAdminEmail,
        officialTitle: assignedAdminOfficialTitle,
      } = assignedAdminChatUser

      return {
        id: assignedAdminUserId,
        name: assignedAdminName ?? `${t('N/A')} - (${assignedAdminEmail})`,
        officialTitle: assignedAdminOfficialTitle,
        email: assignedAdminEmail,
        type: ChatUserType.ADMIN,
      }
    },
    [t]
  )

  const createAssistanceChatChannel = useCallback(
    ({ assistedChatUser }: { assistedChatUser: IChatUser }) =>
      streamChatClient.channel(StreamChatChannelType.ASSISTANCE, uuidv4(), {
        members: [assistedChatUser.id],
        name: `Assistance Chat - ${getSourcePageText({ sourcePage })}`,
        sourcePage,
        advisorSupport: true,
        assistedUserType: assistedChatUser.type,
        metadataUsername: assistedChatUser?.name,
        metadataCompanyName: assistedChatUser?.companyName,
        isReadyForSupport: false,
        isAdminAssigned: false,
        isArchived: false,
      }),
    [sourcePage]
  )

  const connectToChatChannel = useCallback(async () => {
    alreadyRequestedAllNonOfflineAutomaticMessages.current = false

    const channels = await streamChatClient.queryChannels(
      {
        type: StreamChatChannelType.ASSISTANCE,
        members: { $in: [assistedChatUser.id] },
        advisorSupport: true,
      },
      undefined
    )

    let assistanceChatChannel: ChannelType<DefaultGenerics>

    if (channels.length > 0) {
      assistanceChatChannel = channels[0]
    } else {
      assistanceChatChannel = createAssistanceChatChannel({
        assistedChatUser,
      })

      WindowEventUtils.dispatch({
        event: NEW_VISITOR_CHAT_CHANNEL_CREATED_WINDOW_EVENT,
        value: 1,
      })
    }

    await assistanceChatChannel.watch({
      presence: true,
    })

    assignedAdminRef.current = getAssignedAdminChatMember({
      chatChannel: assistanceChatChannel,
    })

    newMessageSub.current = assistanceChatChannel.on(
      'message.new',
      async (event) => {
        const newMessage: IChatMessage | undefined = event?.message
        const isAutomaticResponse = newMessage?.extra?.automaticResponse
        const isMyMessage = newMessage?.user?.id === assistedChatUser.id

        if (!newMessage || !isMyMessage || isAutomaticResponse) {
          return
        }

        const updateChannelMetadataFunctionParams = {
          channelId: assistanceChatChannel.id!,
          chatUserId: assistedChatUser.id!,
        }

        await ChatService.updateChannelLatestMessageMetadata(
          updateChannelMetadataFunctionParams
        )

        if (isMyMessage) {
          await ChatService.updateChannelSetUnreadStatusMetadata(
            updateChannelMetadataFunctionParams
          )
        }
      }
    )

    setChatChannel(assistanceChatChannel)
  }, [
    assistedChatUser,
    getAssignedAdminChatMember,
    createAssistanceChatChannel,
  ])

  const handleOnChatConnectionChanged = useCallback(
    (event: Event<DefaultGenerics>) => {
      if (event.online) {
        connectToChatChannel()
      }
    },
    [connectToChatChannel]
  )

  const subscribeToChatConnectionChangedEvents = useCallback(() => {
    subscribeToChatEvent({
      id: CONNECTION_CHANGED_EVENT_ID,
      eventName: 'connection.changed',
      callback: handleOnChatConnectionChanged,
    })
  }, [handleOnChatConnectionChanged, subscribeToChatEvent])

  const init = useCallback(async () => {
    setIsConnecting(true)

    try {
      await connectToChatChannel()
      subscribeToChatConnectionChangedEvents()
    } catch (err) {
      handleError({
        exception: err,
      })
      onClose()
    }

    setIsConnecting(false)
  }, [
    connectToChatChannel,
    handleError,
    onClose,
    subscribeToChatConnectionChangedEvents,
  ])

  useEffect(() => {
    if (!isConnectionOpen) {
      return
    }

    init()

    return () => {
      newMessageSub?.current?.unsubscribe?.()

      unsubscribeFromAllChatEvents({
        eventIds: [CONNECTION_CHANGED_EVENT_ID],
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnectionOpen])

  useEffect(() => {
    changePageTitle()

    return () => {
      restorePreviousPageTitle()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const handleIsOwnMessage = ({
    message,
  }: {
    message: StreamMessage<DefaultStreamChatGenerics>
  }) => message.user?.id === assistedChatUser.id

  const MemoedChatMessageInput = useCallback(() => {
    const isSendAttachmentSupportEnabled =
      assistedChatUser?.type !== ChatUserType.VISITOR

    return (
      <ChatMessageInput
        sendAttachmentSupport={isSendAttachmentSupportEnabled}
        onMessageSent={requestAutomaticMessages}
      />
    )
  }, [assistedChatUser?.type, requestAutomaticMessages])

  const getChatHeaderText = () => t('Assistance Chat')

  const handleOnOpenSideBarDrawer = () => {
    setIsSideBarDrawerOpen(true)
  }

  const isChatChannelConnected = useCallback(
    () => !!isConnectionOpen && !!chatChannel && !chatChannel.disconnected,
    [chatChannel, isConnectionOpen]
  )

  return (
    <Dialog
      data-testid={DIALOG_ID}
      id={DIALOG_ID}
      open={open}
      PaperProps={{
        sx: dialogPaperSx,
      }}
      onClose={handleOnDialogClose}
    >
      {open && !isChatChannelConnected() ? (
        <CircularProgress sx={circularProgressSx} />
      ) : (
        <ReactStreamChat
          client={streamChatClient}
          customClasses={{
            chat: 'custom-chat',
            channel: 'custom-channel',
          }}
        >
          <Channel
            channel={chatChannel}
            DateSeparator={DateSeparator}
            Message={() => <ChatMessage isOwnMessageFn={handleIsOwnMessage} />}
          >
            <Stack direction="row" sx={contentSx}>
              {showSideBar && (
                <SideBar
                  sideBarDrawerOpen={isSideBarDrawerOpen}
                  chatChannel={chatChannel}
                  assistedChatUser={assistedChatUser}
                  assignedAdmin={assignedAdminRef.current}
                  onCloseSideBarDrawer={() => setIsSideBarDrawerOpen(false)}
                />
              )}

              <Stack sx={chatWrapperSx}>
                <Header
                  showDrawerIconButton={showSideBar}
                  chatChannelHeaderText={getChatHeaderText()}
                  onOpenSideBarDrawer={handleOnOpenSideBarDrawer}
                  onDialogClose={handleOnDialogClose}
                />
                <Divider />

                <Stack data-testid="chat-content" sx={chatContentSx}>
                  <MessageList />
                  <MessageInput Input={MemoedChatMessageInput} />
                </Stack>
              </Stack>
            </Stack>
          </Channel>
        </ReactStreamChat>
      )}
    </Dialog>
  )
}

export { AssistedAdvisorChatDialog }
