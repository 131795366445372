import { useCallback, useEffect, useState } from 'react'

function usePageVisibility() {
  const [isPageVisible, setIsPageVisible] = useState(
    document.visibilityState === 'visible'
  )

  const handleOnVisibilityChange = useCallback(() => {
    setIsPageVisible(document.visibilityState === 'visible')
  }, [])

  useEffect(() => {
    document.addEventListener('visibilitychange', handleOnVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleOnVisibilityChange)
    }
  }, [handleOnVisibilityChange])

  return {
    visible: isPageVisible,
  }
}

export default {
  usePageVisibility,
}
