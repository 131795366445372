import { Outlet } from 'react-router-dom'

import { FeatureAvailabilityProvider } from '@contexts/features'

const FeatureAvailabilityProviderRoute = () => (
  <FeatureAvailabilityProvider>
    <Outlet />
  </FeatureAvailabilityProvider>
)

export { FeatureAvailabilityProviderRoute }
