import { FC } from 'react'

import { Breakpoint, CssBaseline, alpha, darken } from '@mui/material'
import '@mui/material/'
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@mui/material/styles'

export const breakpoints: Record<Breakpoint, number> = {
  xs: 0,
  sm: 430,
  md: 768,
  lg: 1280,
  xl: 1920,
  xxl: 2560,
}

const shadows = [
  ...createTheme({}).shadows.map((shadow, shadowIndex) => {
    const shadowNumbers: Record<number, string> = {
      1: '0 0.1rem 0.1rem rgba(0, 0, 0, 0.18)',
      2: '0 0.1rem 0.141rem rgba(0, 0, 0, 0.2)',
      3: '0 0.1rem 0.222rem rgba(0, 0, 0, 0.22)',
      4: '0 0.2rem 0.262rem rgba(0, 0, 0, 0.23)',
      5: '0 0.2rem 0.384rem rgba(0, 0, 0, 0.25)',
      6: '0 0.3rem 0.465rem rgba(0, 0, 0, 0.27)',
      7: '0 0.3rem 0.465rem rgba(0, 0, 0, 0.29)',
      8: '0 0.4rem 0.465rem rgba(0, 0, 0, 0.3)',
      9: '0 0.4rem 0.546rem rgba(0, 0, 0, 0.32)',
      10: '0 0.5rem 0.627rem rgba(0, 0, 0, 0.34)',
      11: '0 0.5rem 0.668rem rgba(0, 0, 0, 0.36)',
      12: '0 0.6rem 0.749rem rgba(0, 0, 0, 0.37)',
      13: '0 0.6rem 0.83rem rgba(0, 0, 0, 0.39)',
      14: '0 0.7rem 0.911rem rgba(0, 0, 0, 0.41)',
      15: '0 0.7rem 0.951rem rgba(0, 0, 0, 0.43)',
      16: '0 0.8rem 1.032rem rgba(0, 0, 0, 0.44)',
      17: '0 0.8rem 1.114rem rgba(0, 0, 0, 0.46)',
      18: '0 0.9rem 1.195rem rgba(0, 0, 0, 0.48)',
      19: '0 0.9rem 1.235rem rgba(0, 0, 0, 0.5)',
      20: '0 0.10rem 1.316rem rgba(0, 0, 0, 0.51)',
      21: '0 0.10rem 1.397rem rgba(0, 0, 0, 0.53)',
      22: '0 0.11rem 1.478rem rgba(0, 0, 0, 0.55)',
      23: '0 0.11rem 1.519rem rgba(0, 0, 0, 0.57)',
      24: '0 0.12rem 1.6rem rgba(0, 0, 0, 0.58)',
    }

    return shadowNumbers[shadowIndex] || shadow
  }),
]

let theme = createTheme({
  typography: {
    fontFamily:
      'DM Sans, source-code-pro, Menlo, Monaco, Consolas, "Couriea New"',
    htmlFontSize: 10,
    h1: {
      fontSize: '4.8rem',
    },
    h2: {
      fontWeight: 700,
      fontSize: '6rem',
      lineHeight: '7.1rem',
      letterSpacing: '-0.05rem',
    },
    h3: {
      fontWeight: 700,
      fontSize: '4.8rem',
      lineHeight: '5.7rem',
      letterSpacing: '0.1rem',
    },

    h4: {
      fontWeight: 700,
      fontSize: '3.4rem',
      lineHeight: '4rem',
      letterSpacing: '0.025rem',
    },
    h5: {
      fontWeight: 500,
      fontSize: '2.4rem',
      lineHeight: '3.2rem',
      letterSpacing: 0,
    },
    h6: {
      fontWeight: 500,
      fontSize: '2rem',
      lineHeight: '2.8rem',
      letterSpacing: '0.015rem',
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
      letterSpacing: '0.015rem',
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: '1.4rem',
      lineHeight: '2.4rem',
      letterSpacing: '0.01rem',
    },
    body1: {
      fontWeight: 400,
      fontSize: '1.6rem',
      lineHeight: '2.8rem',
      letterSpacing: '0.044rem',
    },
    body2: {
      fontWeight: 400,
      fontSize: '1.4rem',
      lineHeight: '2rem',
      letterSpacing: '0.025rem',
    },
    caption: {
      fontWeight: 400,
      fontSize: '1.2rem',
      lineHeight: '1.6rem',
      letterSpacing: '0.04rem',
    },
    badge: {
      fontWeight: 700,
      fontSize: '0.8rem',
      lineHeight: '1.42rem',
    },
    overline: {
      fontWeight: 500,
      fontSize: '1.4rem',
      lineHeight: '2.4rem',
      letterSpacing: '0.1rem',
      textTransform: 'uppercase',
    },
    allVariants: {
      fontWeight: 500,
    },
  },
  palette: {
    text: {
      primary: '#222222',
      secondary: '#212121B3',
      disabled: alpha('#222222', 0.3),
    },
    primary: {
      main: '#184A9B',
      light: '#2B89C5',
      contrastText: '#FFFFFF',
    },
    primaryLight: {
      main: '#2B89C5',
      light: '#64B5F6',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#D5A83C',
      light: '#FFD96C',
      dark: '#A07900',
      contrastText: '#FFFFFF',
    },
    background: {
      default: '#F5F6F8',
      paper: '#FFFFFF',
      disabled: alpha('#000000', 0.12),
    },
    success: {
      main: '#4CAF50',
      contrastText: '#FFFFFF',
      light: '#81C784',
    },
    info: {
      main: '#37445F',
      light: '#64B5F6',
      dark: '#1976D2',
    },
    light: {
      main: '#FFFFFF',
      dark: darken('#FFFFFF', 0.1),
      contrastText: '#184A9B',
    },
    error: {
      main: '#DB0028',
      light: '#F83B3A',
    },
    warning: {
      main: '#EF6C00',
      light: '#FF9800',
      dark: '#E65100',
      contrastText: '#FFFFFF',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
  },
  breakpoints: {
    values: breakpoints,
  },
})

theme = createTheme(theme, {
  components: {
    MuiDialog: {
      defaultProps: {
        maxWidth: 'xl',
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: '0.4rem',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label': {
            fontWeight: 500,
            fontSize: '1.6rem',
            letterSpacing: '0.015rem',
          },
          '& input': {
            fontWeight: 500,
            letterSpacing: '0.015rem',
            paddingLeft: '1.7rem',
          },
          '& textarea': {
            fontWeight: 500,
            letterSpacing: '0.015rem',
            paddingLeft: '0.6rem',
          },
        },
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            '& > div': {
              minHeight: '4.8rem',
            },
          },
        },
        {
          props: { size: 'small', variant: 'outlined' },
          style: {
            '& label': {
              ':not([data-shrink=true])': {
                transform: 'translate(1.7rem, 1.2rem) scale(1)',
              },
            },
          },
        },
        {
          props: { size: 'small', variant: 'filled' },
          style: {
            '& label': {
              transform: 'translate(1.7rem, 0.4rem) scale(0.75)',
              ':not([data-shrink=true])': {
                transform: 'translate(1.7rem, 1.2rem) scale(1)',
              },
            },
          },
        },
        {
          props: { size: 'medium', variant: 'outlined' },
          style: {
            '& label': {
              ':not([data-shrink=true])': {
                transform: 'translate(1.7rem, 1.6rem) scale(1)',
              },
            },
          },
        },
        {
          props: { size: 'medium', variant: 'filled' },
          style: {
            '& label': {
              transform: 'translate(1.7rem, 0.4rem) scale(0.75)',
              ':not([data-shrink=true])': {
                transform: 'translate(1.7rem, 1.6rem) scale(1)',
              },
            },
          },
        },
        {
          props: { multiline: true },
          style: {
            '& textarea': {
              lineHeight: '2rem !important',
            },
            '& div': {
              paddingBottom: '0.5rem !important',
            },
          },
        },
      ],
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': { width: '80%' },
          '& input': { paddingLeft: '0.8rem' },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          position: 'relative',
          '&.Mui-checked': {
            color: theme.palette.primary.light,
          },
          '& .MuiTouchRipple-root': {
            width: '200%',
            height: '200%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 'max-content',
          letterSpacing: '0.046rem',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          height: '4.8rem',
          color: theme.palette.primary.main,
          '& p': {
            letterSpacing: '0.025rem',
          },
        },
        button: {
          borderRadius: '0.8rem',
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: '0.8rem',
          '& p': {
            letterSpacing: '0.025rem',
          },
          '& .MuiSvgIcon-root[data-testid="ExpandLessIcon"], & .MuiSvgIcon-root[data-testid="ExpandMoreIcon"]':
            {
              color: theme.palette.text.secondary,
              opacity: 0.5,
            },
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
          },
          '.MuiTouchRipple-child': {
            backgroundColor: '#184A9B',
          },
        },
        button: {
          borderRadius: '0.8rem',
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: 400,
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          margin: '0 0.5rem',
          letterSpacing: '0.015rem',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: '0.6rem 0',
          paddingBottom: '1rem',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1.5rem',
          lineHeight: '2rem',
          fontWeight: 400,
          letterSpacing: '0.025rem',
          maxHeight: '35rem',
          overflowY: 'auto',
          overflowX: 'hidden',
          '&::-webkit-scrollbar': {
            width: '0.8rem',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(0, 0, 0, 0.2)',
            borderRadius: '0.8rem',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: 'rgba(0, 0, 0, 0.4)',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          height: '5.2rem',
          letterSpacing: '0.044rem',
          '.MuiListItemIcon-root': {
            minWidth: '4.4rem',
            svg: {
              fontSize: '2.4rem',
            },
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          transition: 'none',
        },
      },
    },
  },
  shadows,
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: '0.8rem',
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        '& > :not(:first-of-type)': {
          marginLeft: '0.7rem',
        },
      },
    },
  },
})

const ThemeProvider: FC = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </MuiThemeProvider>
)

export { ThemeProvider, theme }
