import * as Sentry from '@sentry/react'

import EnvironmentUtils from '@utils/environments'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [
    new Sentry.Replay({
      minReplayDuration: 1_000,
      flushMinDelay: 3_000,
      flushMaxDelay: 3_000,
      maskAllText: false,
      networkDetailAllowUrls: [
        /^https:\/\/api\.technologymatch\.com\/api/,
        /^https:\/\/chat\.stream-io-api\.com/,
        /^https:\/\/api\.hubapi\.com/,
      ],
    }),
  ],
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  release: `${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_SENTRY_ENVIRONMENT}`,
  enabled: (process.env.REACT_APP_SENTRY_ENVIRONMENT ?? 'local') !== 'local',
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: EnvironmentUtils.isProduction() ? 1.0 : 0.0,
})
