export type DataHandler = (parsedData: any) => void

const readStream = async ({
  reader,
  decoder,
  handleData,
  onComplete,
}: {
  reader: ReadableStreamDefaultReader
  decoder: TextDecoder
  handleData: DataHandler
  onComplete?: () => void
}) => {
  let partialData = ''

  for (;;) {
    const { done, value } = await reader.read()
    if (done) {
      onComplete?.()
      break
    }

    partialData += decoder.decode(value, { stream: true })
    const lines = partialData.split('\n')
    partialData = lines.pop() || ''

    for (const line of lines) {
      if (line.startsWith('data: ')) {
        const jsonData = line.substring(6).trim()
        const parsedData = JSON.parse(jsonData)
        handleData(parsedData)
      }
    }
  }
}

export default {
  readStream,
}
