import { SxProps, Theme, alpha } from '@mui/material'

import { getChatStatusTextColor } from '@utils/chats'
import type { ChatStatus } from '@utils/types'

export const buyerNameAndStatusWrapperSx: SxProps<Theme> = {
  gap: '1rem',
  justifyContent: 'center',
}

export const adminOfficialTitleSx: SxProps<Theme> = {
  color: (theme) => theme.palette.text.secondary,
}

export const containerSx: SxProps<Theme> = {
  maxWidth: '38.4rem',
  flex: 1,
  borderRight: `0.1rem solid ${alpha('#000000', 0.12)}`,
  backgroundColor: (theme) => theme.palette.background.default,
}

export const buyerWrapperSx: SxProps<Theme> = {
  padding: '2.4rem',
  gap: '2rem',
}

export const buyerAvatarSx: SxProps<Theme> = { width: '6rem', height: '6rem' }

export const buyerInfoWrapperSx: SxProps<Theme> = { gap: '1.6rem' }

export const channelsListSx: SxProps<Theme> = {
  listStyleType: 'none',
  padding: 0,
  margin: 0,
}

export const menuOptionsSx: SxProps<Theme> = {
  marginLeft: 'auto',
  alignSelf: 'start',
  marginTop: '-0.5rem',
  marginRight: '-0.8rem',
}

export const archiveDialogContainerSx: SxProps<Theme> = {
  width: '100%',
  maxWidth: '44.6rem',
  padding: '2.4rem',
}

export const onlineStatusTextSx = ({
  chatStatus,
}: {
  chatStatus: ChatStatus
}): SxProps<Theme> => {
  return {
    alignSelf: 'end',
    color: getChatStatusTextColor({ chatStatus }),
  }
}

export const buyerNameAndOnlineStatusWrapperSx: SxProps<Theme> = {
  alignItems: 'center',
  gap: '1rem',
}

export const companyChatChannelHeaderSx: SxProps<Theme> = {
  padding: '2.4rem 2.4rem 0.4rem',
}

export const chatChannelHeaderTitleSx: SxProps<Theme> = {
  lineHeight: '2.4rem',
  color: (theme) => theme.palette.text.secondary,
}
