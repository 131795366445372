import authHook from '@hooks/useAuth'

const useAuthenticatedUserPathPrefix = () => {
  const { isAdmin, isBuyer, isVendor, isFetchingUserData } = authHook.useAuth()

  let pathPrefix: string | undefined

  if (isAdmin()) {
    pathPrefix = '/admin'
  } else if (isBuyer()) {
    pathPrefix = '/buyer'
  } else if (isVendor()) {
    pathPrefix = '/vendor'
  }
  return {
    pathPrefix,
    isFetchingUserData,
  }
}

export default {
  useAuthenticatedUserPathPrefix,
}
