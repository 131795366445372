import { SxProps, Theme } from '@mui/material'

export const betaTagSx: SxProps<Theme> = {
  background: '#FFECB3',
  // TODO LG-2573 - we'll refactor this to use values from the palette in this ticket
  padding: '0rem 0.65rem',
  borderRadius: '10rem',
  fontSize: '1.2rem',
  lineHeight: '2rem',
  letterSpacing: '0.014rem',
  fontWeight: '500',
  color: '#663C00',
  // TODO LG-2573 - we'll refactor this to use values from the palette in this ticket
  height: '2rem',
}
