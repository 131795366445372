import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

import { Box, Link, Stack, Typography } from '@mui/material'

import { DefaultGenerics } from 'stream-chat'
import {
  Attachment,
  MessageContextValue,
  StreamMessage,
  useChannelStateContext,
  useMessageContext,
} from 'stream-chat-react'
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types'

import {
  DEFAULT_ADMIN_SUPPORT_NAME,
  THE_LAUNCHPAD_USER_NAME,
} from '@utils/chats'
import DateUtils from '@utils/dates'
import type { IChatMessageExtra, IChatUserExtra } from '@utils/types'

import {
  attachmentWrapperSx,
  messageSenderSx,
  messageSx,
  messageTimeSx,
  messageTlgSenderSx,
  ownMessageWrapperSx,
  userNameWrapperSx,
  vendorCompanyNameSx,
  vendorMessageWrapperSx,
} from './styles'

import { AttachmentFile } from '../AttachmentFile'
import { ImpersonationWarning } from './components/ImpersonationWarning'

type IChatMessageProps = Partial<MessageContextValue<DefaultGenerics>> & {
  showNameInsteadOfYou?: boolean
  isOwnMessageFn: (data: {
    message: StreamMessage<DefaultStreamChatGenerics>
  }) => boolean
}

const ChatMessageLink = ({
  children,
  href,
}: {
  children: React.ReactNode
  href?: string
}) => (
  <Link target="_blank" href={href}>
    {children}
  </Link>
)

const ChatMessage = ({
  showNameInsteadOfYou,
  isOwnMessageFn,
}: IChatMessageProps) => {
  const { channel } = useChannelStateContext()
  const { message } = useMessageContext()
  const { t } = useTranslation()

  const userExtras = message.user?.extra as IChatUserExtra | undefined
  const messageExtras = message.extra as IChatMessageExtra

  const isAutomaticResponse = !!messageExtras?.automaticResponse
  const isAutomaticOfflineResponse = !!messageExtras?.automaticOfflineResponse
  const isImpersonation = !!messageExtras?.impersonatedBy

  const companyName = userExtras?.company?.name ?? ''
  const isTlgAutomaticMessage =
    messageExtras?.sentFromTlg && isAutomaticResponse

  const hasAttachments = !!message.attachments?.length && message.attachments

  const isOwnMessage = isOwnMessageFn({ message })

  let messageSender: string

  if (isTlgAutomaticMessage) {
    messageSender = THE_LAUNCHPAD_USER_NAME
  } else if (isOwnMessage && !showNameInsteadOfYou) {
    messageSender = t('You')
  } else {
    const userName = message.user?.name?.trim()
    messageSender = userName?.length ? userName : DEFAULT_ADMIN_SUPPORT_NAME
  }

  let containerSx

  if (isOwnMessage) {
    containerSx = ownMessageWrapperSx
  } else {
    containerSx = vendorMessageWrapperSx
  }

  const currentMessageSenderSx = isTlgAutomaticMessage
    ? messageTlgSenderSx
    : messageSenderSx

  const messageText =
    isAutomaticResponse && message.text ? t(message.text) : message.text

  return (
    <Stack
      data-testid="message-container"
      sx={containerSx({
        channelDisabled: channel.data?.frozen ?? false,
      })}
    >
      <Stack direction="row" sx={userNameWrapperSx}>
        <Typography
          data-testid="message-sender"
          variant={isTlgAutomaticMessage ? 'body2' : 'subtitle1'}
          sx={currentMessageSenderSx}
        >
          {messageSender}
        </Typography>

        {!isOwnMessage && !!companyName && (
          <Typography
            data-testid="vendor-company-name"
            variant="body2"
            sx={vendorCompanyNameSx}
          >
            {`• ${companyName}`}
          </Typography>
        )}

        {isImpersonation && <ImpersonationWarning />}
      </Stack>

      {!!messageText && (
        <Typography
          variant="body2"
          sx={messageSx({
            isAutomaticOfflineResponse,
          })}
        >
          <ReactMarkdown
            components={{
              a: ChatMessageLink,
            }}
          >
            {messageText}
          </ReactMarkdown>
        </Typography>
      )}

      {hasAttachments && !!message.attachments?.length && (
        <Box sx={attachmentWrapperSx}>
          <Attachment attachments={message.attachments} File={AttachmentFile} />
        </Box>
      )}

      {!!message.created_at && (
        <Typography variant="caption" sx={messageTimeSx}>
          {DateUtils.formatTo(message.created_at as Date, 'hh:mm aaa')}
        </Typography>
      )}
    </Stack>
  )
}

export { ChatMessage }
