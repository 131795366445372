import { SxProps, Theme, alpha } from '@mui/material'

export const assistantMessageWrapperSx: SxProps<Theme> = {
  display: 'flex',
  gap: '2.4rem',
  flexDirection: 'column',
  alignItems: 'start',
}

export const assistantMessageIconSx: SxProps<Theme> = {
  width: '4rem',
  height: '3.3rem',
}

export const assistantMessageSx: SxProps<Theme> = {
  borderRadius: '1.2rem',
  border: (theme) => `0.1rem solid ${theme.palette.divider}`,
  padding: '1.2rem 1.6rem',
  overflowWrap: 'anywhere',
  whiteSpace: 'pre-wrap',
  '& .markdown > :first-of-type': {
    marginTop: 0,
  },
}

export const conversationBoxSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: '2.4rem',
  padding: {
    xs: '0 1.6rem',
    md: '0 3.2rem',
  },
}

export const userMessageSx: SxProps<Theme> = {
  // TODO LG-2573 - we'll refactor this to use values from the palette in this ticket
  background: '#E8EDF5',
  borderRadius: '1.2rem',
  padding: '1.2rem 1.6rem',
  overflowWrap: 'anywhere',
  whiteSpace: 'pre-wrap',
}

export const reportButtonSx = ({
  isReported,
}: {
  isReported?: boolean
}): SxProps<Theme> => {
  let props = {} as SxProps<Theme>

  if (isReported) {
    props = {
      color: (theme) => theme.palette.text.disabled,
      cursor: 'initial',
    }
  } else {
    props = {
      color: (theme) => alpha(theme.palette.text.primary, 0.5),
      cursor: 'pointer',
      '&:hover': {
        color: (theme) => theme.palette.error.main,
      },
    }
  }

  return {
    display: 'flex',
    alignItems: 'end',
    gap: '0.4rem',
    background: 'none',
    border: 'none',
    marginLeft: 'auto',
    padding: '0.4rem 0.5rem',
    ...props,
  }
}

export const reportButtonIconSx: SxProps<Theme> = {
  width: '1.8rem',
  height: '1.8rem',
}

export const assistantMessageTextSx: SxProps<Theme> = {
  display: 'flex',
  gap: '1rem',
}

export const errorContainerSx: SxProps<Theme> = {
  borderRadius: '1.2rem',
  padding: '1.6rem',
  display: 'flex',
  flexDirection: 'column',
  border: '0.1rem solid rgba(211, 47, 47, 0.50)',
  color: '#5F2120',
  marginBottom: '2.4rem',
}

export const tryAgainButtonSx: SxProps<Theme> = {
  display: 'flex',
  width: 'fit-content',
  marginTop: '1.6rem',
}

export const errorSubtitleSx: SxProps<Theme> = { marginTop: '0.4rem' }
