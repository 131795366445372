import { useCallback, useEffect, useRef } from 'react'

const useTitle = (title: string) => {
  const initialPageTitle = useRef('')

  const changePageTitle = useCallback(
    (newTitle?: string) => {
      document.title = newTitle ?? title

      return () => {
        document.title = initialPageTitle.current
      }
    },
    [title]
  )

  const restorePreviousPageTitle = () => {
    document.title = initialPageTitle.current
  }

  useEffect(() => {
    initialPageTitle.current = document.title

    // hack to make page title to work when refreshing the page
    setTimeout(() => {
      changePageTitle(title)
    }, 1)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    changePageTitle,
    restorePreviousPageTitle,
  }
}

export default {
  useTitle,
}
