import {
  AvatarProps,
  Box,
  Avatar as MuiAvatar,
  SxProps,
  Theme,
} from '@mui/material'

import { DEFAULT_AVATAR } from '@utils/images'

import { avatarStyleSx, defaultAvatarStyleSx } from './styles'

export enum AvatarSize {
  MICRO = 'micro',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export interface IAvatarProps extends AvatarProps {
  size?: AvatarSize
  defaultImgSrc?: string
  wrapperSx?: SxProps<Theme>
}

const Avatar = ({
  src,
  size,
  defaultImgSrc = DEFAULT_AVATAR,
  wrapperSx,
  sx,
  ...rest
}: IAvatarProps) => (
  <Box sx={wrapperSx}>
    <MuiAvatar
      src={src}
      sx={{ ...avatarStyleSx({ size }), ...sx } as SxProps<Theme>}
      {...rest}
    >
      <MuiAvatar
        src={defaultImgSrc}
        sx={{ ...defaultAvatarStyleSx, ...sx } as SxProps<Theme>}
        {...rest}
      />
    </MuiAvatar>
  </Box>
)

export { Avatar }
