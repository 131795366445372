import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Channel as ChannelType, DefaultGenerics } from 'stream-chat'

import { streamChatClient } from '@libs/stream-chat'

import chatHook from '@hooks/useChat'
import { useLocalStorage } from '@hooks/useLocalStorage'

import { withStreamChatManagedConnector } from '@HOCs/withStreamChatManagedConnector'

import ChatService from '@services/chat'

import { AssistanceChatBubble } from '@components/AssistanceChatBubble'
import {
  DIALOG_ID as ASSISTED_ADVISOR_CHAT_DIALOG_ID,
  AssistedAdvisorChatDialog,
} from '@components/AssistedAdvisorChatDialog'
import { StreamChatAnonymousConnection } from '@components/StreamChatAnonymousConnection'
import { VisitorInfoDialog } from '@components/VisitorInfoDialog'

import { LOCAL_STORAGE_CHAT_VISITOR_INFO_KEY } from '@utils/local-storage'
import {
  AssistanceChatSourcePage,
  ChatUserType,
  IVisitorChatInfo,
  StreamChatChannelType,
} from '@utils/types'
import {
  NEW_VISITOR_CHAT_CHANNEL_CREATED_WINDOW_EVENT,
  NEW_VISITOR_CHAT_DATA_WINDOW_EVENT,
} from '@utils/window-events'

interface ISubscription {
  unsubscribe: () => void
}

interface IAdvisorConnectAssistanceChatBubble {
  sourcePage: AssistanceChatSourcePage
  waitTime?: number
  connectToChat?: () => Promise<void>
}

const AdvisorConnectAssistanceChatBubble = ({
  sourcePage,
  waitTime,
}: IAdvisorConnectAssistanceChatBubble) => {
  const { t } = useTranslation()
  const { isConnectionOpen } = chatHook.useChat()

  const [chatChannel, setChatChannel] = useState<ChannelType<DefaultGenerics>>()
  const [newMessageCounter, setNewMessageCounter] = useState(0)
  const [isAssistedAdvisorChatDialogOpen, setIsAssistedAdvisorChatDialogOpen] =
    useState(false)
  const [isRequestsVisitorInfoDialogOpen, setIsRequestsVisitorInfoDialogOpen] =
    useState(false)

  const {
    getValue: getLocalStorageChatVisitorInfo,
    setValue: setLocalStorageChatVisitorData,
  } = useLocalStorage<IVisitorChatInfo | undefined>(
    LOCAL_STORAGE_CHAT_VISITOR_INFO_KEY,
    undefined
  )

  const subscriptions = useRef<ISubscription[]>([])

  const connectionOpenRef = useRef(false)

  connectionOpenRef.current = isConnectionOpen

  const getValidVisitorInfo = useCallback(() => {
    const visitorInfo = getLocalStorageChatVisitorInfo()

    if (!visitorInfo?.token || !visitorInfo?.visitorName) {
      return undefined
    }

    return visitorInfo
  }, [getLocalStorageChatVisitorInfo])

  const handleOnConnectWithAdvisorInteract = useCallback(() => {
    const visitorInfo = getValidVisitorInfo()

    if (!visitorInfo) {
      setIsRequestsVisitorInfoDialogOpen(true)
      return
    }

    setIsAssistedAdvisorChatDialogOpen(true)
  }, [getValidVisitorInfo])

  const handleOnRequestVisitorInfoDialogContinue = async () => {
    setIsRequestsVisitorInfoDialogOpen(false)

    const visitorInfo = getLocalStorageChatVisitorInfo()!
    if (!visitorInfo.token) {
      const { chatUserId, token } = await ChatService.getChatVisitorToken()

      visitorInfo.id = chatUserId
      visitorInfo.token = token

      setLocalStorageChatVisitorData(visitorInfo, {
        customWindowEvent: NEW_VISITOR_CHAT_DATA_WINDOW_EVENT,
      })
    }

    setIsAssistedAdvisorChatDialogOpen(true)
  }

  const handleOnCloseVisitorChatDialog = () => {
    setIsAssistedAdvisorChatDialogOpen(false)
  }

  const localStorageChatVisitorInfo = getLocalStorageChatVisitorInfo()

  const setNewMessageCount = useCallback(() => {
    setNewMessageCounter(chatChannel?.countUnread() ?? 0)
  }, [chatChannel])

  const unsubscribeFromAllSubscriptions = () => {
    subscriptions.current.forEach((subscription) =>
      subscription?.unsubscribe?.()
    )
  }

  const queryVisitorChatChannel = useCallback(async () => {
    const visitorInfo = getValidVisitorInfo()

    if (!visitorInfo?.id) {
      return
    }

    const newChatChannel = (
      await streamChatClient.queryChannels(
        {
          type: StreamChatChannelType.ASSISTANCE,
          members: { $in: [visitorInfo.id] },
          advisorSupport: true,
        },
        {},
        { limit: 1 }
      )
    )[0]

    setChatChannel(newChatChannel)
  }, [getValidVisitorInfo])

  const connectToChatChannel = useCallback(() => {
    if (!connectionOpenRef.current) {
      return
    }

    queryVisitorChatChannel()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnectionOpen])

  useEffect(() => {
    const refreshConnection = () => {
      if (!chatChannel) {
        return
      }

      unsubscribeFromAllSubscriptions()

      let newChatChannel: ChannelType<DefaultGenerics> | undefined

      const chatChannelUpdate = newChatChannel ?? chatChannel

      subscriptions.current = [
        chatChannelUpdate.on('message.new', () => {
          const assistedAdvisorChatDialogElement = document.getElementById(
            ASSISTED_ADVISOR_CHAT_DIALOG_ID
          )

          const isAssistedAdvisorChatDialogElementInDom =
            !!assistedAdvisorChatDialogElement

          if (!isAssistedAdvisorChatDialogElementInDom) {
            setNewMessageCount()
          }
        }),

        chatChannelUpdate.on('message.read', () => setNewMessageCount()),
      ]

      setNewMessageCount()
    }

    if (!connectionOpenRef.current) {
      return
    }

    refreshConnection()
  }, [connectionOpenRef, chatChannel, setNewMessageCount])

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (chatChannel) {
        unsubscribeFromAllSubscriptions()
        chatChannel?.stopWatching()
      }
    }

    window.addEventListener('pagehide', handleBeforeUnload)
    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      handleBeforeUnload()

      window.addEventListener('pagehide', handleBeforeUnload)
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    connectToChatChannel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectToChatChannel])

  useEffect(() => {
    const handleOnNewVisitorChatChannelWindowEvent = (_: CustomEvent) => {
      if (!connectionOpenRef.current) {
        return
      }

      const TIMEOUT_TO_MAKE_SURE_NEW_CHAT_CHANNEL_WAS_CREATED_AND_IS_QUERYABLE = 2_000

      setTimeout(() => {
        queryVisitorChatChannel()
      }, TIMEOUT_TO_MAKE_SURE_NEW_CHAT_CHANNEL_WAS_CREATED_AND_IS_QUERYABLE)
    }

    window.addEventListener(
      NEW_VISITOR_CHAT_CHANNEL_CREATED_WINDOW_EVENT,
      handleOnNewVisitorChatChannelWindowEvent
    )
    return () => {
      window.removeEventListener(
        NEW_VISITOR_CHAT_CHANNEL_CREATED_WINDOW_EVENT,
        handleOnNewVisitorChatChannelWindowEvent
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isRequestsVisitorInfoDialogOpen && (
        <VisitorInfoDialog
          open={isRequestsVisitorInfoDialogOpen}
          onCancel={() => setIsRequestsVisitorInfoDialogOpen(false)}
          onContinue={handleOnRequestVisitorInfoDialogContinue}
        />
      )}

      {isAssistedAdvisorChatDialogOpen && (
        <AssistedAdvisorChatDialog
          open={isAssistedAdvisorChatDialogOpen}
          showSideBar={false}
          sourcePage={sourcePage}
          assistedChatUser={{
            id: localStorageChatVisitorInfo!.id!,
            name: localStorageChatVisitorInfo!.visitorName!,
            companyName: localStorageChatVisitorInfo!.companyName,
            token: localStorageChatVisitorInfo!.token!,
            type: ChatUserType.VISITOR,
          }}
          onClose={handleOnCloseVisitorChatDialog}
        />
      )}

      <AssistanceChatBubble
        text={t('Hey there! How can I help?')}
        onInteract={handleOnConnectWithAdvisorInteract}
        waitTime={waitTime}
        badgeValue={newMessageCounter}
      />
    </>
  )
}

const AdvisorConnectAssistanceChatBubbleWithStreamChatManagedConnection =
  withStreamChatManagedConnector(
    AdvisorConnectAssistanceChatBubble,
    StreamChatAnonymousConnection,
    {
      authenticated: false,
    }
  )

export { AdvisorConnectAssistanceChatBubbleWithStreamChatManagedConnection as AdvisorConnectAssistanceChatBubble }
