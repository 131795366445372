import { SxProps, Theme } from '@mui/material'

export const containerSx: SxProps<Theme> = {
  width: '31.8rem',
  borderRadius: '0.8rem',
}

export const titleSx: SxProps<Theme> = {
  padding: '1.6rem 2.4rem',
  letterSpacing: '0.015rem',
  overflowWrap: 'anywhere',
}

export const contentSx: SxProps<Theme> = {
  display: 'flex',
  padding: '0rem 2.4rem 2rem 2.4rem',
}

export const contentTextSx: SxProps<Theme> = {
  display: 'flex',
  fontWeight: 400,
  letterSpacing: '0.044rem',
}

export const buttonSx: SxProps<Theme> = {
  lineHeight: '1.6rem',
  padding: '1rem 1.2rem',
  letterSpacing: '0.125rem',
}
