import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import navigationHook from '@hooks/useNavigation'

export interface IUseDialogParams {
  initialState?: boolean
  dialogId?: string
  params?: string[]
}

interface IParams {
  params?: Record<string, string>
}

const useDialog = ({
  initialState = false,
  dialogId,
  params,
}: IUseDialogParams = {}) => {
  const { changeUrlSearchParams } = navigationHook.useNavigation()
  const [searchParams] = useSearchParams()
  const [isOpen, setIsOpen] = useState(initialState)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [allParams, setAllParams] = useState<Record<string, string>>({})
  const [dialogParams, setDialogParams] = useState<Record<string, string>>({})

  const updateSearchParams = useCallback(
    ({
      open,
      paramsToSet = {},
    }: {
      open: boolean
      paramsToSet: Record<string, string>
    }) => {
      if (dialogId) {
        const newSearchParams = new URLSearchParams(searchParams.toString())

        if (open) {
          newSearchParams.set(dialogId, 'open')
          Object.entries(paramsToSet).forEach(([key, value]) => {
            newSearchParams.set(key, value)
          })
        } else {
          newSearchParams.delete(dialogId)
          Object.keys(dialogParams).forEach((param) => {
            newSearchParams.delete(param)
          })
        }

        if (newSearchParams.toString() !== searchParams.toString()) {
          changeUrlSearchParams({ params: newSearchParams, replace: true })
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams, dialogParams]
  )

  useEffect(() => {
    if (dialogId) {
      const isOpenThroughSearchParams = searchParams.get(dialogId) === 'open'
      setIsOpen(isOpenThroughSearchParams)

      if (isOpenThroughSearchParams) {
        const initialParams: Record<string, string> = {}
        params?.forEach((param) => {
          const value = searchParams.get(param)
          if (value) {
            initialParams[param] = value
          }
        })
        setAllParams(initialParams)
        setDialogParams(initialParams)
      }

      setIsFirstRender(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  useEffect(() => {
    if (!isFirstRender) {
      updateSearchParams({ open: isOpen, paramsToSet: allParams })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstRender])

  const open = useCallback(
    ({ params }: IParams | any = {}) => {
      const newParams = { ...allParams, ...params }
      setAllParams(newParams)
      setDialogParams(params)
      setIsOpen(true)
      updateSearchParams({ open: true, paramsToSet: newParams })
    },
    [allParams, updateSearchParams]
  )

  const close = useCallback(() => {
    setAllParams({})
    setIsOpen(false)
    setTimeout(() => {
      updateSearchParams({
        open: false,
        paramsToSet: {},
      })
      setDialogParams({})
    }, 300)
  }, [updateSearchParams])

  return { isOpen, open, close, allParams }
}

export default { useDialog }
