import {
  Breakpoint,
  TypographyProps,
  useMediaQuery,
  useTheme,
} from '@mui/material'

const useResponsive = () => {
  const theme = useTheme()
  const breakpoints: readonly Breakpoint[] = [
    ...theme.breakpoints.keys,
  ].reverse()

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('lg'))
  const isTablet = useMediaQuery(theme.breakpoints.only('md'))
  const isTabletOrBigger = useMediaQuery(theme.breakpoints.up('md'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const ifMobile = (data: any) => {
    if (!isMobile) {
      return undefined
    }

    return data
  }

  const ifDesktop = (data: any) => {
    if (!isDesktop) {
      return undefined
    }

    return data
  }

  const findResponsiveVariant = ({
    responsiveVariant,
    currentBreakpoint,
  }: {
    responsiveVariant: Partial<Record<Breakpoint, TypographyProps['variant']>>
    currentBreakpoint: Breakpoint
  }): TypographyProps['variant'] | undefined => {
    const breakpointOrder: Breakpoint[] = ['xl', 'lg', 'md', 'sm', 'xs']

    const currentIndex = breakpointOrder.indexOf(currentBreakpoint)

    for (let i = currentIndex; i < breakpointOrder.length; i++) {
      const breakpoint = breakpointOrder[i]
      if (responsiveVariant[breakpoint]) {
        return responsiveVariant[breakpoint]
      }
    }
  }

  const getNearestBreakpoint = (obj: { [key: string]: any }): Breakpoint =>
    breakpoints.reduce((output: Breakpoint | null, key: Breakpoint) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key))
      return !output && key in obj && matches ? key : output
    }, null) || 'xs'

  const getCurrentBreakpoint = () => {
    const currentBreakpoint: Breakpoint =
      breakpoints.reduce((output: Breakpoint | null, key: Breakpoint) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const matches = useMediaQuery(theme.breakpoints.up(key))
        return !output && matches ? key : output
      }, null) || 'xs'

    return currentBreakpoint
  }

  return {
    isMobile,
    isMobileOrTablet,
    isTablet,
    isTabletOrBigger,
    isDesktop,
    ifMobile,
    ifDesktop,
    getNearestBreakpoint,
    getCurrentBreakpoint,
    findResponsiveVariant,
  }
}

export default {
  useResponsive,
}
