import { ChangeEvent } from 'react'

import { FormikProps } from 'formik'

export const convertFormDataToObject = (
  formData: FormData
): Record<string, unknown> => {
  const additionalObjectsMap = new Map()

  const result = Array.from(formData.entries()).reduce((acc, curr) => {
    const KEY_INDEX = 0
    const VALUE_INDEX = 1

    const isArray =
      curr[KEY_INDEX].includes('[') && curr[KEY_INDEX].includes(']')

    if (isArray) {
      const splittedArray = curr[KEY_INDEX].split('[')

      const isObject = splittedArray.length > 2

      const entry = curr[KEY_INDEX].split('[')[0]
      const objectValue = curr[VALUE_INDEX]

      if (isObject) {
        const isArrayOfArray = splittedArray.length > 3

        const objectIndex = splittedArray[1].split(']')[0]
        const objectEntry = splittedArray[2].split(']')[0]

        const mapKey = `${entry}_${objectIndex}`

        let previousValues = undefined

        if (isArrayOfArray && additionalObjectsMap.get(mapKey)?.[objectEntry]) {
          previousValues = additionalObjectsMap.get(mapKey)[objectEntry]
        }

        let value: any = undefined

        if (isArrayOfArray && previousValues) {
          value = additionalObjectsMap.has(mapKey)
            ? {
                ...additionalObjectsMap.get(mapKey),
                [objectEntry]: [...previousValues, objectValue],
              }
            : { [objectEntry]: objectValue }
        } else {
          value = additionalObjectsMap.has(mapKey)
            ? {
                ...additionalObjectsMap.get(mapKey),
                [objectEntry]: objectValue,
              }
            : { [objectEntry]: objectValue }
        }

        additionalObjectsMap.set(mapKey, value)

        if (acc[entry] && isArrayOfArray) {
          value = [...acc[entry], { ...value }]
          acc[entry] = value
        } else {
          acc[entry] = [value]
        }
        return acc
      }

      const value = acc[entry] ? objectValue : [objectValue]

      entry in acc && !isObject ? acc[entry].push(value) : (acc[entry] = value)

      return acc
    }

    return { ...acc, [curr[0]]: curr[1] }
  }, {} as any)

  const additionalObjects = Array.from(additionalObjectsMap.entries()).reduce(
    (acc, curr) => {
      const key = curr[0].split('_')[0]
      const value = curr[1]

      if (acc[key]) {
        acc[key].push(value)
      } else {
        acc[key] = [value]
      }

      return acc
    },
    {} as any
  )

  return { ...result, ...additionalObjects }
}

export const convertObjectToFormData = (
  formData: FormData,
  data: any,
  parentKey?: string
) => {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      convertObjectToFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      )
    })
  } else {
    if (data === undefined) {
      return
    }

    const value = data === null ? '' : data
    parentKey && formData.append(parentKey, value)
  }
}

export const handleOnFieldChange = (
  formik: FormikProps<any>,
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  const { name } = event.target

  formik.handleChange(event)
  formik.setFieldError(name, undefined)
}

const exports = {
  convertFormDataToObject,
  convertObjectToFormData,
}

export default exports
