import { SxProps, Theme, keyframes } from '@mui/material'

const fadeInFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const chatbotDrawerContainerSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '0 3.2rem',
  textAlign: 'center',
  gap: '1.2rem',
  animation: `${fadeInFromBottom} 0.8s ease-in-out`,
}

export const chatbotDrawerImageSx: SxProps<Theme> = {
  width: '13.6rem',
  height: '13.6rem',
}

export const chatbotDrawerTitleSx: SxProps<Theme> = {
  marginTop: '0.4rem',
}

export const chatbotDrawerSubtitleSx: SxProps<Theme> = {
  color: (theme) => theme.palette.text.secondary,
}

export const chatbotTryNowButtonSx: SxProps<Theme> = {
  padding: '1.6rem 3.2rem',
  borderRadius: '3.2rem',
  height: '5.6rem',
  maxHeight: '5.6rem',
  minHeight: '5.6rem',
  marginTop: '1.2rem',
  boxShadow:
    '0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)',
}
