import { Box } from '@mui/material'

import { dotSx, typingDotsSx } from './styles'

const TypingDots = () => (
  <Box
    component="svg"
    sx={typingDotsSx}
    width="36px"
    height="16px"
    data-testid="typing-dots"
  >
    <Box component="circle" sx={dotSx('0s')} cx="4" cy="8" r="4" />
    <Box component="circle" sx={dotSx('0.2s')} cx="18" cy="8" r="4" />
    <Box component="circle" sx={dotSx('0.4s')} cx="32" cy="8" r="4" />
  </Box>
)

export { TypingDots }
