import { useTranslation } from 'react-i18next'

import { Divider, Typography } from '@mui/material'

import { isToday } from 'date-fns'

import DateUtils from '@utils/dates'

import { dateSx } from './styles'

interface IDateSeparatorProps {
  date: Date
}

const DateSeparator = ({ date }: IDateSeparatorProps) => {
  const { t } = useTranslation()

  const dateToShow = isToday(date)
    ? t('Today')
    : DateUtils.formatTo(date, 'MM/dd/yyyy')

  return (
    <Divider>
      <Typography variant="caption" sx={dateSx}>
        {dateToShow}
      </Typography>
    </Divider>
  )
}

export { DateSeparator }
