import { IconButton, Stack, Typography } from '@mui/material'

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'

import { FileAttachmentProps } from 'stream-chat-react'
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types'

import {
  attachmentIconSx,
  attachmentTitleSx,
  containerSx,
  downloadAttachmentIconSx,
} from './styles'

const AttachmentFile = ({
  attachment,
}: FileAttachmentProps<DefaultStreamChatGenerics>) => (
  <Stack direction="row" sx={containerSx}>
    <IconButton href={attachment.asset_url ?? ''}>
      <ArrowDownwardIcon sx={downloadAttachmentIconSx} />
    </IconButton>

    <InsertDriveFileIcon sx={attachmentIconSx} />

    <Typography sx={attachmentTitleSx}>{attachment.title}</Typography>
  </Stack>
)

export { AttachmentFile }
