import { lazy } from 'react'
import { Route, Routes as RouterRoutes } from 'react-router-dom'

import { NavigateTo } from '@contexts/components/NavigateTo'

import { withSuspense } from '@HOCs/withSuspense'

import { FeatureAvailabilityProviderRoute } from '@routes/components/feature-availability-provider-route'
import { PublicRoutesWithoutAssistanceChatBubble } from '@routes/components/public-routes-without-assistance-chat-bubble'
import { SolutionViewMiddleware } from '@routes/components/solution-view-middleware'
import { WithFooterRoute } from '@routes/components/with-footer-route'
import { GaRouteTracker } from '@routes/ga-route-tracker'

import { BuyerChatbotRoute } from '@pages/BuyerChatbotRoute'
import { ErrorPage404 } from '@pages/HttpError/ErrorPage404'
import { TemplateWithoutSideBar } from '@pages/TemplateWithoutSideBar'

import { ScrollToTop } from '@components/ScrollToTop'
import { Toast } from '@components/Toast'

const LandingPageRoutes = lazy(async () => {
  const { LandingPageRoutes } = await import('@routes/landing-page.routes')
  return { default: LandingPageRoutes }
})

const BuyerRoutes = lazy(async () => {
  const { BuyerRoutes } = await import('@routes/buyer.routes')
  return { default: BuyerRoutes }
})

const VendorRoutes = lazy(async () => {
  const { VendorRoutes } = await import('@routes/vendor.routes')
  return { default: VendorRoutes }
})

const AdminRoutes = lazy(async () => {
  const { AdminRoutes } = await import('@routes/admin.routes')
  return { default: AdminRoutes }
})

const ErrorPage403 = lazy(async () => {
  const { ErrorPage403 } = await import('@pages/HttpError/ErrorPage403')
  return { default: ErrorPage403 }
})

const ErrorPage500 = lazy(async () => {
  const { ErrorPage500 } = await import('@pages/HttpError/ErrorPage500')
  return { default: ErrorPage500 }
})

const SignIn = lazy(async () => {
  const { SignIn } = await import('@pages/SignIn')
  return { default: SignIn }
})

const SignUp = lazy(async () => {
  const { SignUp } = await import('@pages/SignUp')
  return { default: SignUp }
})

const ReactivateAccountLanding = lazy(async () => {
  const { ReactivateAccountLanding } = await import(
    '@pages/UserReactivationLanding'
  )
  return { default: ReactivateAccountLanding }
})

const ForgotPassword = lazy(async () => {
  const { ForgotPassword } = await import('@pages/ForgotPassword')
  return { default: ForgotPassword }
})

const PrivateRoute = lazy(async () => {
  const { PrivateRoute } = await import('@components/PrivateRoute')
  return { default: PrivateRoute }
})

const LandingPageRoutesWithSuspense = withSuspense(LandingPageRoutes)
const BuyerRoutesWithSuspense = withSuspense(BuyerRoutes)
const VendorRoutesWithSuspense = withSuspense(VendorRoutes)
const AdminRoutesWithSuspense = withSuspense(AdminRoutes)
const ForgotPasswordWithSuspense = withSuspense(ForgotPassword)
const ErrorPage403WithSuspense = withSuspense(ErrorPage403)
const ErrorPage404WithSuspense = withSuspense(ErrorPage404)
const ErrorPage500WithSuspense = withSuspense(ErrorPage500)
const SignInWithSuspense = withSuspense(SignIn)
const SignUpWithSuspense = withSuspense(SignUp)
const ReactivateAccountLandingWithSuspense = withSuspense(
  ReactivateAccountLanding
)
const PrivateRouteWithSuspense = withSuspense(PrivateRoute)

const Routes = () => (
  <>
    <GaRouteTracker />
    <ScrollToTop />

    <RouterRoutes>
      <Route path="/*" element={<LandingPageRoutesWithSuspense />} />

      <Route path="/signup" element={<SignUpWithSuspense />} />
      <Route path="/forgot-password" element={<ForgotPasswordWithSuspense />} />

      <Route path="/signin" element={<SignInWithSuspense />} />
      <Route element={<WithFooterRoute />}>
        <Route element={<PrivateRouteWithSuspense redirectTo="/signin" />}>
          <Route
            path="/reactivate-account"
            element={<ReactivateAccountLandingWithSuspense />}
          />
        </Route>
      </Route>

      <Route element={<FeatureAvailabilityProviderRoute />}>
        <Route element={<BuyerChatbotRoute />}>
          <Route element={<TemplateWithoutSideBar />}>
            <Route element={<PublicRoutesWithoutAssistanceChatBubble />}>
              <Route
                path="/solutions/:solutionIdOrSlug"
                element={<SolutionViewMiddleware />}
              />
            </Route>
          </Route>

          <Route path="/buyer/*" element={<BuyerRoutesWithSuspense />} />
        </Route>

        <Route path="/vendor/*" element={<VendorRoutesWithSuspense />} />
      </Route>

      <Route path="/admin/*" element={<AdminRoutesWithSuspense />} />

      <Route path="/403" element={<ErrorPage403WithSuspense />} />
      <Route path="/404" element={<ErrorPage404WithSuspense />} />
      <Route path="/500" element={<ErrorPage500WithSuspense />} />
      <Route path="*" element={<NavigateTo path="/404" replace />} />
    </RouterRoutes>
    <Toast />
  </>
)

export { Routes }
