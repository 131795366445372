import React from 'react'
import ReactDOM from 'react-dom'

import '@stream-io/stream-chat-css/dist/css/index.css'

import '@libs/cognito'
import '@libs/data-dog'
import '@libs/everflow'
import '@libs/ga'
import '@libs/i18n'
import '@libs/sentry'

import App from './App'
import './chat.css'
import './index.css'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
