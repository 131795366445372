import { useTranslation } from 'react-i18next'

import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'

import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import CompareIcon from '@mui/icons-material/Compare'
import HelpIcon from '@mui/icons-material/Help'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import SearchIcon from '@mui/icons-material/Search'

import CosmoIdleAnimation from '@lottie-animations/cosmo_idle.json'

import i18n from '@libs/i18n'

import { BetaTag } from '@components/BetaTag'
import { LottieAnimation } from '@components/LottieAnimation'

import {
  emptyStateWrapperSx,
  imageSx,
  listItemArrowIconSx,
  listItemIconSx,
  listItemSx,
  listItemTextSx,
  listSx,
  subtitleSx,
  titleSx,
} from './styles'

interface IEmptyHistoryProps {
  onSendPrompt: ({ prompt }: { prompt: string }) => void
}

const t = i18n.t.bind(i18n)

const options = [
  {
    icon: <SearchIcon color="primary" />,
    text: t('Find the best matches for my needs'),
    prompt: t('Hi, could you help me find the perfect match for my needs?'),
  },
  {
    icon: <CompareIcon color="primary" />,
    text: t("Compare multiple vendors' solutions"),
    prompt: t('Hi, could you help me compare vendors’ solutions?'),
  },
  {
    icon: <AttachMoneyIcon color="primary" />,
    text: t('Get recommendations based on my budget'),
    prompt: t(
      'Hi, could you help me find the best match for my needs based on my budget?'
    ),
  },
  {
    icon: <HelpIcon color="primary" />,
    text: t('Get help navigating the platform'),
    prompt: t('Hi, I need assistance navigating the app'),
  },
]

const EmptyHistory = ({ onSendPrompt }: IEmptyHistoryProps) => {
  const { t } = useTranslation()

  const handleItemClick = ({ prompt }: { prompt: string }) => {
    onSendPrompt({ prompt })
  }

  return (
    <Box sx={emptyStateWrapperSx} data-testid="chatbot-empty-history">
      <Box sx={imageSx}>
        <LottieAnimation
          animationData={CosmoIdleAnimation}
          width={120}
          height={88}
          loop={false}
        />
      </Box>
      <Typography variant="subtitle1" sx={subtitleSx}>
        {t("Hi, I'm Cosmo, your AI assistant.")}
        <BetaTag />
      </Typography>
      <Typography variant="h5" sx={titleSx}>
        {t('How can I assist you today?')}
      </Typography>
      <List sx={listSx}>
        {options.map((option, index) => (
          <ListItemButton
            key={index}
            sx={listItemSx}
            onClick={() => handleItemClick({ prompt: option.prompt })}
          >
            <ListItemIcon sx={listItemIconSx}>{option.icon}</ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body1" sx={listItemTextSx}>
                  {option.text}
                </Typography>
              }
            />
            <ListItemIcon sx={listItemArrowIconSx}>
              <KeyboardArrowRightIcon />
            </ListItemIcon>
          </ListItemButton>
        ))}
      </List>
    </Box>
  )
}

export { EmptyHistory }
