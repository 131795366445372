import { SxProps, Theme } from '@mui/material'

export const chatHeaderSx: SxProps<Theme> = {
  padding: {
    xs: '1.6rem',
    lg: '2rem 2.4rem',
  },
  alignItems: 'center',
  height: '6.4rem',
}

export const channelNameSx = ({
  addLeftMargin,
}: {
  addLeftMargin: boolean
}): SxProps<Theme> => {
  return {
    marginLeft: {
      xs: addLeftMargin ? '1.6rem' : 0,
      lg: 0,
    },
  }
}

export const closeChatButtonSx: SxProps<Theme> = {
  marginLeft: 'auto',
  marginRight: {
    xs: '-0.8rem',
    lg: '-2rem',
  },
  svg: {
    width: '3.2rem',
    height: '3.2rem',
  },
}

export const openSideBarDrawerButtonSx: SxProps<Theme> = {
  marginLeft: '-0.8rem',
}

export const openSideBarDrawerIconButtonSx: SxProps<Theme> = {
  width: '2.4rem',
  height: '2.4rem',
  color: (theme) => theme.palette.primary.main,
}
