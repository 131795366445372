import { SxProps, Theme } from '@mui/material'

export const containerSx: SxProps<Theme> = { alignItems: 'center' }

export const attachmentIconSx: SxProps<Theme> = {
  minWidth: '5.5rem',
  minHeight: '5.5rem',
  color: (theme) => theme.palette.primaryLight.light,
}

export const downloadAttachmentIconSx: SxProps<Theme> = {
  color: (theme) => theme.palette.primaryLight.light,
}

export const attachmentTitleSx: SxProps<Theme> = {
  marginLeft: '1rem',
  color: (theme) => theme.palette.text.secondary,
}
