import api from '@libs/api'

import { handleServiceError } from '@utils/errors'
import {
  IEnterpriseSettings,
  IFeatureAvailability,
  IRequestDemoUrls,
} from '@utils/types'

const routeName = '/global-settings'

const getDaysRequiredForDeletion = async (): Promise<number> => {
  try {
    const response = await api.get({
      path: `${routeName}/days-required-for-deletion`,
    })

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const getRequestDemoUrls = async (): Promise<IRequestDemoUrls> => {
  try {
    const response = await api.get({
      path: `${routeName}/request-demo-urls`,
      authenticate: false,
    })

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const getEnterpriseSettings = async (): Promise<IEnterpriseSettings> => {
  try {
    const response = await api.get({
      path: `${routeName}/enterprise-settings`,
    })

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const getMyUserFeatureAvailabilities =
  async (): Promise<IFeatureAvailability> => {
    try {
      const response = await api.get({
        path: `${routeName}/features/me`,
      })

      return response
    } catch (err: any) {
      throw handleServiceError(err)
    }
  }

export default {
  getDaysRequiredForDeletion,
  getRequestDemoUrls,
  getEnterpriseSettings,
  getMyUserFeatureAvailabilities,
}
