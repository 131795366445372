import { AdvisorRealtimeEventProvider } from '@contexts/advisor-realtime-events'
import { AuthProvider } from '@contexts/auth'
import { ChatProvider } from '@contexts/chat'
import { NavigationProvider } from '@contexts/navigation'
import { TemplateProvider } from '@contexts/template'
import { ThemeProvider } from '@contexts/theme'
import { ToastProvider } from '@contexts/toast'

import { StripeElements } from '@components/StripeElements'

const AppProvider: React.FC = ({ children }) => (
  <NavigationProvider>
    <ThemeProvider>
      <ToastProvider>
        <TemplateProvider>
          <ChatProvider>
            <AuthProvider>
              <AdvisorRealtimeEventProvider>
                <StripeElements>{children}</StripeElements>
              </AdvisorRealtimeEventProvider>
            </AuthProvider>
          </ChatProvider>
        </TemplateProvider>
      </ToastProvider>
    </ThemeProvider>
  </NavigationProvider>
)

export { AppProvider }
