import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { Link as RouterLink } from 'react-router-dom'

import { Box, Button, Link, Tooltip, Typography } from '@mui/material'

import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined'

import responsiveHook from '@hooks/useResponsive'

import { TypingDots } from '@components/TypingDots'

import { COSMO_AVATAR } from '@utils/images'
import { ChatbotMessage } from '@utils/types'
import { getDomainWithoutSubdomain } from '@utils/url'

import {
  assistantMessageIconSx,
  assistantMessageSx,
  assistantMessageTextSx,
  assistantMessageWrapperSx,
  errorContainerSx,
  errorSubtitleSx,
  reportButtonIconSx,
  reportButtonSx,
  tryAgainButtonSx,
} from './styles'

import { FeedbackForm } from '../FeedbackForm'

interface IAssistantMessage {
  item: ChatbotMessage
  isLastMessage: boolean
  onScroll: () => void
  onLinkClick: () => void
  isStreaming: boolean
  conversationId: string | undefined
  resendPrompt: () => Promise<void>
}

interface ChatbotMessageLinkProps {
  children: React.ReactNode
  href?: string
  onLinkClick: () => void
}

const ChatbotMessageLink = ({
  children,
  href = '',
  onLinkClick,
}: ChatbotMessageLinkProps) => {
  const { isMobile } = responsiveHook.useResponsive()

  const handleClick = () => {
    if (isMobile) {
      onLinkClick()
    }
  }

  let finalPath = href
  const hrefUrl = new URL(href)

  if (getDomainWithoutSubdomain(href) === 'technologymatch.com') {
    hrefUrl.protocol = window.location.protocol
    hrefUrl.hostname = window.location.hostname
    hrefUrl.port = window.location.port

    finalPath = hrefUrl.pathname
  }

  const isBuyerDashboardOrSolutionLink =
    hrefUrl.pathname.startsWith('/buyer/') ||
    hrefUrl.pathname.startsWith('/solutions/')

  return isBuyerDashboardOrSolutionLink ? (
    <Link component={RouterLink} to={finalPath} onClick={handleClick}>
      {children}
    </Link>
  ) : (
    <Link href={hrefUrl.href} target="_blank" rel="noopener noreferrer">
      {children}
    </Link>
  )
}

const AssistantMessage = ({
  item,
  isLastMessage,
  isStreaming,
  onScroll,
  onLinkClick,
  conversationId,
  resendPrompt,
}: IAssistantMessage) => {
  const { t } = useTranslation()

  const [isFeedbackFormOpen, setIsFeedbackFormOpen] = useState(false)
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false)

  const onSuccess = useCallback(() => {
    setIsFeedbackSubmitted(true)
  }, [])

  const handleOnOpenFeedbackForm = useCallback(
    ({ isReported }: { isReported?: boolean }) => {
      if (isReported) {
        return
      }
      setIsFeedbackFormOpen(true)
      setTimeout(() => onScroll(), 100)
    },
    [onScroll]
  )

  const handleOnCloseFeedbackForm = () => {
    setIsFeedbackFormOpen(false)
  }

  const isReported = item?.reported || isFeedbackSubmitted

  const shouldDisplayFeedbackForm =
    isFeedbackFormOpen &&
    isLastMessage &&
    item.id &&
    conversationId &&
    !isStreaming

  return (
    <Box sx={assistantMessageWrapperSx}>
      <Box sx={assistantMessageTextSx}>
        <Box
          alt={t('Cosmo Chatbot Avatar')}
          loading="lazy"
          component="img"
          src={COSMO_AVATAR}
          sx={assistantMessageIconSx}
        />
        {item.error ? (
          <Box sx={errorContainerSx} data-testid="conversation-history-error">
            <Typography variant="subtitle1">
              {t('Oops! I hit a snag on my end')}
            </Typography>
            <Typography variant="body2" sx={errorSubtitleSx}>
              {t("It looks like something went wrong. Let's try that again!")}
            </Typography>
            <Button
              variant="outlined"
              color="error"
              size="medium"
              sx={tryAgainButtonSx}
              onClick={resendPrompt}
              data-testid="resend-prompt-button"
            >
              {t('Try Again')}
            </Button>
          </Box>
        ) : (
          <Box data-testid="conversation-history-item" sx={assistantMessageSx}>
            {item.message === '...' ? (
              <TypingDots />
            ) : (
              <Box>
                <ReactMarkdown
                  className="markdown"
                  components={{
                    a: ({ children, href }) => (
                      <ChatbotMessageLink href={href} onLinkClick={onLinkClick}>
                        {children}
                      </ChatbotMessageLink>
                    ),
                  }}
                >
                  {item.message}
                </ReactMarkdown>
                {isLastMessage && item.id && conversationId && !isStreaming && (
                  <Tooltip
                    title={isReported ? t('Feedback already provided') : null}
                    enterDelay={500}
                  >
                    <Box
                      component="button"
                      aria-label={t('Open feedback form')}
                      onClick={() =>
                        handleOnOpenFeedbackForm({
                          isReported,
                        })
                      }
                      sx={reportButtonSx({
                        isReported,
                      })}
                      data-testid="not-helpful-button"
                    >
                      {isReported ? (
                        <ThumbDownAltIcon sx={reportButtonIconSx} />
                      ) : (
                        <ThumbDownOffAltOutlinedIcon sx={reportButtonIconSx} />
                      )}
                      <Typography variant="body2">
                        {isReported ? t('Reported') : t('Not helpful?')}
                      </Typography>
                    </Box>
                  </Tooltip>
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
      {shouldDisplayFeedbackForm && (
        <FeedbackForm
          messageId={item.id!}
          conversationId={conversationId}
          message={item.message}
          onSuccess={onSuccess}
          onClose={handleOnCloseFeedbackForm}
          onScroll={onScroll}
        />
      )}
    </Box>
  )
}

export { AssistantMessage }
