import React from 'react'

import { Box } from '@mui/material'

import { ChatbotMessage, ChatbotUser } from '@utils/types'

import { conversationBoxSx } from './styles'

import { AssistantMessage } from './AssistantMessage'
import { UserMessage } from './UserMessage'

interface IConversationViewProps {
  history: ChatbotMessage[]
  conversationId: string | undefined
  isStreaming: boolean
  resendPrompt: () => Promise<void>
  onLinkClick: () => void
  onScroll: () => void
}

const ConversationView = ({
  history,
  conversationId,
  isStreaming,
  onScroll,
  onLinkClick,
  resendPrompt,
}: IConversationViewProps) => (
  <Box sx={conversationBoxSx}>
    {history.map((item, index) => (
      <React.Fragment key={index}>
        {item.role === ChatbotUser.USER ? (
          <UserMessage message={item.message} />
        ) : (
          <AssistantMessage
            item={item}
            onLinkClick={onLinkClick}
            resendPrompt={resendPrompt}
            onScroll={onScroll}
            isLastMessage={index === history.length - 1}
            isStreaming={isStreaming}
            conversationId={conversationId}
          />
        )}
      </React.Fragment>
    ))}
  </Box>
)

export { ConversationView }
