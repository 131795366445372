import { createContext, useCallback, useEffect, useState } from 'react'

import authHook from '@hooks/useAuth'
import errorHandlerHook from '@hooks/useErrorHandler'

import GlobalSettingService from '@services/global-setting'

import { type IFeatureAvailability } from '@utils/types'

export type FeaturesContextState = {
  availableFeatures: IFeatureAvailability
  isLoadingAvailableFeatures: boolean
}

export const FeatureAvailabilityContext = createContext<FeaturesContextState>(
  {} as FeaturesContextState
)

const FeatureAvailabilityProvider: React.FC = ({ children }) => {
  const { authenticatedUser } = authHook.useAuth()
  const { handleError } = errorHandlerHook.useErrorHandler()

  const [isLoadingAvailableFeatures, setIsLoadingAvailableFeatures] =
    useState(true)
  const [availableFeatures, setAvailableFeatures] =
    useState<IFeatureAvailability>({
      marketTrends: false,
      buyerChatbot: false,
    })

  const fetchMyUserFeaturesAvailabilities = useCallback(async () => {
    try {
      const response =
        await GlobalSettingService.getMyUserFeatureAvailabilities()

      setAvailableFeatures(response)
    } catch (err) {
      handleError({ exception: err })
    }

    setIsLoadingAvailableFeatures(false)
  }, [handleError])

  useEffect(() => {
    if (authenticatedUser) {
      fetchMyUserFeaturesAvailabilities()
    }
  }, [fetchMyUserFeaturesAvailabilities, authenticatedUser])

  return (
    <FeatureAvailabilityContext.Provider
      value={{
        availableFeatures,
        isLoadingAvailableFeatures,
      }}
    >
      {children}
    </FeatureAvailabilityContext.Provider>
  )
}

export { FeatureAvailabilityProvider }
