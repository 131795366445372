import { SxProps, Theme } from '@mui/material'

export const containerSx: SxProps<Theme> = {
  gap: '0.4rem',
  alignItems: 'center',
}

export const warningIconSx: SxProps<Theme> = {
  width: '1.4rem',
  height: '1.2rem',
  color: (theme) => theme.palette.warning.dark,
}
