import ReactGA from 'react-ga4'

const initializeGA = () => {
  const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS

  if (googleAnalyticsId) {
    const isLocalhost = window.location.hostname === 'localhost'

    const options = isLocalhost
      ? {
          gtagOptions: {
            debug_mode: isLocalhost,
            debug_event: isLocalhost,
          },
        }
      : undefined

    ReactGA.initialize(googleAnalyticsId, options)
  }
}

initializeGA()
