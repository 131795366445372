import { useEffect } from 'react'

import EnvironmentUtils from '@utils/environments'
import HubSpotUtils from '@utils/hubspot'

const HubSpotScript = () => {
  const addHubSpotScript = () => {
    const script = HubSpotUtils.buildScript()

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }

  useEffect(() => {
    if (EnvironmentUtils.isProduction()) {
      addHubSpotScript()
    }
  }, [])

  return null
}

export { HubSpotScript }
