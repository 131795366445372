import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar, Card, CardActionArea, Stack, Typography } from '@mui/material'

import { Channel as ChannelType, DefaultGenerics, Event } from 'stream-chat'
import { useChatContext } from 'stream-chat-react'

import chatHook from '@hooks/useChat'

import { UserWarningChip } from '@components/UserWarningChip'

import {
  DEFAULT_ADMIN_SUPPORT_NAME,
  getAssistedUserTypeMappedText,
} from '@utils/chats'
import DateUtils from '@utils/dates'
import StringUtils from '@utils/strings'
import type { IChatUser } from '@utils/types'
import { ChatStatus } from '@utils/types'

import {
  chatChannelItemSx,
  chatStatusTextSx,
  lastOnlineDateSx,
  lastUserPositionAndLastOnlineWrapperSx,
  userAvatarSx,
  userInfoWithAvatarWrapperSx,
  userInfoWithoutAvatarWrapperSx,
  userNameAndStatusWrapperSx,
  userNotAvailableWarningLabelSx,
  userPositionTextSx,
} from './styles'

export type ChatUserWithOnlineStatus = IChatUser & {
  currentChatStatus: ChatStatus
  lastOnline?: Date
}

interface IChatUserChatChannelProps {
  user: IChatUser
  chatChannel: ChannelType<DefaultGenerics>
}

const UserChatChannel = ({
  user: initialUser,
  chatChannel,
}: IChatUserChatChannelProps) => {
  const { t } = useTranslation()

  const { processUserChatStatus } = chatHook.useChat()
  const { client } = useChatContext()

  const [userWithOnlineStatus, setUserWithOnlineStatus] =
    useState<ChatUserWithOnlineStatus>({
      ...initialUser,
      currentChatStatus: ChatStatus.OFFLINE,
    })

  const getPosition = () => {
    const { companyName, officialTitle } = userWithOnlineStatus

    if (!!officialTitle && !!companyName) {
      return `${officialTitle} • ${companyName}`
    } else if (officialTitle) {
      return officialTitle
    } else if (companyName) {
      return companyName
    }

    return undefined
  }

  const getUsername = () => {
    const name = userWithOnlineStatus.name?.trim()
    return !name || name.length <= 0 ? DEFAULT_ADMIN_SUPPORT_NAME : name
  }

  useEffect(() => {
    const updateChannelUsersChatStatus = (event?: Event) => {
      const wasTriggeredByMe = !!event && event?.user?.id === client.userID

      if (
        wasTriggeredByMe ||
        (event && !chatChannel.state.members[event.user!.id])
      ) {
        return
      }

      const userChatMemberTemp = { ...userWithOnlineStatus }

      const userUserId = event ? event!.user!.id : userChatMemberTemp.id
      const userChatMemberUser = chatChannel.state.members[userUserId!]

      if (!userChatMemberUser) {
        return
      }

      const lastTimeOnline = userChatMemberUser.user?.last_active
      if (lastTimeOnline) {
        userChatMemberTemp.lastOnline = new Date(lastTimeOnline!)
      }

      userChatMemberTemp.currentChatStatus = processUserChatStatus({
        user: userChatMemberUser.user,
      })

      setUserWithOnlineStatus(userChatMemberTemp)
    }

    updateChannelUsersChatStatus()

    client.on('user.presence.changed', updateChannelUsersChatStatus)

    return () => {
      client.off('user.presence.changed', updateChannelUsersChatStatus)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAssistedUserType = () => {
    const assistedUserTypeMappedText = getAssistedUserTypeMappedText({
      assistedChatUserType: initialUser.type,
    })

    return t(assistedUserTypeMappedText)
  }

  const position = getPosition()
  const userNotAvailable = userWithOnlineStatus.notAvailable ?? false

  return (
    <CardActionArea
      data-testid="user-chat-channel"
      key={chatChannel.id}
      sx={chatChannelItemSx({
        userNotAvailable,
      })}
    >
      <Card
        elevation={0}
        sx={userInfoWithAvatarWrapperSx({
          userNotAvailable,
        })}
      >
        <Avatar
          alt={t('User Avatar')}
          src={userWithOnlineStatus.avatar}
          sx={userAvatarSx}
        />
        <Stack sx={userInfoWithoutAvatarWrapperSx}>
          <Stack direction="row" sx={userNameAndStatusWrapperSx}>
            <Typography data-testid="user-name" variant="subtitle1">
              {getUsername()}
            </Typography>
            <Typography
              data-testid="user-online-status"
              variant="caption"
              sx={chatStatusTextSx({
                chatStatus: userWithOnlineStatus.currentChatStatus,
              })}
            >
              {StringUtils.toCapitalCase(
                t(userWithOnlineStatus.currentChatStatus)
              )}
            </Typography>
          </Stack>
          <Stack direction="row" sx={lastUserPositionAndLastOnlineWrapperSx}>
            {!!position && (
              <Typography
                data-testid="user-position"
                variant="body2"
                sx={userPositionTextSx}
              >
                {position}
              </Typography>
            )}

            {userWithOnlineStatus.currentChatStatus !== ChatStatus.ONLINE &&
              !!userWithOnlineStatus.lastOnline && (
                <Typography
                  data-testid="user-last-online"
                  variant="caption"
                  sx={lastOnlineDateSx}
                >
                  {DateUtils.formatTo(
                    userWithOnlineStatus.lastOnline,
                    'd/M/yyyy'
                  )}
                </Typography>
              )}
          </Stack>
        </Stack>
      </Card>

      {userNotAvailable && (
        <UserWarningChip
          dataTestId="user-not-available-warning"
          label={t(`${getAssistedUserType()} no longer available`)}
          chipSx={userNotAvailableWarningLabelSx}
        />
      )}
    </CardActionArea>
  )
}

export { UserChatChannel }
