import { format, formatDistance } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'

import { getTimeZone } from './timezones'

const formatTo = (
  date: Date | string | undefined,
  dateFormat: string
): string => {
  if (!date) {
    return ''
  }

  const dateToFormat = typeof date === 'string' ? new Date(date) : date
  return format(dateToFormat, dateFormat)
}

const getRelativeTimeDistance = (dateValue: string | undefined) =>
  dateValue
    ? formatDistance(new Date(dateValue), new Date(), { addSuffix: true })
    : undefined

const getDateInLocalTimeZone = (date: Date) => {
  const timeZone = getTimeZone()
  return zonedTimeToUtc(date, timeZone)
}

const getISODateLocalTimeZone = (date: Date) =>
  getDateInLocalTimeZone(date).toISOString()

const ISO_DATE_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:.\d{1,3})?Z?$/

const replaceISODateWithDate = (obj: any): any => {
  if (typeof obj === 'string' && ISO_DATE_REGEX.test(obj)) {
    const date = new Date(obj)
    return isNaN(date.getTime()) ? obj : date
  } else if (Array.isArray(obj)) {
    return obj.map((item) => replaceISODateWithDate(item))
  } else if (typeof obj === 'object' && obj !== null) {
    const newObj: { [key: string]: any } = {}
    for (const key in obj) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      newObj[key] = replaceISODateWithDate(obj[key])
    }
    return newObj
  } else {
    return obj
  }
}

const formatLocalTimeWithTimeZone = (date: Date): string => {
  // Format the time
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }
  const formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(
    date
  )

  // Get the timezone abbreviation
  const timeZoneOptions: Intl.DateTimeFormatOptions = { timeZoneName: 'short' }
  const timeZone = new Intl.DateTimeFormat('en-US', timeZoneOptions)
    .format(date)
    .split(' ')[1]

  return `${formattedTime} ${timeZone}`
}

const getDifferenceBetweenHoursInMinutes = (
  startDate: Date,
  endDate: Date
): number => {
  const diffInMs = Math.abs(endDate.getTime() - startDate.getTime())
  return Math.floor(diffInMs / 1000 / 60)
}

const shiftDateByYear = ({
  date,
  yearOffset,
}: {
  date: Date | undefined
  yearOffset: number
}) => {
  if (!date) {
    return
  }

  const newDate = date
  newDate.setFullYear(date.getFullYear() + yearOffset)

  return newDate
}

const getDateFromISOString = (dateString: string | undefined | null) =>
  dateString ? new Date(dateString) : undefined

export default {
  formatTo,
  getRelativeTimeDistance,
  replaceISODateWithDate,
  formatLocalTimeWithTimeZone,
  getDifferenceBetweenHoursInMinutes,
  shiftDateByYear,
  getDateFromISOString,
  getDateInLocalTimeZone,
  getISODateLocalTimeZone,
}
