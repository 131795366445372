export class UserNotConfirmedException extends Error {}
export class UserEmailNotVerifiedException extends Error {}
export class LimitExceededException extends Error {}
export class CodeMismatchException extends Error {}
export class NewPasswordRequiredException extends Error {}
export class UserNotAdminException extends Error {}
export class EmailNotVerifiedException extends Error {}
export class ExpiredCodeException extends Error {}
export class UserNotBuyerException extends Error {}
export class UserNotVendorException extends Error {}
export class HasAdminAccountException extends Error {}
export class FileTooLargeException extends Error {}
export class FileNotImageException extends Error {}
export class SignupUserGroupMismatchException extends Error {}

export const exceptionsTypes = {
  USER_NOT_ADMIN_EXCEPTION: 'UserNotAdminException',
  NEW_PASSWORD_REQUIRED_EXCEPTION: 'NewPasswordRequiredException',
  USER_NOT_CONFIRMED_EXCEPTION: 'UserNotConfirmedException',
  USER_EMAIL_NOT_VERIFIED_EXCEPTION: 'UserEmailNotVerifiedException',
  CODE_MISMATCH_EXCEPTION: 'CodeMismatchException',
  EXPIRED_CODE_EXCEPTION: 'ExpiredCodeException',
  USER_NOT_BUYER_EXCEPTION: 'UserNotBuyerException',
  USER_NOT_VENDOR_EXCEPTION: 'UserNotVendorException',
  HAS_ADMIN_ACCOUNT_EXCEPTION: 'HasAdminAccountException',
  SIGNUP_USER_GROUP_MISMATCH_EXCEPTION: 'SignupUserGroupMismatchException',
}

export const exceptions: { [key: string]: any } = {
  LimitExceededException,
  [exceptionsTypes.NEW_PASSWORD_REQUIRED_EXCEPTION]:
    NewPasswordRequiredException,
  [exceptionsTypes.USER_NOT_ADMIN_EXCEPTION]: UserNotAdminException,
  [exceptionsTypes.USER_NOT_CONFIRMED_EXCEPTION]: UserNotConfirmedException,
  [exceptionsTypes.USER_EMAIL_NOT_VERIFIED_EXCEPTION]:
    UserEmailNotVerifiedException,
  [exceptionsTypes.CODE_MISMATCH_EXCEPTION]: CodeMismatchException,
  [exceptionsTypes.EXPIRED_CODE_EXCEPTION]: ExpiredCodeException,
  [exceptionsTypes.USER_NOT_BUYER_EXCEPTION]: UserNotBuyerException,
  [exceptionsTypes.USER_NOT_VENDOR_EXCEPTION]: UserNotVendorException,
  [exceptionsTypes.HAS_ADMIN_ACCOUNT_EXCEPTION]: HasAdminAccountException,
  [exceptionsTypes.SIGNUP_USER_GROUP_MISMATCH_EXCEPTION]:
    SignupUserGroupMismatchException,
}
