import { SxProps, Theme, keyframes } from '@mui/material'

const fadeInFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const cardSx: SxProps<Theme> = {
  width: '100%',
  margin: 'auto',
  padding: '1.2rem 1.6rem',
  border: '0.1rem solid rgba(0, 0, 0, 0.12)',
  boxShadow:
    '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
  borderRadius: '1.2rem',
  marginBottom: '2.4rem',
  paddingBottom: 0,
  animation: `${fadeInFromTop} 0.5s ease-in-out`,
}

export const feedbackFormCardContentSx: SxProps<Theme> = {
  padding: '0rem',
  gap: '1.6rem',
  display: 'flex',
  flexDirection: 'column',
}

export const feedbackFormSubmittedSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  marginBottom: '2.4rem',
  width: '100%',
  padding: '1.6rem',
  textAlign: 'center',
  border: '0.1rem solid rgba(0, 0, 0, 0.12)',
  boxShadow:
    '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
  borderRadius: '1.2rem',
  gap: '1rem',
}

export const feedbackFormSubmittedIconContainerSx: SxProps<Theme> = {
  padding: '0.8rem',
  borderRadius: '4rem',
  background: 'rgba(46, 125, 50, 0.08)',
  width: '4rem',
  height: '4rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const feedbackFormSubmittedIconSx: SxProps<Theme> = {
  width: '2.4rem',
  height: '2.4rem',
}

export const feedbackFormSubmittedTextSx: SxProps<Theme> = { textAlign: 'left' }

export const feedbackFormHeader: SxProps<Theme> = {
  display: 'flex',
  gap: '1rem',
  alignItems: 'center',
  paddingBottom: '1.2rem',
  borderBottom: (theme) => `0.1rem solid ${theme.palette.divider}`,
  justifyContent: 'space-between',
}
export const feedbackFormHeaderContainer: SxProps<Theme> = {
  display: 'flex',
  gap: '1rem',
  alignItems: 'center',
}

export const feedbackFormHeaderIconContainerSx: SxProps<Theme> = {
  padding: '0.8rem',
  borderRadius: '4rem',
  background: 'rgba(211, 47, 47, 0.08)',
  width: '4rem',
  height: '4rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const feedbackFormHeaderIconSx: SxProps<Theme> = {
  width: '2.4rem',
  height: '2.4rem',
  color: (theme) => theme.palette.error.main,
}

export const feedbackFormChipsContainerSx: SxProps<Theme> = {
  display: 'flex',
  flexWrap: 'wrap',
  rowGap: '0.8rem',
  columnGap: '1rem',
  marginTop: '0.8rem',
}

export const feedbackFormTextFieldSx: SxProps<Theme> = {
  width: '100%',
  border: 'none',
  '& .MuiOutlinedInput-root': {
    alignItems: 'flex-end',
    padding: 0,
  },
  '& fieldset': {
    border: 'none',
    padding: 0,
  },
  '& textarea': {
    padding: 0,
  },
}

export const feedbackFormChipSx: SxProps<Theme> = { cursor: 'pointer' }

export const textFieldWrapperSx: SxProps<Theme> = {
  borderRadius: '0.4rem',
  marginTop: '0.8rem',
  border: '0.1rem solid rgba(0, 0, 0, 0.23)',
  padding: '0.8rem 1.2rem',
  '&:focus-within': {
    border: (theme) => `0.2rem solid ${theme.palette.primary.main}`,
  },
  '&:hover': {
    border: (theme) => `0.1rem solid ${theme.palette.text.secondary}`,
  },
  '&:hover:focus-within': {
    border: (theme) => `0.2rem solid ${theme.palette.primary.main}`,
  },
}

export const charactersCountSx: SxProps<Theme> = {
  color: (theme) => theme.palette.text.secondary,
}

export const textFieldBottomSx: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'end',
}
