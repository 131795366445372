import { SxProps, Theme } from '@mui/material'

export const assistantMessageWrapperSx: SxProps<Theme> = {
  display: 'flex',
  gap: '2.4rem',
  flexDirection: 'column',
  alignItems: 'start',
}

export const assistantMessageIconSx: SxProps<Theme> = {
  width: '4rem',
  height: '3.3rem',
}

export const assistantMessageSx: SxProps<Theme> = {
  borderRadius: '1.2rem',
  border: (theme) => `0.1rem solid ${theme.palette.divider}`,
  padding: '1.2rem 1.6rem',
  overflowWrap: 'anywhere',
  whiteSpace: 'pre-wrap',
}

export const conversationBoxSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: '2.4rem',
  padding: {
    xs: '0 1.6rem',
    md: '0 3.2rem',
  },
}

export const reportButtonSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'end',
  gap: '0.4rem',
  background: 'none',
  border: 'none',
  marginLeft: 'auto',
  color: (theme) => theme.palette.text.secondary,
  padding: '0.4rem 0.5rem',
  cursor: 'pointer',
}

export const reportButtonIconSx: SxProps<Theme> = {
  width: '1.8rem',
  height: '1.8rem',
}

export const assistantMessageTextSx: SxProps<Theme> = {
  display: 'flex',
  gap: '1rem',
}
