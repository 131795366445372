import { useTranslation } from 'react-i18next'

import { Box, Button, Typography } from '@mui/material'

import CosmoIntroAnimation from '@lottie-animations/cosmo_intro.json'

import { LottieAnimation } from '@components/LottieAnimation'

import {
  chatbotDrawerContainerSx,
  chatbotDrawerSubtitleSx,
  chatbotDrawerTitleSx,
  chatbotTryNowButtonSx,
} from './styles'

interface IChatbotWelcome {
  onClick: () => void
}

const ChatbotWelcome = ({ onClick }: IChatbotWelcome) => {
  const { t } = useTranslation()

  return (
    <Box sx={chatbotDrawerContainerSx} data-testid="chatbot-welcome">
      <Box>
        <LottieAnimation
          animationData={CosmoIntroAnimation}
          width={220}
          height={150}
          loop={false}
        />
      </Box>
      <Typography variant="h5" sx={chatbotDrawerTitleSx}>
        {t("Hi, I'm Cosmo, your AI assistant!")}
      </Typography>
      <Typography variant="body1" sx={chatbotDrawerSubtitleSx}>
        {t(
          "I'll save you time along the way to make searching for the perfect match a breeze."
        )}
      </Typography>
      <Button
        variant="contained"
        size="large"
        sx={chatbotTryNowButtonSx}
        onClick={onClick}
        data-testid="chatbot-welcome-try-now-button"
      >
        {t('Try now')}
      </Button>
    </Box>
  )
}

export { ChatbotWelcome }
