import { useTranslation } from 'react-i18next'

import { Stack, SxProps, Theme, Typography } from '@mui/material'

import templateHook from '@hooks/useTemplate'

import { wrapperSx } from './styles'

const FooterCopyright = () => {
  const { t } = useTranslation()
  const { footerCopyrightSx } = templateHook.useTemplate()

  const currentYear = new Date().getFullYear()
  const currentVersion = process.env.REACT_APP_VERSION

  return (
    <Stack
      component="footer"
      data-testid="footer-copyright"
      direction="row"
      sx={{ ...wrapperSx, ...footerCopyrightSx } as SxProps<Theme>}
    >
      <Typography variant="body2">
        {t('All rights reserved')} © {currentYear} The Launchpad -{' '}
        {currentVersion}
      </Typography>
    </Stack>
  )
}

export { FooterCopyright }
