import { Outlet } from 'react-router-dom'

import authHook from '@hooks/useAuth'

import { Chatbot } from '@components/Chatbot'

const BuyerChatbotRoute = () => {
  const { isBuyer } = authHook.useAuth()

  return (
    <>
      <Outlet />
      {isBuyer() && <Chatbot />}
    </>
  )
}

export { BuyerChatbotRoute }
