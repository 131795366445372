import { SxProps, Theme } from '@mui/material'

export const conversationListSx: SxProps<Theme> = {
  padding: {
    xs: '0 1.6rem',
    md: '0 2.4rem',
  },
}

export const newConversationButtonSx: SxProps<Theme> = {
  borderRadius: '3.2rem',
  width: 'fit-content',
}

export const listSx: SxProps<Theme> = {
  marginTop: '1.6rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.8rem',
}

export const periodSubtitleSx: SxProps<Theme> = {
  color: (theme) => theme.palette.text.secondary,
}

export const periodContainerSx: SxProps<Theme> = {
  marginTop: '2.4rem',
  '&:first-of-type': {
    marginTop: 0,
  },
}

export const confirmationDialogContainerSx: SxProps<Theme> = {
  width: '44.4rem',
}
