import {
  SetStateAction,
  createContext,
  useCallback,
  useRef,
  useState,
} from 'react'

import * as Sentry from '@sentry/react'
import {
  Channel,
  ConnectionOpen,
  DefaultGenerics,
  Event,
  OwnUserResponse,
  UserResponse,
} from 'stream-chat'

import { streamChatClient } from '@libs/stream-chat'

import ChatService from '@services/chat'

import { AdvisorConnectAssistanceChatBubble } from '@components/AdvisorConnectAssistanceChatBubble'

import {
  AssistanceChatSourcePage,
  ChatStatus,
  IBuyerVendorChatUserData,
  IVisitorChatUserData,
  StreamChatChannelType,
} from '@utils/types'

interface IUnsubscribeFromAllChatEvents {
  eventIds?: string[]
}

export interface UserChatStatusEventResponse {
  user?: UserResponse<DefaultGenerics>
  chatStatus: ChatStatus
}

interface ISubscribeToEvent {
  id?: string
  eventName: string
  channelType?: string
  channelIds?: string[]
  memberIds?: string[]
  callback: (event: any) => void
}

interface IDisconnectUserFromChat {
  hardDisconnect?: boolean
}

export interface IChangeChatStatus {
  status: ChatStatus
}

interface IProcessUserChatStatus {
  user?: UserResponse<DefaultGenerics>
}

interface IConnectToChatOptionsInterface {
  watchChannels?: boolean
}

export type ChatContextState = {
  chatConnectedUser?: ConnectionOpen
  isConnectionOpen: boolean
  connectToChat: (
    data: IBuyerVendorChatUserData | IVisitorChatUserData,
    options?: IConnectToChatOptionsInterface
  ) => Promise<undefined | ConnectionOpen<DefaultGenerics>>
  subscribeToChatEvent: (data: ISubscribeToEvent) => void
  disconnectFromChat: (data?: IDisconnectUserFromChat) => Promise<void>
  unsubscribeFromAllChatEvents: (data?: IUnsubscribeFromAllChatEvents) => void
  changeChatStatus: (data: IChangeChatStatus) => Promise<void>
  processUserChatStatus: (data: IProcessUserChatStatus) => ChatStatus
  watchUserChannels: () => Promise<void>
  getUserChannels: () => Channel<DefaultGenerics>[] | undefined
  setIsShowAssistanceChatBubble: (data: SetStateAction<boolean>) => void
  getConnectedUser: () =>
    | UserResponse<DefaultGenerics>
    | OwnUserResponse<DefaultGenerics>
    | undefined
}

export const ChatContext = createContext<ChatContextState>(
  {} as ChatContextState
)

interface IEventSubscriptionData {
  id?: string
  channelType?: string
  channelIds?: string[]
  memberIds?: string[]
  callback: (
    event: Event<DefaultGenerics> | UserChatStatusEventResponse
  ) => void
}

export interface IUnsubscribeFromEventListener {
  unsubscribe: () => void
}

export const CHAT_EVENT_SUBSCRIPTIONS = new Map<
  string,
  IEventSubscriptionData[]
>()

interface IChatProviderProps {
  children: React.ReactNode
  override?: Partial<ChatContextState>
}

const ChatProvider = ({ children, override }: IChatProviderProps) => {
  const preventDisconnect = useRef(false)

  const lastConnectedUser = useRef<
    IBuyerVendorChatUserData | IVisitorChatUserData
  >()
  const userPresenceChannels = useRef<Channel<DefaultGenerics>[]>()

  const unsubscribeFromMessageReadEventListeners = useRef<
    IUnsubscribeFromEventListener[]
  >([])
  const unsubscribeFromNewMessageEventListeners = useRef<
    IUnsubscribeFromEventListener[]
  >([])
  const unsubscribeFromUserIdleEventListenerRef =
    useRef<IUnsubscribeFromEventListener>()

  const [chatConnectedUser, setChatConnectedUser] = useState<ConnectionOpen>()

  const [isShowAssistanceChatBubble, setIsShowAssistanceChatBubble] =
    useState(false)

  const removeConnectionChangeEventListenerRef =
    useRef<IUnsubscribeFromEventListener>()
  const removeNewMessageEventListenerRef =
    useRef<IUnsubscribeFromEventListener>()
  const removeMessageReadEventListenerRef =
    useRef<IUnsubscribeFromEventListener>()
  const removeUserPresenceEventListenerRef =
    useRef<IUnsubscribeFromEventListener>()

  const clearChatEventSubscriptions = useCallback(() => {
    CHAT_EVENT_SUBSCRIPTIONS.clear()
  }, [])

  const unsubscribeFromAllChatEvents = useCallback(
    ({ eventIds }: IUnsubscribeFromAllChatEvents = {}) => {
      if (!eventIds) {
        CHAT_EVENT_SUBSCRIPTIONS.clear()
        return
      }

      CHAT_EVENT_SUBSCRIPTIONS.forEach((_, key) => {
        const updatedSubscriptions = CHAT_EVENT_SUBSCRIPTIONS.get(key)?.filter(
          (v) => !v.id || !eventIds.includes(v.id)
        )

        if (updatedSubscriptions) {
          CHAT_EVENT_SUBSCRIPTIONS.set(key, updatedSubscriptions)
        }
      })
    },
    []
  )

  const stopWatchingAndListeningUserChannelsEvents = async () => {
    for (const {
      unsubscribe,
    } of unsubscribeFromNewMessageEventListeners.current) {
      unsubscribe()
    }

    for (const {
      unsubscribe,
    } of unsubscribeFromMessageReadEventListeners.current) {
      unsubscribe()
    }

    unsubscribeFromUserIdleEventListenerRef.current?.unsubscribe()

    for (const channel of userPresenceChannels.current || []) {
      if (!channel.disconnected) {
        await channel.stopWatching()
      }
    }

    unsubscribeFromNewMessageEventListeners.current = []
    unsubscribeFromMessageReadEventListeners.current = []
    userPresenceChannels.current = undefined
  }

  const getUserChannels = () => userPresenceChannels.current

  const connectToChat = useCallback(
    async (
      data: IBuyerVendorChatUserData | IVisitorChatUserData,
      {
        watchChannels = true,
      }: {
        watchChannels?: boolean
      } = {}
    ) => {
      if (streamChatClient.user) {
        return
      }

      preventDisconnect.current = true

      lastConnectedUser.current = data

      const {
        id,
        name,
        email,
        type,
        officialTitle,
        companyName,
        invisible,
        idle,
        anon,
        userType,
        token,
      } = data

      const connectedUser: ConnectionOpen = (await streamChatClient.connectUser(
        {
          id,
          name,
          companyName,
          idle: idle ?? false,
          invisible: invisible ?? false,
          ...(!!email && { email }),
          ...(!!type && { type }),
          ...(!!officialTitle && { officialTitle }),
          ...(!!anon && { anon }),
          ...(!!userType && { userType }),
        },
        token
      )) as ConnectionOpen

      setChatConnectedUser(connectedUser)

      if (!removeConnectionChangeEventListenerRef.current) {
        removeConnectionChangeEventListenerRef.current = streamChatClient.on(
          'connection.changed',
          (event) => {
            processEvent(event)
          }
        )
      }

      if (!removeNewMessageEventListenerRef.current) {
        removeNewMessageEventListenerRef.current = streamChatClient.on(
          'notification.message_new',
          (event) => {
            setChatConnectedUser((connectedUser: any) => {
              connectedUser.me.total_unread_count = event.total_unread_count
              return connectedUser
            })

            processEvent(event)
          }
        )
      }

      if (!removeMessageReadEventListenerRef.current) {
        removeMessageReadEventListenerRef.current = streamChatClient.on(
          'notification.mark_read',
          (event) => {
            setChatConnectedUser((connectedUser: any) => {
              connectedUser.me.total_unread_count = event.total_unread_count
              return connectedUser
            })

            processEvent(event)
          }
        )
      }

      if (!removeUserPresenceEventListenerRef.current) {
        removeUserPresenceEventListenerRef.current = streamChatClient.on(
          'user.presence.changed',
          (event) => {
            processEvent(event)
          }
        )
      }

      if (watchChannels) {
        watchUserChannels()
      }

      preventDisconnect.current = false

      return connectedUser
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const subscribeToChatEvent = useCallback(
    ({
      eventName,
      channelType,
      id,
      channelIds,
      memberIds,
      callback,
    }: ISubscribeToEvent) => {
      if (!CHAT_EVENT_SUBSCRIPTIONS.has(eventName)) {
        CHAT_EVENT_SUBSCRIPTIONS.set(eventName, [])
      }

      const eventSubscriptions = CHAT_EVENT_SUBSCRIPTIONS.get(eventName)
      const alreadyHasSubscriptionRegistered =
        !!id && eventSubscriptions?.some((v) => v.id === id)

      if (alreadyHasSubscriptionRegistered) {
        const index = eventSubscriptions?.findIndex((v) => v.id === id)
        eventSubscriptions?.splice(index!, 1)
      }

      eventSubscriptions?.push({
        id,
        channelType,
        channelIds,
        memberIds,
        callback,
      })

      const unsubscribeFromEvent = () => {
        if (eventSubscriptions) {
          const index = eventSubscriptions.findIndex(
            (eventSubscription) => eventSubscription.callback === callback
          )
          if (index > -1) {
            eventSubscriptions.splice(index, 1)
          }
        }
      }

      return unsubscribeFromEvent
    },
    []
  )

  const processEvent = useCallback((event: Event<DefaultGenerics>) => {
    const { type } = event

    if (CHAT_EVENT_SUBSCRIPTIONS.has(type)) {
      const eventSubscriptions = CHAT_EVENT_SUBSCRIPTIONS.get(type)!

      eventSubscriptions.forEach((eventSubscription) => {
        const { channelIds, memberIds, channelType, callback } =
          eventSubscription

        if (!!channelType) {
          if (!event?.channel_type) {
            throw new Error(`[${event.type}] does not use a channel type`)
          } else if (event.channel_type !== channelType) {
            return
          }
        }

        const isInFilteredChannels =
          !channelIds ||
          (!!event.channel_id && channelIds.includes(event.channel_id))

        const isInFilteredMembers =
          !memberIds ||
          (!!event.message?.user?.id &&
            memberIds.includes(event.message.user.id))

        if (isInFilteredChannels && isInFilteredMembers) {
          if (type === 'user.presence.changed') {
            const user = event.user

            callback({
              user,
              chatStatus: processUserChatStatus({
                user: event.user,
              }),
            })
          } else {
            callback(event)
          }
        }
      })
    }
  }, [])

  const watchUserChannels = useCallback(async () => {
    if (!streamChatClient?.userID) {
      return
    }

    await stopWatchingAndListeningUserChannelsEvents()

    let userChannels: Channel<DefaultGenerics>[]
    const unsubscribeFromChannelsNewMessageEventListeners: IUnsubscribeFromEventListener[] =
      []
    const unsubscribeFromChannelsMessageReadEventListeners: IUnsubscribeFromEventListener[] =
      []

    try {
      userChannels = await streamChatClient.queryChannels(
        {
          type: StreamChatChannelType.MESSAGING,
          joined: true,
        },
        undefined,
        {
          watch: true,
          presence: true,
        }
      )

      userPresenceChannels.current = userChannels

      userChannels!.forEach((channel) => {
        const unsubscribeFromNewMessageEventListenerFunction = channel.on(
          'message.new',
          (event) => {
            processEvent({
              ...event,
              channel: channel.data as any,
              type: 'notification.message_new',
            })
          }
        )

        unsubscribeFromChannelsNewMessageEventListeners.push(
          unsubscribeFromNewMessageEventListenerFunction
        )

        const unsubscribeFromMessageReadEventListenerFunction = channel.on(
          'message.read',
          (event) => {
            if (event.user?.id === streamChatClient.userID) {
              return
            }

            processEvent(event)
          }
        )

        unsubscribeFromChannelsMessageReadEventListeners.push(
          unsubscribeFromMessageReadEventListenerFunction
        )
      })

      unsubscribeFromNewMessageEventListeners.current?.push(
        ...unsubscribeFromChannelsNewMessageEventListeners
      )

      unsubscribeFromMessageReadEventListeners.current?.push(
        ...unsubscribeFromChannelsMessageReadEventListeners
      )
    } catch (error) {
      Sentry.captureException(error)
    }
  }, [processEvent])

  const processUserChatStatus = ({
    user,
  }: {
    user?: UserResponse<DefaultGenerics>
  }) => {
    if (!user) {
      return ChatStatus.OFFLINE
    } else {
      const currentOnline = user?.online
      const isIdle = user?.idle

      if (isIdle) {
        return ChatStatus.IDLE
      } else if (currentOnline) {
        return ChatStatus.ONLINE
      } else {
        return ChatStatus.OFFLINE
      }
    }
  }

  const disconnectFromChat = useCallback(
    async ({ hardDisconnect = true }: IDisconnectUserFromChat = {}) => {
      if (!hardDisconnect && preventDisconnect.current) {
        preventDisconnect.current = false
        return
      }

      setChatConnectedUser(undefined)

      stopWatchingAndListeningUserChannelsEvents()

      removeNewMessageEventListenerRef.current?.unsubscribe()
      removeNewMessageEventListenerRef.current = undefined

      removeMessageReadEventListenerRef.current?.unsubscribe()
      removeMessageReadEventListenerRef.current = undefined

      removeUserPresenceEventListenerRef.current?.unsubscribe()
      removeUserPresenceEventListenerRef.current = undefined

      if (hardDisconnect) {
        removeConnectionChangeEventListenerRef.current?.unsubscribe()
        removeConnectionChangeEventListenerRef.current = undefined

        unsubscribeFromAllChatEvents()
        clearChatEventSubscriptions()

        lastConnectedUser.current = undefined
      }

      await changeChatStatus({
        status: ChatStatus.OFFLINE,
      })

      await streamChatClient.disconnectUser()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      chatConnectedUser?.connection_id,
      unsubscribeFromAllChatEvents,
      clearChatEventSubscriptions,
    ]
  )

  const changeChatStatus = useCallback(
    async ({ status }: IChangeChatStatus) => {
      const idle = status === ChatStatus.IDLE

      if (!streamChatClient?.userID || streamChatClient.user?.idle === idle) {
        return
      }

      await streamChatClient.partialUpdateUser({
        id: streamChatClient.userID!,
        set: {
          invisible: idle,
          idle,
        },
      })

      await ChatService.changeOnlineStatus({
        status,
      })
    },
    []
  )

  const getConnectedUser = () => streamChatClient?.user

  const isConnectionOpen = !!chatConnectedUser && !!getConnectedUser()

  return (
    <ChatContext.Provider
      value={{
        chatConnectedUser,
        isConnectionOpen,
        connectToChat,
        subscribeToChatEvent,
        unsubscribeFromAllChatEvents,
        disconnectFromChat,
        changeChatStatus,
        processUserChatStatus,
        watchUserChannels,
        getUserChannels,
        setIsShowAssistanceChatBubble,
        getConnectedUser,
        ...override,
      }}
    >
      {isShowAssistanceChatBubble && (
        <AdvisorConnectAssistanceChatBubble
          sourcePage={AssistanceChatSourcePage.PROMOTIONAL_LANDING_PAGE}
        />
      )}
      {children}
    </ChatContext.Provider>
  )
}

export { ChatProvider }
