import api from '@libs/api'

import { handleServiceError } from '@utils/errors'
import { getTimeZone } from '@utils/timezones'
import type { IApiData, IBuyer, ICompany, IQueryFilters } from '@utils/types'
import { buildQueryString } from '@utils/url'

const getByUserId = async (): Promise<IBuyer> => {
  try {
    const response = await api.get({
      path: '/buyers/profile',
    })
    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const getLegacyById = async (id: string): Promise<IBuyer> => {
  try {
    const response = await api.get({
      path: `/buyers/legacy/${id}`,
      authenticate: false,
    })

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const getById = async (id: string): Promise<IBuyer> => {
  try {
    const response = await api.get({
      path: `/buyers/${id}`,
    })

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const findAll = async (
  queryFilters: IQueryFilters
): Promise<IApiData<IBuyer[]>> => {
  const queryString = buildQueryString(queryFilters)

  try {
    const response = (await api.get({
      path: '/buyers',
      queryString,
    })) as IApiData<IBuyer[]>

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const fetchCsvData = async (queryFilters: IQueryFilters) => {
  const queryString = {
    ...buildQueryString(queryFilters),
    timeZone: getTimeZone(),
  }

  try {
    const response = await api.csv({
      path: '/buyers/csv',
      queryString,
    })

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const updateProfile = async (data: {
  firstName: string
  lastName: string
  officialTitle: string
  personalEmail?: string
  phone?: string
  company?: ICompany
  avatar?: File | null
}) => {
  try {
    const formData = api.buildFormData(data)

    await api.put({
      headers: { 'Content-Type': 'multipart/form-data' },
      path: '/buyers/profile',
      data: formData,
    })
  } catch (err: any) {
    throw handleServiceError(err)
  }
}
const createBuyerFromVendor = async (
  {
    email,
    password,
  }: {
    email?: string
    password?: string
  },
  { authenticate = false } = {}
): Promise<IBuyer> => {
  try {
    const response = await api.post({
      path: '/buyers/convert/vendor',
      authenticate,
      data: { credentials: { email, password } },
    })

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const update = async (data: {
  id: string
  email: string
  status: string
  statusOption?: string
  firstName?: string
  lastName?: string
  companyName?: string
  officialTitle?: string
  personalEmail?: string
  phone?: string
  companyAddress1?: string
  companyAddress2?: string
  companyCity?: string
  companyState?: string
  companyZipCode?: string
  companyPhoneNumber?: string
  companyPhoneExtension?: string
  resetPasswordAndSendEmail?: boolean
}) => {
  try {
    return await api.put({
      path: `/buyers/${data.id}`,
      data,
    })
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const batchUpdate = async (data: { buyerIds: string[]; status: string }) => {
  try {
    return await api.put({
      path: '/buyers/batch',
      data,
    })
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const batchDelete = async ({ buyerIds }: { buyerIds: string[] }) => {
  try {
    return await api.remove({
      path: '/buyers/batch',
      queryString: {
        buyerIds,
      },
    })
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const remove = async ({ id }: { id?: string } = {}) => {
  try {
    const path = id ? `/buyers/${id}/delete-account` : '/buyers/delete-account'

    return await api.remove({
      path,
    })
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const recoverAccount = async () => {
  try {
    return await api.post({
      path: '/buyers/recover-account',
    })
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const create = async (
  firstName: string,
  lastName: string,
  email: string,
  companyName?: string,
  officialTitle?: string,
  personalEmail?: string,
  phone?: string,
  companyAddress1?: string,
  companyAddress2?: string,
  companyCity?: string,
  companyState?: string,
  companyZipCode?: string,
  companyPhoneNumber?: string,
  companyPhoneExtension?: string,
  legacy?: boolean,
  externalId?: string,
  importing?: boolean
) => {
  const path = importing ? '/buyers/import' : '/buyers'

  try {
    return await api.post({
      path,
      data: {
        firstName,
        email,
        lastName,
        companyName,
        officialTitle,
        personalEmail,
        phone,
        companyAddress1,
        companyAddress2,
        companyCity,
        companyState,
        companyZipCode,
        companyPhoneNumber,
        companyPhoneExtension,
        legacy,
        externalId: externalId || undefined,
      },
    })
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const sendWelcomeEmail = async () => {
  try {
    return await api.post({
      path: '/buyers/send-welcome-email',
    })
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const getLegacyMatchesCount = async () => {
  try {
    return await api.get({
      path: '/buyers/opportunities/count',
      queryString: {
        legacy: true,
      },
    })
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const getProfileCompletion = async (): Promise<number> => {
  const response = await api.get({
    path: '/buyers/profile-completion',
  })

  return response
}

export default {
  getByUserId,
  updateProfile,
  createBuyerFromVendor,
  findAll,
  getById,
  getLegacyById,
  update,
  fetchCsvData,
  batchUpdate,
  batchDelete,
  create,
  remove,
  recoverAccount,
  sendWelcomeEmail,
  getLegacyMatchesCount,
  getProfileCompletion,
}
