import { useTranslation } from 'react-i18next'

import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import authenticatedUserPathPrefixHook from '@hooks/useAuthenticatedUserPathPrefix'
import navigationHook from '@hooks/useNavigation'

import { buttonSx } from './styles'

const ReturnButton = () => {
  const { t } = useTranslation()
  const { navigateTo } = navigationHook.useNavigation()
  const { pathPrefix, isFetchingUserData } =
    authenticatedUserPathPrefixHook.useAuthenticatedUserPathPrefix()

  const buttonConfig = pathPrefix
    ? {
        navigationLink: `${pathPrefix}/dashboard`,
        text: t('Back to Dashboard'),
      }
    : {
        navigationLink: '/',
        text: t('Return to Home'),
      }

  return (
    <LoadingButton
      variant="contained"
      color="secondary"
      loading={isFetchingUserData}
      sx={buttonSx}
      data-testid="return-button"
      startIcon={<ArrowBackIcon />}
      onClick={() => navigateTo({ path: buttonConfig.navigationLink })}
    >
      <Typography variant="h6">{buttonConfig.text}</Typography>
    </LoadingButton>
  )
}

export { ReturnButton }
