import { SxProps, Theme } from '@mui/material'

export const tooltipSx: SxProps<Theme> = {
  whiteSpace: 'pre-line',
}

export const textWithTooltipSx: SxProps<Theme> = {
  overflowWrap: 'anywhere',
  textOverflow: 'ellipsis',
}

export const textSx: SxProps<Theme> = {
  overflowWrap: 'anywhere',
}
