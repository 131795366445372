import React, { FC } from 'react'

import { Drawer, DrawerProps } from '@mui/material'

import responsiveHook from '@hooks/useResponsive'

import { drawerModalSx } from './styles'

interface IWithMobileDrawerProps {
  sideBarDrawerOpen: boolean
  drawerProps?: DrawerProps
  onCloseSideBarDrawer: () => void
}

const withMobileDrawer = <P,>(
  WrappedComponent: React.ComponentType<P>
): FC<P & IWithMobileDrawerProps> =>
  function ({
    sideBarDrawerOpen,
    drawerProps,
    onCloseSideBarDrawer,
    ...props
  }) {
    const { isMobileOrTablet } = responsiveHook.useResponsive()

    if (isMobileOrTablet) {
      return (
        <Drawer
          keepMounted
          anchor="left"
          open={sideBarDrawerOpen}
          onClose={onCloseSideBarDrawer}
          ModalProps={{
            sx: drawerModalSx,
          }}
          {...drawerProps}
        >
          <WrappedComponent {...(props as any)} />
        </Drawer>
      )
    } else {
      return <WrappedComponent {...(props as any)} />
    }
  }

export { withMobileDrawer }
