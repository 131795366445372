import {
  ReactNode,
  createContext,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  NavigateOptions,
  To,
  useLocation, // eslint-disable-next-line custom-rules/no-use-navigate
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

interface INavigateToProps {
  path: To
  replace?: NavigateOptions['replace']
  state?: NavigateOptions['state']
}

interface INavigateBackProps {
  fallbackPath: string
  state?: NavigateOptions['state']
}

interface IChangeUrlSearchParamsProps {
  params: Record<string, string> | URLSearchParams
  replace?: boolean
}

interface NavigationContextProps {
  readonly history: readonly string[]
  navigateTo: ({ path, replace, state }: INavigateToProps) => void
  navigateBack: ({ fallbackPath, state }: INavigateBackProps) => void
  changeUrlSearchParams: ({
    params,
    replace,
  }: IChangeUrlSearchParamsProps) => void
}

export const NavigationContext = createContext<
  NavigationContextProps | undefined
>(undefined)

const NavigationProvider = memo(({ children }: { children: ReactNode }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [_, setSearchParams] = useSearchParams()
  const [history, setHistory] = useState<string[]>([])
  const lockPopHistory = useRef(false)

  useEffect(() => {
    const { pathname, search, hash } = location
    const fullPath = `${pathname}${search ?? ''}${hash ?? ''}`
    const isHistoryEmpty = history.length === 0
    const isDiffFromLastPath = history[history.length - 1] !== fullPath

    if (isHistoryEmpty || isDiffFromLastPath) {
      setHistory((prevHistory) => [...prevHistory, fullPath])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const popHistory = useCallback(() => {
    if (lockPopHistory.current) {
      return
    }

    lockPopHistory.current = true
    setHistory((prevHistory) => prevHistory.slice(0, -1))

    setTimeout(() => {
      lockPopHistory.current = false
    }, 100)
  }, [])

  const navigateTo = useCallback(
    ({ path, replace, state }: INavigateToProps) => {
      const options: NavigateOptions = {}

      if (replace) {
        popHistory()
        options.replace = true
      }

      if (state) {
        options.state = state
      }

      navigate(path, ...(Object.keys(options).length > 0 ? [options] : []))
    },
    [navigate, popHistory]
  )

  const navigateBackRef = useRef<
    ({ fallbackPath, state }: INavigateBackProps) => void
  >(() => {})

  useEffect(() => {
    navigateBackRef.current = ({ fallbackPath, state }: INavigateBackProps) => {
      let path: string | undefined

      if (history.length > 1) {
        path = history[history.length - 2]
      } else if (fallbackPath) {
        path = fallbackPath
      }

      if (path) {
        popHistory()
        navigate(path, { replace: true, state })
      }
    }
  }, [history, navigate, popHistory])

  const navigateBack = useCallback(
    ({ fallbackPath, state }: INavigateBackProps) => {
      navigateBackRef.current({ fallbackPath, state })
    },
    []
  )

  const changeUrlSearchParams = useCallback(
    ({ params, replace }: IChangeUrlSearchParamsProps) => {
      const options: NavigateOptions = {}

      if (replace) {
        popHistory()
        options.replace = true
      }

      setSearchParams(
        params,
        ...(Object.keys(options).length > 0 ? [options] : [])
      )
    },
    [popHistory, setSearchParams]
  )

  return (
    <NavigationContext.Provider
      value={{ history, navigateTo, navigateBack, changeUrlSearchParams }}
    >
      {children}
    </NavigationContext.Provider>
  )
})

export { NavigationProvider }
