import { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { ThirdPartyScripts } from '@scripts/index'

import { AppProvider } from '@contexts/index'

import { Routes } from '@routes/index.routes'

const App = () => (
  <>
    <ThirdPartyScripts />
    <Suspense fallback={null}>
      <BrowserRouter>
        <AppProvider>
          <Routes />
        </AppProvider>
      </BrowserRouter>
    </Suspense>
  </>
)

export default App
