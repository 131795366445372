import type { SetOptional } from 'type-fest'

import api from '@libs/api'

import DateUtils from '@utils/dates'
import { handleServiceError } from '@utils/errors'
import {
  AssistanceAutomaticMessageType,
  ChatStatus,
  IAdvisorChatData,
  IApiData,
  IBuyerCompanyChatData,
  IBuyerConversationData,
  IBuyerConversationDataExtra,
  IQueryFilters,
  IVendorConversationData,
  IVendorConversationDataExtra,
  IVendorLeadChatData,
  UserType,
} from '@utils/types'
import { buildQueryString } from '@utils/url'

interface IGetChatVisitorToken {
  chatUserId: string
  token: string
}

type IFindResponse<T = IAdvisorChatData> = Omit<T, 'latestMessage'> & {
  latestMessage?: {
    userId: string
    text: string
    createdDate: string
  }
}

const mapFindResponse = <T extends IAdvisorChatData>(
  data: IFindResponse<T>
): IAdvisorChatData => DateUtils.replaceISODateWithDate(data)

const getBuyerCompanyChatChannel = async ({
  companyId,
}: {
  companyId: string
}): Promise<string> => {
  try {
    const response = await api.get({
      path: '/chats/company-channel',
      queryString: {
        company: companyId,
      },
    })

    return response
  } catch (err) {
    throw handleServiceError(err)
  }
}

const getCompanyLeadChatChannel = async ({
  leadUserId,
}: {
  leadUserId: string
}): Promise<string> => {
  try {
    const response = await api.get({
      path: '/chats/lead-channel',
      queryString: {
        lead: leadUserId,
      },
    })

    return response
  } catch (err) {
    throw handleServiceError(err)
  }
}

const getBuyerOpportunityChatChannel = async ({
  opportunityId,
}: {
  opportunityId: string
}): Promise<string> => {
  try {
    const response = await api.get({
      path: '/chats/opportunity-channel',
      queryString: {
        opportunity: opportunityId,
      },
    })

    return response
  } catch (err) {
    throw handleServiceError(err)
  }
}

const findBuyerCompanyChatData = async ({
  companyId,
}: {
  companyId: string
}): Promise<IBuyerCompanyChatData> => {
  try {
    const response = (await api.get({
      path: `/chats/companies/${companyId}/buyer-chat`,
    })) as IBuyerCompanyChatData

    return DateUtils.replaceISODateWithDate(response)
  } catch (err) {
    throw handleServiceError(err)
  }
}

const findCompanyLeadChatData = async ({
  leadUserId,
}: {
  leadUserId: string
}): Promise<IVendorLeadChatData> => {
  try {
    const response = (await api.get({
      path: `/chats/leads/${leadUserId}/vendor-chat`,
    })) as IVendorLeadChatData

    return DateUtils.replaceISODateWithDate(response)
  } catch (err) {
    throw handleServiceError(err)
  }
}

const findBuyerConversationData = async (
  queryFilters: SetOptional<IQueryFilters, 'page' | 'limit' | 'filters'>
): Promise<IApiData<IBuyerConversationData[], IBuyerConversationDataExtra>> => {
  try {
    const queryString = buildQueryString(queryFilters)

    const response = (await api.get({
      path: '/chats/buyer/conversations',
      queryString,
    })) as IApiData<IBuyerConversationData[], IBuyerConversationDataExtra>

    return response
  } catch (err) {
    throw handleServiceError(err)
  }
}

const findVendorConversationData = async (
  queryFilters: SetOptional<IQueryFilters, 'page' | 'limit'>
): Promise<
  IApiData<IVendorConversationData[], IVendorConversationDataExtra>
> => {
  try {
    const queryString = buildQueryString(queryFilters)

    const response = (await api.get({
      path: '/chats/vendor/conversations',
      queryString,
    })) as IApiData<IVendorConversationData[], IVendorConversationDataExtra>

    return DateUtils.replaceISODateWithDate(response)
  } catch (err) {
    throw handleServiceError(err)
  }
}

const updateConversationReplyDate = async ({
  companyChatChannelId,
}: {
  companyChatChannelId: string
}) => {
  try {
    const response = (await api.put({
      path: `/chats/companies/channels/${companyChatChannelId}/update-last-reply`,
      data: {},
    })) as IVendorConversationData[]

    return response
  } catch (err) {
    throw handleServiceError(err)
  }
}

const removeChatConversation = async ({ channelId }: { channelId: string }) => {
  try {
    const response = await api.put({
      path: `/chats/companies/channels/${channelId}/update-removed-state`,
      data: {
        isRemoved: true,
      },
    })

    return response
  } catch (err) {
    throw handleServiceError(err)
  }
}

export const checkForAutomaticResponse = async ({
  channelId,
}: {
  channelId: string
}): Promise<{ automaticOfflineMessageSent: boolean }> => {
  try {
    return await api.post({
      path: `/chats/channels/${channelId}/check-for-automatic-response`,
    })
  } catch (err) {
    throw handleServiceError(err)
  }
}

const archiveBuyerCompanyChat = async ({
  buyerCompanyChatId,
}: {
  buyerCompanyChatId: string
}): Promise<void> => {
  try {
    await api.put({
      path: `/chats/channels/${buyerCompanyChatId}/archive`,
      data: undefined,
    })
  } catch (err) {
    throw handleServiceError(err)
  }
}

const unarchiveBuyerCompanyChat = async ({
  buyerCompanyChatId,
}: {
  buyerCompanyChatId: string
}): Promise<void> => {
  try {
    await api.put({
      path: `/chats/channels/${buyerCompanyChatId}/unarchive`,
      data: undefined,
    })
  } catch (err) {
    throw handleServiceError(err)
  }
}

const getLatestReplyFromChannel = async ({
  channelId,
}: {
  channelId: string
}) => {
  try {
    const response = await api.get({
      path: `/chats/channels/${channelId}/get-last-reply-from-channel`,
    })

    return response
  } catch (err) {
    throw handleServiceError(err)
  }
}

const getConversationEntryCount = async (
  userType: UserType,
  queryFilters: SetOptional<IQueryFilters, 'page' | 'limit'>
): Promise<number> => {
  try {
    const pathToUse =
      userType === UserType.BUYER
        ? 'buyer/conversations-general-count'
        : 'vendor/conversations-general-count'

    const queryString = buildQueryString(queryFilters)
    const response = await api.get({
      path: `/chats/${pathToUse}`,
      queryString,
    })

    return response
  } catch (err) {
    throw handleServiceError(err)
  }
}

const changeOnlineStatus = async ({ status }: { status: ChatStatus }) => {
  try {
    await api.put({
      path: `/chats/change-chat-status/${status}`,
    })
  } catch (err) {
    throw handleServiceError(err)
  }
}

const getChatVisitorToken = async (): Promise<IGetChatVisitorToken> => {
  try {
    return await api.get({
      authenticate: false,
      path: '/chats/visitors/token',
    })
  } catch (err) {
    throw handleServiceError(err)
  }
}

const requestAssistanceChatAutomaticResponse = async ({
  chatUserId,
  channelId,
  messageType,
}: {
  chatUserId: string
  channelId: string
  messageType: AssistanceAutomaticMessageType
}): Promise<string> => {
  try {
    return await api.get({
      authenticate: false,
      path: `/chats/channels/${channelId}/users/${chatUserId}/request-message/${messageType}`,
    })
  } catch (err) {
    throw handleServiceError(err)
  }
}

const updateChannelLatestMessageMetadata = async ({
  chatUserId,
  channelId,
}: {
  chatUserId: string
  channelId: string
}): Promise<string> => {
  try {
    return await api.post({
      authenticate: false,
      path: `/chats/channels/${channelId}/users/${chatUserId}/update-message-metadata`,
    })
  } catch (err) {
    throw handleServiceError(err)
  }
}

const updateChannelSetUnreadStatusMetadata = async ({
  chatUserId,
  channelId,
}: {
  chatUserId: string
  channelId: string
}): Promise<string> => {
  try {
    return await api.post({
      authenticate: false,
      path: `/chats/channels/${channelId}/users/${chatUserId}/set-unread-metadata`,
    })
  } catch (err) {
    throw handleServiceError(err)
  }
}

const getAdminChats = async (
  path: string,
  queryFilters: SetOptional<IQueryFilters, 'page' | 'limit'>
): Promise<IApiData<IAdvisorChatData[]>> => {
  try {
    const queryString = buildQueryString(queryFilters)

    const response = (await api.get({
      path,
      queryString,
    })) as IApiData<IFindResponse<IAdvisorChatData>[]>

    return {
      ...response,
      data: response.data.map(mapFindResponse),
    }
  } catch (err) {
    throw handleServiceError(err)
  }
}

const getAdminAllChats = (
  queryFilters: SetOptional<IQueryFilters, 'page' | 'limit'>
): Promise<IApiData<IAdvisorChatData[]>> =>
  getAdminChats('/chats/admin/all-assistance-chats', queryFilters)

const getAdminMyChats = (
  queryFilters: SetOptional<IQueryFilters, 'page' | 'limit'>
): Promise<IApiData<IAdvisorChatData[]>> =>
  getAdminChats('/chats/admin/my-assistance-chats', queryFilters)

const archiveOrUnarchiveAssistanceChat = async (
  channelIds: string[],
  action: 'archive' | 'unarchive'
) => {
  try {
    return await api.post({
      path: '/chats/admin/archive-assistance-chats',
      data: {
        channelIds,
        action,
      },
    })
  } catch (err) {
    throw handleServiceError(err)
  }
}

const archiveAssistanceChats = async (channelIds: string[]) =>
  await archiveOrUnarchiveAssistanceChat(channelIds, 'archive')

const unarchiveAssistanceChats = async (channelIds: string[]) =>
  await archiveOrUnarchiveAssistanceChat(channelIds, 'unarchive')

const assignAdvisorChatToAdmin = async ({
  channelId,
  userId,
  markMessagesAsRead,
}: {
  channelId: string
  userId: string
  markMessagesAsRead?: boolean
}) => {
  try {
    const response = await api.post({
      path: `/chats/channels/${channelId}/users/${userId}/assign-advisor-chat-to-admin`,
      data: {
        markMessagesAsRead,
      },
    })

    return response
  } catch (err) {
    throw handleServiceError(err)
  }
}

export default {
  getBuyerCompanyChatChannel,
  getCompanyLeadChatChannel,
  getBuyerOpportunityChatChannel,
  findBuyerCompanyChatData,
  findBuyerConversationData,
  findCompanyLeadChatData,
  findVendorConversationData,
  updateConversationReplyDate,
  removeChatConversation,
  archiveBuyerCompanyChat,
  unarchiveBuyerCompanyChat,
  checkForAutomaticResponse,
  getLatestReplyFromChannel,
  getConversationEntryCount,
  changeOnlineStatus,
  getChatVisitorToken,
  requestAssistanceChatAutomaticResponse,
  updateChannelLatestMessageMetadata,
  updateChannelSetUnreadStatusMetadata,
  getAdminAllChats,
  getAdminMyChats,
  archiveAssistanceChats,
  unarchiveAssistanceChats,
  assignAdvisorChatToAdmin,
}
