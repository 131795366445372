import { useEffect } from 'react'

const minHeight = 'calc(var(--vh, 1vh) * 100)'

const useViewportHeight = () => {
  useEffect(() => {
    const rootDiv = document.getElementById('root')

    const calculateViewportHeight = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)

      document.documentElement.style.minHeight = minHeight
      document.body.style.minHeight = minHeight

      if (rootDiv) {
        rootDiv.style.minHeight = minHeight
      }
    }

    window.addEventListener('resize', calculateViewportHeight)
    window.addEventListener('orientationchange', calculateViewportHeight)

    calculateViewportHeight()

    return () => {
      const originalHtmlStyle = document.documentElement.style.cssText
      const originalBodyStyle = document.body.style.cssText

      if (rootDiv) {
        rootDiv.style.minHeight = ''
      }
      document.documentElement.style.cssText = originalHtmlStyle
      document.body.style.cssText = originalBodyStyle

      window.removeEventListener('resize', calculateViewportHeight)
      window.removeEventListener('orientationchange', calculateViewportHeight)
    }
  }, [])
}

export default {
  useViewportHeight,
}
