import { SxProps, Theme } from '@mui/material'

export const containerSx = ({
  isVisible,
  leftPosition,
}: {
  isVisible: boolean
  leftPosition?: number
}): SxProps<Theme> => {
  return {
    flexDirection: 'row',
    gap: {
      xs: '0.8rem',
      md: '1.2rem',
    },
    zIndex: 100,
    position: 'fixed',
    ...(leftPosition
      ? {
          left: {
            xs: `${leftPosition - 10}px`,
            md: `${leftPosition - 20}px`,
          },
        }
      : {
          right: {
            xs: '1rem',
            md: '2rem',
          },
        }),
    bottom: {
      xs: isVisible ? '1rem' : Number.MAX_VALUE,
      md: isVisible ? '2rem' : Number.MAX_VALUE,
    },
    opacity: isVisible ? 1 : 0,
    transition: 'opacity 1s ease-in',
  }
}

export const bubbleWrapperSx = ({
  isVisible,
}: {
  isVisible: boolean
}): SxProps<Theme> => {
  return {
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '0.8rem',
    visibility: isVisible ? 'visible' : 'hidden',
  }
}

export const bubbleSx: SxProps<Theme> = {
  cursor: 'pointer',
  padding: {
    xs: '0.8rem',
    md: '1.2rem',
  },
  borderRadius: '0.8rem',
  width: {
    xs: '15.5rem',
    md: '19.1rem',
  },
}

export const closeButtonSx: SxProps<Theme> = {
  color: 'rgba(0, 0, 0, 0.54)',
  padding: 0,
}

export const cancelBackgroundSx: SxProps<Theme> = {
  width: '2.4rem',
  height: '2.4rem',
  backgroundColor: 'white',
  position: 'absolute',
  borderRadius: '10rem',
  zIndex: -1,
}

export const badgeSx = ({
  isVisible,
}: {
  isVisible: boolean
}): SxProps<Theme> => {
  return {
    cursor: 'pointer',
    '.MuiBadge-badge': {
      display: isVisible ? 'flex' : 'none',
      top: '10%',
      right: '12%',
      minWidth: '1.5rem',
      height: '1.5rem',
      padding: 0,
      alignContent: 'center',
    },
  }
}

export const badgeValueSx = ({
  isVisible,
}: {
  isVisible: boolean
}): SxProps<Theme> => {
  return {
    display: isVisible ? 'flex' : 'none',
  }
}

export const avatarSx: SxProps<Theme> = {
  boxShadow: '0 0.3rem 0.3rem 0.1rem #00000033',
  border: '0.1rem solid white',
  cursor: 'pointer',
  width: {
    xs: '4.3rem',
    md: '6.3rem',
  },
  height: {
    xs: '4.3rem',
    md: '6.3rem',
  },
  background: (theme) => theme.palette.background.paper,
}
