import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import chatHook from '@hooks/useChat'

const PublicRoutesWithoutAssistanceChatBubble = () => {
  const { setIsShowAssistanceChatBubble } = chatHook.useChat()

  useEffect(() => {
    setIsShowAssistanceChatBubble(false)
  }, [setIsShowAssistanceChatBubble])

  return <Outlet />
}

export { PublicRoutesWithoutAssistanceChatBubble }
