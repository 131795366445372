import { Amplify } from 'aws-amplify'

import ApiUtils from '@utils/api'

const config = {
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEBCLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'API',
        endpoint: ApiUtils.API_URL,
      },
    ],
  },
}

Amplify.configure(config)
