import { Chip, SxProps, Theme } from '@mui/material'

import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'

import { containerSx, userNotAvailableIconSx } from './styles'

export interface IUserWarningChipProps {
  label: string
  customIcon?: JSX.Element
  chipSx?: SxProps<Theme>
  dataTestId?: string
}

const UserWarningChip = ({
  label,
  customIcon,
  chipSx,
  dataTestId,
}: IUserWarningChipProps) => (
  <Chip
    label={label}
    variant="outlined"
    icon={
      customIcon ?? <WarningAmberOutlinedIcon sx={userNotAvailableIconSx} />
    }
    sx={{ ...containerSx, ...chipSx } as SxProps<Theme>}
    data-testid={dataTestId}
  />
)

export { UserWarningChip }
