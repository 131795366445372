import { SxProps, Theme } from '@mui/material'

import { IMPERSONATION_BAR_HEIGHT } from '@pages/Template/ImpersonationBar'

export const quickAccessDrawerSx = ({
  isImpersonatingUser,
}: {
  isImpersonatingUser: boolean
}): SxProps<Theme> => {
  return {
    height: {
      xs: 'auto',
      lg: isImpersonatingUser
        ? `calc(100vh - ${IMPERSONATION_BAR_HEIGHT})`
        : '100vh',
    },
    overflowY: 'hidden',
    width: 0,
  }
}

export const quickAccessDrawerPaperSx = ({
  isImpersonatingUser,
}: {
  isImpersonatingUser: boolean
}): SxProps<Theme> => {
  return {
    width: {
      xs: 'calc(100% - 1.6rem)',
      md: '50rem',
    },
    right: {
      xs: '0.8rem',
      lg: 0,
    },
    top: {
      xs: isImpersonatingUser ? '8.8rem' : '0.8rem',
      lg: isImpersonatingUser ? IMPERSONATION_BAR_HEIGHT : 0,
    },
    borderRadius: {
      xs: '1.6rem',
      lg: 0,
    },
    maxHeight: {
      xs: isImpersonatingUser
        ? `calc(100% - 1.6rem - ${IMPERSONATION_BAR_HEIGHT} - env(safe-area-inset-top) - env(safe-area-inset-bottom) )`
        : 'calc(100% - 1.6rem - env(safe-area-inset-top) - env(safe-area-inset-bottom) )',
      lg: '100%',
    },
    height: {
      xs: '100%',
      lg: isImpersonatingUser
        ? `calc(100vh - ${IMPERSONATION_BAR_HEIGHT})`
        : '100vh',
    },

    boxShadow:
      '0rem 0.6rem 3rem 0.5rem rgba(0, 0, 0, 0.12), 0rem 1.6rem 2.4rem 0.2rem rgba(0, 0, 0, 0.14), 0rem 0.8rem 1rem -0.5rem rgba(0, 0, 0, 0.20)',
    // TODO LG-2573 - we'll refactor this to use values from the palette in this ticket
  }
}

export const betaTagSx: SxProps<Theme> = {
  background: '#FFECB3',
  // TODO LG-2573 - we'll refactor this to use values from the palette in this ticket
  padding: '0rem 0.65rem',
  borderRadius: '10rem',
  fontSize: '1.2rem',
  lineHeight: '2rem',
  letterSpacing: '0.014rem',
  fontWeight: '500',
  color: '#663C00',
  // TODO LG-2573 - we'll refactor this to use values from the palette in this ticket
  height: '2rem',
}

export const buttonWrapperSx: SxProps<Theme> = {
  padding: '1.6rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '1rem',
}

export const drawerHeaderSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  flexGrow: 1,
  justifyContent: 'center',
}

export const backButtonSx: SxProps<Theme> = {
  border: '0.1rem solid grey',
  // TODO LG-2573 - we'll refactor this to use values from the palette in this ticket
  padding: '0.8rem',
}

export const drawerCloseButtonSx: SxProps<Theme> = {
  border: '0.1rem solid grey',
  // TODO LG-2573 - we'll refactor this to use values from the palette in this ticket
  padding: '0.8rem',
  marginLeft: 'auto',
}

export const cancelResponseButtonSx: SxProps<Theme> = {
  display: 'flex',
  gap: '1rem',
  margin: '0 auto',
  marginBottom: '2.4rem',
  border: 'none',
  borderRadius: '10rem',
  boxShadow:
    '0rem 0.1rem 1rem 0rem rgba(0, 0, 0, 0.12), 0rem 0.4rem 0.5rem 0rem rgba(0, 0, 0, 0.14), 0rem 0.2rem 0.4rem -0.1rem rgba(0, 0, 0, 0.20)',
  // TODO LG-2573 - we'll refactor this to use values from the palette in this ticket
}

export const textFieldSx: SxProps<Theme> = {
  width: '100%',
  border: 'none',
  '& .MuiOutlinedInput-root': {
    alignItems: 'flex-end',
    padding: 0,
  },
  '& fieldset': {
    border: 'none',
    padding: 0,
  },
  '& textarea': {
    padding: 0,
  },
}

export const footerTextSx: SxProps<Theme> = {
  marginTop: '0.8rem',
  display: 'flex',
  justifyContent: 'center',
  color: 'text.secondary',
}

export const charactersCountSx: SxProps<Theme> = {
  color: (theme) => theme.palette.text.secondary,
}

export const textFieldWrapperSx: SxProps<Theme> = {
  borderRadius: '0.4rem',
  border: '0.1rem solid rgba(0, 0, 0, 0.23)',
  padding: '0.8rem 1.2rem',
  '&:focus-within': {
    border: (theme) => `0.2rem solid ${theme.palette.primary.main}`,
  },
  '&:hover': {
    border: (theme) => `0.1rem solid ${theme.palette.text.secondary}`,
  },
  '&:hover:focus-within': {
    border: (theme) => `0.2rem solid ${theme.palette.primary.main}`,
  },
}

export const textFieldBottomSx: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'end',
}

export const footerWrapperSx: SxProps<Theme> = {
  padding: {
    xs: '2.4rem 1.6rem 0.8rem 1.6rem',
    md: '2.4rem 3.2rem 0.8rem 3.2rem',
  },
  width: '100%',
  marginTop: 'auto',
}

export const chatHistoryTitleSx: SxProps<Theme> = {
  paddingLeft: {
    xs: 0,
    md: '0.8rem',
  },
}

export const middleContentWrapperSx = ({
  isScrollable,
  isCentered,
}: {
  isScrollable: boolean
  isCentered: boolean
}): SxProps<Theme> => {
  return {
    overflowY: isScrollable ? 'auto' : 'initial',
    margin: isCentered ? 'auto' : 0,
  }
}
