import React from 'react'

import { SxProps, Theme } from '@mui/material'

const iconSx: SxProps<Theme> = {
  margin: 0,
}

const labelSx = (theme: Theme): React.CSSProperties => {
  return {
    ...theme.typography.body2,
    padding: 0,
  }
}

export const containerSx: SxProps<Theme> = {
  backgroundColor: (theme) => theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  maxWidth: 'initial',
  padding: '0.8rem 1.2rem',
  marginLeft: '-0.1rem',
  gap: '0.8rem',
  ' > .MuiChip-icon': iconSx,
  ' > .MuiChip-label': (theme) => labelSx(theme),
  cursor: 'inherit',
}

export const userNotAvailableIconSx: SxProps<Theme> = {
  color: (theme) => `${theme.palette.error.main} !important`,
  margin: 0,
}
